import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import { ANDROID_ENTERPRISE_GET_ROUTE } from '../../../utils/routes-defs';

export interface IEnterprise {
  enterpriseId: string;
  displayName: string;
}
export const enterpriseInit: IEnterprise = {
  enterpriseId: '',
  displayName: '',
};

interface IAndroidEnterpriseSliceState {
  enterprise: IEnterprise;
  activeStep: number;
  loading: boolean;
  message: IMessage;
}

const initialState: IAndroidEnterpriseSliceState = {
  enterprise: enterpriseInit,
  activeStep: 0,
  loading: false,
  message: messageInit,
};

export const getAndroidEnterprise = createAsyncThunk(
  'users/getAndroidEnterprise',
  async (payload: string, thunkAPI) => {
    try {
      const response = await axiosInstance().get(ANDROID_ENTERPRISE_GET_ROUTE);
      if (payload === 'create') {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Android enterprise created successfully',
          }),
        );
      }
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const androidEnterpriseSlice = createSlice({
  name: 'androidEnterprise',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAndroidEnterprise.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAndroidEnterprise.fulfilled, (state, action) => {
      const { EnterpriseID, DisplayName } = action.payload;
      if (EnterpriseID && DisplayName) {
        state.loading = false;
        state.activeStep = 2;
        state.enterprise.enterpriseId = EnterpriseID;
        state.enterprise.displayName = DisplayName;
      }
    });
    builder.addCase(getAndroidEnterprise.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      } else {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });
  },
});

export const { updateMessage } = androidEnterpriseSlice.actions;
export default androidEnterpriseSlice;
