import axiosInstance from '../../utils/axiosInstance';
import { APPLE_APPLICATION_SEARCH_LIST_ROUTE } from '../../utils/routes-defs';
import { IAppleApp } from './definitions';

export interface IAPIParams {
  [key: string]: string | number | (string | number)[];
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export async function searchAppleApps(params: string): Promise<any> {
  /* eslint-disable no-async-promise-executor */
  return new Promise<any>(async (resolve, reject) => {
    try {
      const response = await axiosInstance().get(
        `${APPLE_APPLICATION_SEARCH_LIST_ROUTE}?${params}`,
      );
      resolve(response.data.appleSearchedApps as IAppleApp[]);
    } catch (error: any) {
      reject(error);
    }
  });
}
