import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PolicyContext } from '../..';
import {
  createAndroidPolicy,
  updateAndroidPolicy,
  updateMessage,
} from '../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch } from '../../../../redux/store';
import { messageInit } from '../../../../utils/common-constants';
import { IAndroidPolicy, initAndroidPolicy } from '../../definitions';
import TabNavigation from '../TabNavigation';

interface IProps {
  isCreate: boolean;
  openPolicyDialog: boolean;
  handlePolicyDialog: (create: boolean, policy: IAndroidPolicy) => void;
}
function PolicyDialog(props: IProps) {
  const { isCreate, openPolicyDialog, handlePolicyDialog } = props;
  const { policy, setPolicy } = useContext(PolicyContext);

  const dispatch = useDispatch<AppDispatch>();

  const handleCreatePolicy = () => {
    if (policy.policyName === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Policy Name is required',
        }),
      );
      return;
    }

    dispatch(createAndroidPolicy(policy));

    handlePolicyDialog(true, policy);
    setPolicy(initAndroidPolicy);
  };

  const handleUpdatePolicy = () => {
    dispatch(updateAndroidPolicy(policy));

    handlePolicyDialog(false, policy);
    setPolicy(initAndroidPolicy);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      PaperProps={{
        sx: {
          height: '80vh',
        },
      }}
      onClose={() => handlePolicyDialog(true, initAndroidPolicy)}
      open={openPolicyDialog}
    >
      <DialogTitle>{isCreate ? 'Create Policy' : 'Edit Policy'}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => handlePolicyDialog(true, initAndroidPolicy)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME ? (
          <Grid
            container
            direction='row'
            justifyContent='flex-between'
            alignItems='center'
            paddingBottom={2}
            spacing={2}
          >
            <Grid item sm={12}>
              <Typography variant='body1' sx={{ color: 'red' }}>
                Default policy cannot be edited!
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          container
          direction='row'
          justifyContent='flex-between'
          alignItems='center'
          paddingBottom={2}
          spacing={2}
        >
          <Grid item sm={4}>
            <TextField
              fullWidth
              size='small'
              placeholder='Policy Name'
              disabled={!isCreate}
              value={policy.policyName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPolicy((prev) => ({ ...prev, policyName: event.target.value.trim() }));
              }}
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              fullWidth
              size='small'
              placeholder='Description'
              value={policy.policyDescription}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPolicy((prev) => ({ ...prev, policyDescription: event.target.value }));
              }}
            />
          </Grid>
        </Grid>
        <TabNavigation />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handlePolicyDialog(true, initAndroidPolicy)}>
          Cancel
        </Button>
        {isCreate ? (
          <Button variant='contained' onClick={handleCreatePolicy}>
            Create Policy
          </Button>
        ) : (
          <Button
            variant='contained'
            onClick={handleUpdatePolicy}
            disabled={policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(PolicyDialog);
