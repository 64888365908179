import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IAndroidApp, IAndroidPolicy } from '../../../pages/AndroidPolicies/definitions';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  ANDROID_ENTERPRISE_WEBTOKEN_GET_ROUTE,
  ANDROID_POLICY_CREATE_ROUTE,
  ANDROID_POLICY_DELETE_ROUTE,
  ANDROID_POLICY_LIST_ROUTE,
  ANDROID_POLICY_UPDATE_ROUTE,
} from '../../../utils/routes-defs';

interface IAndroidPolicySliceState {
  androidPolicies: IAndroidPolicy[];
  webtoken: string;
  loading: boolean;
  message: IMessage;
}

const initialState: IAndroidPolicySliceState = {
  androidPolicies: [],
  webtoken: '',
  loading: false,
  message: messageInit,
};

export interface IAndroidPolicyDeletePayload {
  androidPolicyId: string;
}
export const getAndroidPolicies = createAsyncThunk(
  'androidPolicy/getAndroidPolicies',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(ANDROID_POLICY_LIST_ROUTE);
      const addAppMeta = (application: IAndroidApp) => ({
        ...application,
        appMeta: {
          title: '',
          managedConfiguration: false,
          iconUrl: '',
          permissions: [],
        },
      });
      const allPolicies = response.data.allPolicies.map((policy: IAndroidPolicy) => ({
        ...policy,
        // Update the appCatalog with the modified applications
        appCatalog: {
          ...policy.appCatalog,
          applications: policy.appCatalog.applications.map(addAppMeta),
        },
      }));
      return allPolicies;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const createAndroidPolicy = createAsyncThunk(
  'androidPolicy/createAndroidPolicy',
  async (payload: IAndroidPolicy, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_POLICY_CREATE_ROUTE, {
        frontendPolicy: payload,
      });
      thunkAPI.dispatch(getAndroidPolicies());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);
export const deleteAndroidPolicy = createAsyncThunk(
  'androidPolicy/deleteAndroidPolicy',
  async (payload: IAndroidPolicyDeletePayload, thunkAPI) => {
    try {
      const { androidPolicyId } = payload;
      await axiosInstance().delete(`${ANDROID_POLICY_DELETE_ROUTE}?policyId=${androidPolicyId}`);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'Policy deleted successfully',
        }),
      );
      return payload.androidPolicyId;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const createWebtoken = createAsyncThunk(
  'androidPolicy/createWebtoken',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(ANDROID_ENTERPRISE_WEBTOKEN_GET_ROUTE);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: 'Could not load Google Playstore',
          }),
        );
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateAndroidPolicy = createAsyncThunk(
  'androidPolicy/updateAndroidPolicy',
  async (payload: IAndroidPolicy, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_POLICY_UPDATE_ROUTE, {
        frontendPolicy: payload,
      });
      thunkAPI.dispatch(getAndroidPolicies());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const androidPolicySlice = createSlice({
  name: 'androidPolicy',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get policies
    builder.addCase(getAndroidPolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAndroidPolicies.fulfilled, (state, action) => {
      const allPolicies = action.payload;
      state.loading = false;
      state.androidPolicies = allPolicies;
    });
    builder.addCase(getAndroidPolicies.rejected, (state) => {
      state.loading = false;
    });

    // create policy
    builder.addCase(createAndroidPolicy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAndroidPolicy.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(createAndroidPolicy.rejected, (state) => {
      state.loading = false;
    });

    // delete policy
    builder.addCase(deleteAndroidPolicy.fulfilled, (state, action) => {
      state.androidPolicies = state.androidPolicies.filter(
        (policy) => policy.policyId !== action.payload,
      );
    });
    builder.addCase(deleteAndroidPolicy.rejected, (state, action) => {
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // update policy
    builder.addCase(updateAndroidPolicy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAndroidPolicy.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(updateAndroidPolicy.rejected, (state) => {
      state.loading = false;
    });

    // create webtoken
    builder.addCase(createWebtoken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWebtoken.fulfilled, (state, action) => {
      const { webtoken } = action.payload;
      state.loading = false;
      state.webtoken = webtoken;
    });
    builder.addCase(createWebtoken.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = androidPolicySlice.actions;
export default androidPolicySlice;
