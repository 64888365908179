import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import SyncIcon from '@mui/icons-material/Sync';
import { Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import { ISelectItem } from '../../components/Enrollments/definitions';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { APPLE_DEVICE_CONST, EMPTY_STRING } from '../../constants';
import MainLayout from '../../layouts/MainLayout';
import {
  activateDefaultVPNProfile,
  getAppleDevices,
  updateMessage,
} from '../../redux/slices/appleDevice/appleDeviceSlice';
import { getAppleDeviceGroups } from '../../redux/slices/appleDeviceGroup/appleDeviceGroupSlice';
import { getAppleEnrollmentDetails } from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import DeviceEnrollment from './DeviceEnrollment';

export interface IAppleDevice {
  id: number;
  _id: string;
  type: string;
  name: string;
  osVersion: string;
  enabled: boolean;
  serialNumber: string;
  user: string;
  deviceGroupId: string;
  deviceGroupName: string;
  moVPN: boolean;
}

function AppleDevices() {
  const dispatch = useDispatch<AppDispatch>();

  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const appleDevice = useSelector((state: RootState) => state.appleDevice);
  const appleDeviceGroups = useSelector(
    (state: RootState) => state.appleDeviceGroup.appleDeviceGroups,
  );

  const [openEnrolmentDialog, setOpenEnrolmentDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IAppleDevice[]>([]);

  useEffect(() => {
    if (
      appleEnrollment.appleEnrollment.appleId === EMPTY_STRING ||
      appleEnrollment.appleEnrollment.apnsTopic === EMPTY_STRING
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
    dispatch(getAppleDeviceGroups());
    dispatch(getAppleDevices());
  }, []);

  useEffect(() => {
    setRows(appleDevice.appleDevices);
  }, [appleDevice.appleDevices]);

  const handleEnrolmentDialog = () => {
    setOpenEnrolmentDialog((prev) => !prev);
  };

  const appleDeviceGroupList: ISelectItem[] = [];
  appleDeviceGroups.map((group) =>
    appleDeviceGroupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.description,
    }),
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'type',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 110,
      renderCell: (params) => (
        <span>
          {params.row.enabled === 1
            ? APPLE_DEVICE_CONST.DEVICE_ACTIVE
            : APPLE_DEVICE_CONST.DEVICE_INACTIVE}
        </span>
      ),
    },
    {
      field: 'osVersion',
      headerName: 'OS',
      width: 110,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 110,
    },
    {
      field: 'serialNumber',
      headerName: 'Serial',
      width: 140,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 180,
    },
    {
      field: 'deviceGroupName',
      headerName: 'Group',
      width: 140,
    },
    {
      field: 'moVpn',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant='contained'
            size='small'
            fullWidth
            disabled={
              params.row.enabled === 1 && (params.row.moVPN === 1 || params.row.moVPN === 2)
            }
            onClick={() => dispatch(activateDefaultVPNProfile({ deviceId: params.row._id }))}
          >
            {params.row.enabled === 0 && APPLE_DEVICE_CONST.VPN_ACTIVATE}
            {params.row.enabled === 1 && params.row.moVPN === 0 && APPLE_DEVICE_CONST.VPN_ACTIVATE}
            {params.row.enabled === 1 && params.row.moVPN === 1 && APPLE_DEVICE_CONST.VPN_INITIATED}
            {params.row.enabled === 1 && params.row.moVPN === 2 && APPLE_DEVICE_CONST.VPN_ACTIVATED}
          </Button>
          <Tooltip title={APPLE_DEVICE_CONST.VPN_RETRY} placement='right-end'>
            <IconButton
              onClick={() => dispatch(activateDefaultVPNProfile({ deviceId: params.row._id }))}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title={APPLE_DEVICE_CONST.PAGE_HEADER} />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button
            variant='contained'
            onClick={() => dispatch(getAppleDevices())}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {appleDevice.loading ? <CircularProgress color='inherit' size={20} /> : <SyncIcon />}
            Sync Devices
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={handleEnrolmentDialog}>
            <AddIcon />
            Enroll
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={rows} columns={columns} />
      <DeviceEnrollment open={openEnrolmentDialog} handleDialog={handleEnrolmentDialog} />
      {(appleDevice.message.error || appleDevice.message.success) && (
        <ErrorSuccessSnackbar
          opened={appleDevice.message.error || appleDevice.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appleDevice.message.errorMessage}
          successMessage={appleDevice.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleDevices;
