import { Checkbox, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../../apis';
import { RootState } from '../../../redux/store';
import { IAndroidApp } from '../../AndroidPolicies/definitions';

interface IProps {
  app: IAndroidApp;
  setApps: React.Dispatch<React.SetStateAction<IAndroidApp[]>>;
  handleAppSelection: (appname: string) => void;
}
function AppDetail(props: IProps) {
  const { app, setApps, handleAppSelection } = props;
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );

  useEffect(() => {
    const fetchAppDetails = async () => {
      if (app.appMeta.iconUrl === '') {
        try {
          const response = await getApp(app.appname, enterpriseId);
          const { iconUrl, title } = response.data.application;
          setApps((prev: IAndroidApp[]) => {
            const allApps = [...prev];
            const selectedAppIndex = allApps.findIndex(
              (item: IAndroidApp) => item.appname === app.appname,
            );
            if (selectedAppIndex !== -1) {
              allApps[selectedAppIndex].appMeta.iconUrl = iconUrl;
              allApps[selectedAppIndex].appMeta.title = title;
            }
            return allApps;
          });
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchAppDetails();
  }, []);
  return (
    <Grid key={app.appname} container>
      <Grid item sm={1}>
        <Checkbox checked={!app.appDisabled} onChange={() => handleAppSelection(app.appname)} />
      </Grid>
      <Grid item sm={1}>
        <img src={app.appMeta.iconUrl} alt={app.appMeta.title} height={40} width={40} />
      </Grid>
      <Grid item sm={4}>
        <Typography variant='subtitle1'>{app.appMeta.title}</Typography>
      </Grid>
    </Grid>
  );
}

export default AppDetail;
