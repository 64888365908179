export const months = [
  { view: 'Jan', value: '1' },
  { view: 'Feb', value: '2' },
  { view: 'Mar', value: '3' },
  { view: 'Apr', value: '4' },
  { view: 'May', value: '5' },
  { view: 'Jun', value: '6' },
  { view: 'Jul', value: '7' },
  { view: 'Aug', value: '8' },
  { view: 'Sep', value: '9' },
  { view: 'Oct', value: '10' },
  { view: 'Nov', value: '11' },
  { view: 'Dec', value: '12' },
];
export const days = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
