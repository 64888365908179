import InfoIcon from '@mui/icons-material/Info';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../../..';
import { ISystemUpdateFreezePeriod } from '../../../../definitions';
import { days, months } from './definitions';

function FreezePeriod() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { systemUpdateSetting } = policy.advanceControls.securityPermissions;

  const handleDate = (event: SelectChangeEvent, dateType: 'startDate' | 'endDate') => {
    const { name, value } = event.target; // name is either 'month' or 'day'
    setPolicy((prev) => {
      const newFreezePeriods = [
        ...(prev.advanceControls?.securityPermissions.systemUpdateSetting.freezePeriods || []),
      ];

      if (newFreezePeriods.length > 0) {
        // Update the startDate of the first freeze period
        newFreezePeriods[0] = {
          ...newFreezePeriods[0],
          [dateType]: {
            ...newFreezePeriods[0][dateType],
            [name]: value,
          },
        };
      } else {
        // create a new freeze start date
        const newDate = {
          year: '0',
          month: '1',
          day: '1',
        };
        if (name === 'month' || name === 'day') {
          newDate[name] = value;
        }
        newFreezePeriods[0] = {
          ...newFreezePeriods[0],
          [dateType]: newDate,
        };
      }

      // Update the state with the modified freeze periods
      return {
        ...prev,
        advanceControls: {
          ...prev.advanceControls,
          securityPermissions: {
            ...prev.advanceControls?.securityPermissions,
            systemUpdateSetting: {
              ...prev.advanceControls?.securityPermissions.systemUpdateSetting,
              freezePeriods: newFreezePeriods,
            },
          },
        },
      };
    });
  };

  const resetFreezePeriod = () => {
    setPolicy((prev) => {
      const newFreezePeriods: ISystemUpdateFreezePeriod[] = [];
      return {
        ...prev,
        advanceControls: {
          ...prev.advanceControls,
          securityPermissions: {
            ...prev.advanceControls?.securityPermissions,
            systemUpdateSetting: {
              ...prev.advanceControls?.securityPermissions.systemUpdateSetting,
              freezePeriods: newFreezePeriods,
            },
          },
        },
      };
    });
  };

  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item sm={4}>
          <Typography variant='body1' color='text.secondary'>
            Freeze Period
            <Tooltip title='When a device’s clock is within the freeze period, all incoming system updates (including security patches) are blocked and won’t be installed.'>
              <IconButton size='small'>
                <InfoIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item sm={1}>
          <Tooltip title='Reset freeze period'>
            <IconButton onClick={resetFreezePeriod}>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item sm={4}>
          <Typography variant='body1' color='text.secondary'>
            Start Date
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <FormControl fullWidth size='small'>
            <InputLabel id='freezeStartMonth-select-label'>Month</InputLabel>
            <Select
              labelId='freezeStartMonth-select-label'
              id='freezeStartMonth-select'
              label='Start'
              size='small'
              name='month'
              value={
                systemUpdateSetting?.freezePeriods?.length
                  ? systemUpdateSetting.freezePeriods[0].startDate?.month
                  : ''
              }
              onChange={(e) => handleDate(e, 'startDate')}
            >
              {months.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Typography variant='body1'>{item.view}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth size='small'>
            <InputLabel id='freezeStartDay-select-label'>Day</InputLabel>
            <Select
              labelId='freezeStartDay-select-label'
              id='freezeStartDay-select'
              label='Start'
              size='small'
              name='day'
              value={
                systemUpdateSetting?.freezePeriods?.length
                  ? systemUpdateSetting.freezePeriods[0].startDate?.day
                  : ''
              }
              onChange={(e) => handleDate(e, 'startDate')}
            >
              {days.map((item) => (
                <MenuItem key={item} value={item}>
                  <Typography variant='body1'>{item}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginTop={2}
      >
        <Grid item sm={4}>
          <Typography variant='body1' color='text.secondary'>
            End Date
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <FormControl fullWidth size='small'>
            <InputLabel id='freezeEndMonth-select-label'>Month</InputLabel>
            <Select
              labelId='freezeEndMonth-select-label'
              id='freezeEndMonth-select'
              label='End'
              size='small'
              name='month'
              value={
                systemUpdateSetting?.freezePeriods?.length
                  ? systemUpdateSetting.freezePeriods[0].endDate?.month
                  : ''
              }
              onChange={(e) => handleDate(e, 'endDate')}
            >
              {months.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <Typography variant='body1'>{item.view}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth size='small'>
            <InputLabel id='freezeEndDay-select-label'>Day</InputLabel>
            <Select
              labelId='freezeEndDay-select-label'
              id='freezeEndDay-select'
              label='End'
              size='small'
              name='day'
              value={
                systemUpdateSetting?.freezePeriods?.length
                  ? systemUpdateSetting.freezePeriods[0].endDate?.day
                  : ''
              }
              onChange={(e) => handleDate(e, 'endDate')}
            >
              {days.map((item) => (
                <MenuItem key={item} value={item}>
                  <Typography variant='body1'>{item}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default FreezePeriod;
