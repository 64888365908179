import { Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';

function DataSharing() {
  const { policy, setPolicy } = useContext(PolicyContext);

  const {
    workContactsScope,
    copyPasteScope,
    dataSharingScope,
    widgetsDefaultScope,
  } = policy.policyControls.crossProfileScopes;
  const {
    usbAccess,
    bluetoothDisabled,
    printingPolicy,
  } = policy.advanceControls.deviceConnectivitySettings;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      policyControls: {
        ...prev.policyControls,
        crossProfileScopes: {
          ...prev.policyControls?.crossProfileScopes,
          [name]: value,
        },
      },
    }));
  };

  const handleConnectivityAccessChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      advanceControls: {
        ...prev.advanceControls,
        deviceConnectivitySettings: {
          ...prev.advanceControls?.deviceConnectivitySettings,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Show Work Contacts in Personal Profile',
      name: 'workContactsScope',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
      ],
      value: workContactsScope,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      handleChange,
    },
    {
      property: 'Copy Paste',
      name: 'copyPasteScope',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny Work to Personal', value: 'disallowed' },
      ],
      value: copyPasteScope,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      handleChange,
    },
    {
      property: 'Data Sharing',
      name: 'dataSharingScope',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
        { view: 'Deny Work to Personal', value: 'worktopersonalblocked' },
      ],
      value: dataSharingScope,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      handleChange,
    },
    {
      property: 'Widgets on Homescreen',
      name: 'widgetsDefaultScope',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
      ],
      value: widgetsDefaultScope,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      handleChange,
    },
    {
      property: 'USB Data Access',
      name: 'usbAccess',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny File Transfer', value: 'disallow_file_transfer' },
        { view: 'Deny Data Transfer', value: 'disallow_data_transfer' },
      ],
      value: usbAccess,
      androidVersion: '5.1+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleConnectivityAccessChange,
    },
    {
      property: 'Bluetooth Sharing',
      name: 'bluetoothDisabled',
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: bluetoothDisabled,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleConnectivityAccessChange,
    },
    {
      property: 'Printing Access',
      name: 'printingPolicy',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
      ],
      value: printingPolicy,
      androidVersion: '5.1+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleConnectivityAccessChange,
    },
  ];

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        {propertyList.map((item) => (
          <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
        ))}
      </Grid>
    </Grid>
  );
}

export default DataSharing;
