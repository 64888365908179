import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function EnrollmentSelectInput(props: any) {
  const { fieldId, fieldLabel, entries, selectedEntry, handler } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id={`${fieldId}-label`}>{fieldLabel}</InputLabel>
      <Select
        labelId={`${fieldId}-label`}
        id={fieldId}
        label={fieldLabel}
        name={fieldLabel}
        value={selectedEntry}
        renderValue={(selected) => (
          <>
            <Typography variant='body1'>{selected.value1}</Typography>
            <Typography variant='body2'>{selected.value2}</Typography>
          </>
        )}
        onChange={handler}
      >
        {entries.map((entry: any) => (
          <MenuItem
            key={entry._id}
            value={entry}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Typography variant='body1'>{entry.value1}</Typography>
            <Typography variant='body2'>{entry.value2}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(EnrollmentSelectInput);
