import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import { getAndroidEnterprise } from '../../redux/slices/androidEnterprise/androidEnterpriseSlice';
import { getAndroidPolicies } from '../../redux/slices/androidPolicy/androidPolicySlice';
import {
  createDeviceGroup,
  deleteDeviceGroup,
  getDeviceGroups,
  updateDeviceGroupPolicy,
  updateMessage,
} from '../../redux/slices/deviceGroup/deviceGroupSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import { GET } from '../AndroidEnterprise';
import {
  ADD_DEVICE_GROUP_NOTICE,
  GROUP_NAME_REQUIRED_ERROR_MSG,
  POLICY_REQUIRED_ERROR_MSG,
} from './constants';
import DeleteDialogBox from '../../components/DeleteDialogbox';

export interface IDeviceGroup {
  id?: number;
  _id: string;
  name: string;
  description: string;
  customerTenantId?: string;
  androidPolicyId: {
    _id: string;
    name: string;
  };
  policy: string;
  policyId: string;
}

export const groupInit: IDeviceGroup = {
  id: 0,
  _id: '',
  name: '',
  description: '',
  customerTenantId: '',
  androidPolicyId: {
    _id: '',
    name: '',
  },
  policy: '',
  policyId: '',
};

export interface ISelectedDevicegroup {
  group: IDeviceGroup;
  loading: boolean;
}

interface ISelectedPolicy {
  _id: string;
  name: string;
}

const selectedPolicyInit: ISelectedPolicy = {
  _id: '',
  name: '',
};

function DeviceGroup() {
  const dispatch = useDispatch<AppDispatch>();
  const deviceGroup = useSelector((state: RootState) => state.deviceGroup);
  const androidPolicy = useSelector((state: RootState) => state.androidPolicy);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<ISelectedDevicegroup | null>(null);
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<IDeviceGroup>(groupInit);
  const [selectedPolicy, setSelectedPolicy] = useState<ISelectedPolicy>(selectedPolicyInit);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setGroup((prev) => ({
      ...prev,
      [name]: name === 'name' ? value.trim() : value,
    }));
  };

  const addDeviceGroup = () => {
    if (group.name === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: GROUP_NAME_REQUIRED_ERROR_MSG,
        }),
      );
      return;
    }
    if (selectedPolicy._id === '' || selectedPolicy.name === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: POLICY_REQUIRED_ERROR_MSG,
        }),
      );
      return;
    }
    const payload: IDeviceGroup = {
      ...group,
      androidPolicyId: {
        _id: selectedPolicy._id,
        name: selectedPolicy.name,
      },
    };

    dispatch(createDeviceGroup(payload));
    setOpen(false);
    setGroup(groupInit);
    setSelectedPolicy(selectedPolicyInit);
  };

  const updatePolicyForDeviceGroup = () => {
    const payload: IDeviceGroup = {
      ...group,
      androidPolicyId: {
        _id: selectedPolicy._id,
        name: selectedPolicy.name,
      },
    };
    dispatch(updateDeviceGroupPolicy(payload));
    setOpen(false);
    setGroup(groupInit);
    setSelectedPolicy(selectedPolicyInit);
  };

  useEffect(() => {
    dispatch(getDeviceGroups()); // get all groups
    dispatch(getAndroidPolicies()); // get all policies
    if (enterpriseId === '') {
      dispatch(getAndroidEnterprise(GET));
    }
  }, []);

  const selectPolicy = (e: SelectChangeEvent) => {
    const policyDetails = e.target.value.split(' ');
    const policy = {
      _id: policyDetails[0],
      name: policyDetails[1],
    };
    setSelectedPolicy(policy);
  };

  const handleDialog = () => {
    setOpen((prev) => !prev);
    setIsCreate(true);
    setGroup(groupInit);
    setSelectedPolicy(selectedPolicyInit);
  };

  const handleEditPolicyDialog = (e: React.MouseEvent, row: IDeviceGroup) => {
    setOpen((prev) => !prev);
    setIsCreate(false);
    setGroup({
      _id: row._id,
      name: row.name,
      description: row.description,
      androidPolicyId: {
        _id: row.policyId,
        name: row.policy,
      },
      policy: row.policy,
      policyId: row.policyId,
    });

    const policy = {
      _id: row.policyId,
      name: row.policy,
    };
    setSelectedPolicy(policy);
  };

  const handleDeleteDialog = (group: IDeviceGroup) => {
    setSelectedDeviceGroup({ group, loading: false });
    setDeleteOpen(true);
  };

  const handleDeleteDeviceGroup = async () => {
    if (selectedDeviceGroup) {
      if (
        selectedDeviceGroup.group.name.toLowerCase() ===
        process.env.REACT_APP_DEFAULT_DEVICE_GROUP_NAME
      ) {
        // Show error message when trying to delete a default group
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: 'The default device group cannot be deleted.',
          }),
        );
        setDeleteOpen(false);
        return;
      }

      // Proceed with deletion if the group is not default
      setSelectedDeviceGroup({ group: selectedDeviceGroup.group, loading: true });
      const deletePayload = {
        deviceGroupId: selectedDeviceGroup.group._id,
      };
      await dispatch(deleteDeviceGroup(deletePayload));
      setDeleteOpen(false);
      setSelectedDeviceGroup(null);
    }
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedDeviceGroup(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
    },
    {
      field: 'policy',
      headerName: 'Policy',
      width: 250,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <ButtonGroup disableElevation variant='contained'>
          <Tooltip title='Edit'>
            <Button
              variant='outlined'
              size='small'
              onClick={(e) => handleEditPolicyDialog(e, params.row)}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title='Delete'>
            <Button
              variant='outlined'
              size='small'
              onClick={() => handleDeleteDialog(params.row)}
              disabled={
                selectedDeviceGroup?.loading && selectedDeviceGroup?.group._id === params.row._id
              }
            >
              {selectedDeviceGroup?.loading && selectedDeviceGroup?.group._id === params.row._id ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <DeleteIcon color='error' />
              )}
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader title='Device Groups' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={handleDialog}>
            <AddIcon /> Add Group
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={deviceGroup.deviceGroups} columns={columns} />
      <Dialog fullWidth onClose={handleDialog} open={open}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent dividers>
          <Alert severity='info' sx={{ marginBottom: 2 }}>
            <AlertTitle>{ADD_DEVICE_GROUP_NOTICE}</AlertTitle>
          </Alert>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            padding={1}
            spacing={1}
          >
            <Grid item sm={3}>
              <Typography>Group name</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='name'
                name='name'
                placeholder='Name'
                value={group.name}
                disabled={!isCreate}
                onChange={onChange}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='description'
                name='description'
                placeholder='Description'
                value={group.description}
                disabled={!isCreate}
                onChange={onChange}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>Policy</Typography>
            </Grid>
            <Grid item sm={9}>
              <FormControl fullWidth>
                <InputLabel id='policy-select-label'>Policy</InputLabel>
                <Select
                  labelId='policy-select-label'
                  id='select-policy'
                  label='Policy'
                  value={`${selectedPolicy._id} ${selectedPolicy.name}`}
                  onChange={selectPolicy}
                >
                  {androidPolicy.androidPolicies.map((policyItem) => (
                    <MenuItem
                      key={policyItem.policyId}
                      value={`${policyItem.policyId} ${policyItem.policyName}`}
                    >
                      <Typography variant='body1'>{policyItem.policyName}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant='contained'
              onClick={isCreate ? () => addDeviceGroup() : () => updatePolicyForDeviceGroup()}
            >
              {isCreate ? 'Add Group' : 'Update Policy'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <DeleteDialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        onDelete={handleDeleteDeviceGroup}
        item='device group'
      />
      {(deviceGroup.message.error || deviceGroup.message.success) && (
        <ErrorSuccessSnackbar
          opened={deviceGroup.message.error || deviceGroup.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={deviceGroup.message.errorMessage}
          successMessage={deviceGroup.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default DeviceGroup;
