/* eslint-disable react/jsx-props-no-spreading */

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { ReactNode, useState } from 'react';

interface IProps {
  ariaControls: string;
  summary: ReactNode;
  details?: ReactNode;
}
function CustomAccordion(props: IProps) {
  const { ariaControls, summary, details } = props;
  const [expand, setExpand] = useState(false);
  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };
  return (
    <Accordion expanded={expand}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon onClick={toggleAccordion} />}
        aria-controls={ariaControls}
        id={ariaControls}
      >
        {summary}
      </AccordionSummary>
      {details && <AccordionDetails>{details}</AccordionDetails>}
    </Accordion>
  );
}

CustomAccordion.defaultProps = {
  details: null,
};

export default CustomAccordion;
