import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Keyboard() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_dictionary_word_lookup,
    allow_predictive_keyboard,
    allow_auto_correct,
    allow_spellcheck,
    allow_keyboard_shortcuts,

    allow_dictation,
    process_dictation_on_device,
    process_translation_on_device,
    allow_keyboard_swipe,
  } = profile.restrictions.keyboard;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        keyboard: {
          ...prev.restrictions.keyboard,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Dictionary word lookup',
      name: 'allow_dictionary_word_lookup',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_dictionary_word_lookup,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.1.3',
          isSupervised: true,
        },
        macos: {
          version: '10.11',
        },
      },
    },
    {
      property: 'Predictive keyboard',
      name: 'allow_predictive_keyboard',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_predictive_keyboard,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.1.3',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Auto correct',
      name: 'allow_auto_correct',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_auto_correct,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.1.3',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Spellcheck',
      name: 'allow_spellcheck',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_spellcheck,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.1.3',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Shortcuts',
      name: 'allow_keyboard_shortcuts',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_keyboard_shortcuts,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Dictation',
      name: 'allow_dictation',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_dictation,
      handleChange,
      supportedOS: {
        ios: {
          version: '10.3',
          isSupervised: true,
        },
        macos: {
          version: '10.13',
        },
      },
      children: [
        {
          property: 'Process dictation on device',
          name: 'process_dictation_on_device',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: process_dictation_on_device,
          handleChange,
          supportedOS: {
            ios: {
              version: '14.5',
            },
            macos: {
              version: '14',
            },
            watchos: {
              version: '10',
            },
          },
        },
      ],
    },
    {
      property: 'Process translation on device',
      name: 'process_translation_on_device',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: process_translation_on_device,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Swipe keyboard',
      name: 'allow_keyboard_swipe',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_keyboard_swipe,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
          isSupervised: true,
        },
      },
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Keyboard;
