import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAppsList } from '../../../apis';
import { IAndroidApp } from '../../AndroidPolicies/definitions';
import { IIssueCommandEnum, IIssueCommandPayload } from '../definitions';
import AppDetail from './AppDetail';

interface IProps {
  deviceName: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  policyName: string;
  sendCommand: (payload: IIssueCommandPayload) => void;
}
function ClearAppsDataDialog(props: IProps) {
  const { deviceName, open, setOpen, policyName, sendCommand } = props;
  const [apps, setApps] = useState<IAndroidApp[]>([]);

  const handleAppSelection = (appname: string) => {
    const allApps = [...apps];
    const selectedAppIndex = allApps.findIndex((app) => app.appname === appname);
    if (selectedAppIndex !== -1) {
      allApps[selectedAppIndex].appDisabled = !allApps[selectedAppIndex].appDisabled;
    }
    setApps(allApps);
  };

  useEffect(() => {
    const fetchAppsList = async () => {
      try {
        const response = await getAppsList(policyName);
        if (response.data && response.data?.policy?.appCatalog?.applications) {
          setApps(
            response.data.policy.appCatalog.applications.map((app: IAndroidApp) => ({
              ...app,
              appMeta: {
                iconUrl: '',
                title: '',
              },
            })),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchAppsList();
  }, []);

  const handleSave = () => {
    const appsList = apps.filter((app) => app.appDisabled === false).map((app) => app.appname);
    const payload: IIssueCommandPayload = {
      deviceName,
      commandDetails: {
        device_command: IIssueCommandEnum.CLEAR_APP_DATA,
        clearAppsDataParams: {
          apps: appsList,
        },
      },
    };
    sendCommand(payload);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      sx={{ height: '60vh' }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>Clear Apps Data</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {apps.length > 0 ? (
          apps.map((app: IAndroidApp) => (
            <AppDetail
              key={app.appname}
              app={app}
              handleAppSelection={handleAppSelection}
              setApps={setApps}
            />
          ))
        ) : (
          <Grid container justifyContent='center'>
            <CircularProgress size={35} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSave}>
          Clear Selected Apps Data
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClearAppsDataDialog;
