import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../../..';
import CustomSelectInput from '../../../../../../components/CustomSelectInput';
import { times, timezones } from './definitions';

function TimeRestriction() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { enable, startTime, endTime, timezone, offset } = policy.customPermissions.timeRestriction;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    let newOffsetValue = offset;
    if (name === 'timezone') {
      const selectedTimezone = timezones.find((item) => item.tz === value);
      if (selectedTimezone) {
        newOffsetValue = selectedTimezone.offset;
      }
    }
    setPolicy((prev) => ({
      ...prev,
      customPermissions: {
        ...prev.customPermissions,
        timeRestriction: {
          ...prev.customPermissions.timeRestriction,
          [name]: value,
          offset: newOffsetValue,
        },
      },
    }));
  };

  return (
    <div style={{ width: '400px' }}>
      <Grid container direction='column' paddingBottom={2}>
        <CustomSelectInput
          name='enable'
          menuItems={[
            { view: 'Enable', value: true },
            { view: 'Disable', value: false },
          ]}
          value={enable}
          handleChange={handleChange}
        />
      </Grid>
      <FormControl fullWidth size='small'>
        <InputLabel id='select-label'>Timezone</InputLabel>
        <Select
          fullWidth
          labelId='timezone-select-label'
          id='timezone-select'
          label='Timezone'
          size='small'
          name='timezone'
          disabled={!enable}
          value={timezone}
          onChange={handleChange}
        >
          {timezones.map((item) => (
            <MenuItem key={item.tz} value={item.tz}>
              <Typography variant='body1'>{item.tz}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel id='user-select-label'>From</InputLabel>
          <Select
            labelId='startTime-select-label'
            id='startTime-select'
            label='From'
            size='small'
            name='startTime'
            disabled={!enable}
            value={startTime}
            onChange={handleChange}
          >
            {times.map((time) => (
              <MenuItem key={time} value={time}>
                <Typography variant='body1'>{time}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='user-select-label'>To</InputLabel>
          <Select
            labelId='endTime-select-label'
            id='endTime-select'
            label='To'
            size='small'
            name='endTime'
            disabled={!enable}
            value={endTime}
            onChange={handleChange}
          >
            {times.map((time) => (
              <MenuItem key={time} value={time}>
                <Typography variant='body1'>{time}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
}

export default TimeRestriction;
