import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppGroupContext } from '..';
import {
  createAppleAppGroup,
  updateAppleAppGroup,
  updateMessage,
} from '../../../redux/slices/appleAppGroup/appleAppGroupSlice';
import { AppDispatch } from '../../../redux/store';
import { messageInit } from '../../../utils/common-constants';
import AppCard from '../AppCard';
import AppSearch from '../AppSearch';
import { IAppleAppGroup, initAppleAppGroup } from '../definitions';

interface IProps {
  isCreate: boolean;
  openAppGroupDialog: boolean;
  handleAppGroupDialog: (create: boolean, appGroup: IAppleAppGroup) => void;
}

function AppGroupDialog(props: IProps) {
  const dispatch = useDispatch<AppDispatch>();

  const { isCreate, openAppGroupDialog, handleAppGroupDialog } = props;
  const { appGroup, setAppGroup } = useContext(AppGroupContext);

  const handleCreateAppGroup = () => {
    if (appGroup.name === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'AppGroup name is required',
        }),
      );
      return;
    }
    dispatch(createAppleAppGroup(appGroup));

    handleAppGroupDialog(true, appGroup);
    setAppGroup(initAppleAppGroup);
  };

  const handleUpdateAppGroup = () => {
    dispatch(updateAppleAppGroup(appGroup));

    handleAppGroupDialog(false, appGroup);
    setAppGroup(initAppleAppGroup);
  };

  const dialogTitle = `${isCreate ? 'Create' : 'Edit'} AppGroup`;

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      PaperProps={{
        sx: {
          height: '80vh',
        },
      }}
      open={openAppGroupDialog}
      onClose={() => handleAppGroupDialog(true, initAppleAppGroup)}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => handleAppGroupDialog(true, initAppleAppGroup)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          direction='row'
          justifyContent='flex-between'
          alignItems='center'
          paddingBottom={2}
          spacing={2}
        >
          <Grid item sm={4}>
            <TextField
              fullWidth
              size='small'
              placeholder='AppGroup Name'
              disabled={!isCreate}
              value={appGroup.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAppGroup((prev) => ({ ...prev, name: event.target.value.trim() }));
              }}
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              fullWidth
              size='small'
              placeholder='Description'
              value={appGroup.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAppGroup((prev) => ({ ...prev, description: event.target.value }));
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='flex-between' spacing={2}>
          <Grid item sm={4}>
            {appGroup.apps.length > 0 ? (
              appGroup.apps.map((app) => <AppCard app={app} key={app.bundleId} />)
            ) : (
              <Typography>No app selected</Typography>
            )}
          </Grid>
          <Grid item sm={8} minHeight={550}>
            <AppSearch />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleAppGroupDialog(true, initAppleAppGroup)}>
          Cancel
        </Button>
        {isCreate ? (
          <Button variant='contained' onClick={handleCreateAppGroup}>
            Create
          </Button>
        ) : (
          <Button variant='contained' onClick={handleUpdateAppGroup} disabled={false}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AppGroupDialog;
