/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function CustomTable(props: any) {
  const { rows, columns, ...restProps } = props;
  return (
    <Box sx={{ height: 500, p: 2, pt: 0 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={45}
        columnHeaderHeight={45}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              _id: false,
            },
          },
        }}
        {...restProps}
      />
    </Box>
  );
}

export default CustomTable;
