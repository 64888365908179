import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PolicyContext } from '../../../..';
import DeviceProfileType from '../../../../constants';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import AllPermissions from './RuntimePermissionsDialog';
import { IAndroidApp, IAndroidPolicy, IApplicationMeta } from '../../../../definitions';
import { getApp } from '../../../../../../apis';
import { RootState } from '../../../../../../redux/store';

const fetchApplicationMeta = async (
  policy: IAndroidPolicy,
  enterpriseId: string,
): Promise<IAndroidPolicy> => {
  if (policy.appCatalog.applications.length > 0) {
    const updatedApplications = policy.appCatalog.applications.map(
      (app) =>
        /* eslint-disable no-async-promise-executor */
        new Promise<IAndroidApp>(async (resolve) => {
          try {
            const response = await getApp(app.appname, enterpriseId);
            const { iconUrl, title, managedConfiguration, permissions } = response.data.application;

            const updatedApp = {
              ...app,
              appMeta: {
                ...app.appMeta,
                iconUrl,
                title,
                managedConfiguration,
                permissions: permissions as IApplicationMeta['permissions'],
              },
            };
            resolve(updatedApp);
          } catch (error) {
            console.error(`Failed to get app data for ${app.appname}`, error);
            resolve(app);
          }
        }),
    );
    const apps = await Promise.all(updatedApplications);
    return {
      ...policy,
      appCatalog: {
        ...policy.appCatalog,
        applications: apps,
      },
    };
  }
  return policy;
};

function AllAppsPolicy() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { appAutoUpdatePolicy, allAppsPolicy } = policy.appCatalog;
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );

  const [openAllPermissionsDialog, setOpenAllPermissionsDialog] = useState<boolean>(false);
  const handleAllPermissionsDialog = () => {
    setOpenAllPermissionsDialog((prev) => !prev);
  };
  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      appCatalog: {
        ...prev.appCatalog,
        [name]: value,
      },
    }));
  };

  const propertyList = [
    {
      property: 'Auto-Update Default Setting',
      name: 'appAutoUpdatePolicy',
      menuItems: [
        {
          view: 'Always',
          value: 'always',
          tooltip: 'Apps are auto-updated at any time. Data charges may apply.',
        },
        { view: 'Never', value: 'never', tooltip: '	Apps are never auto-updated.' },
        {
          view: 'Wifi Only',
          value: 'wifi_only',
          tooltip: '	Apps are auto-updated over Wi-Fi only.',
        },
        {
          view: 'User Choice',
          value: 'user_choice',
          tooltip: 'The user can control auto-updates.',
        },
      ],
      value: appAutoUpdatePolicy,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Runtime Permissions Default Setting',
      name: 'allAppsPolicy',
      menuItems: [
        { view: 'Grant', value: 'grant' },
        { view: 'Deny', value: 'deny' },
        { view: 'Prompt', value: 'prompt' },
      ],
      value: allAppsPolicy,
      androidVersion: '6.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
  ];

  useEffect(() => {
    const updatePolicy = async () => {
      const updatedPolicy = await fetchApplicationMeta(policy, enterpriseId);
      setPolicy(updatedPolicy);
    };
    updatePolicy();
  }, []);

  return (
    <>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          {propertyList.map((item) => (
            <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
          ))}
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          <Button fullWidth variant='outlined' onClick={handleAllPermissionsDialog}>
            Modify Runtime Permissions
          </Button>
          <AllPermissions open={openAllPermissionsDialog} onClose={handleAllPermissionsDialog} />
        </Grid>
      </Grid>
    </>
  );
}

export default AllAppsPolicy;
