import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import {
  getAppleAppGroups,
  updateMessage,
} from '../../redux/slices/appleAppGroup/appleAppGroupSlice';
import { getAppleEnrollmentDetails } from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import AppGroupCard from './AppGroupCard';
import AppGroupDialog from './AppGroupDialog';
import { IAppleAppGroup, initAppleAppGroup } from './definitions';

export const AppGroupContext = createContext<{
  appGroup: IAppleAppGroup;
  setAppGroup: React.Dispatch<React.SetStateAction<IAppleAppGroup>>;
}>({ appGroup: initAppleAppGroup, setAppGroup: () => {} });

function AppleAppGroups() {
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const appGroups = useSelector((state: RootState) => state.appleAppGroup);

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [appGroup, setAppGroup] = useState<IAppleAppGroup>(initAppleAppGroup);
  const [openappGroupDialog, setOpenAppGroupDialog] = useState<boolean>(false);

  const appGroupContextProviderValue = useMemo(
    () => ({
      appGroup,
      setAppGroup,
    }),
    [appGroup],
  );

  const handleAppGroupDialog = useCallback((create: boolean, appGroupsReceived: IAppleAppGroup) => {
    if (create) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
    setAppGroup(appGroupsReceived);
    setOpenAppGroupDialog((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(getAppleAppGroups());
    if (
      appleEnrollment.appleEnrollment.appleId === '' ||
      appleEnrollment.appleEnrollment.apnsTopic === ''
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
  }, []);

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title='Application Groups' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={() => handleAppGroupDialog(true, appGroup)}>
            <AddIcon /> Create App Group
          </Button>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        {appGroups.appleAppGroups.length > 0 &&
          appGroups.appleAppGroups.map((appGroup: IAppleAppGroup) => (
            <Grid item key={appGroup.name} xs={12} sm={3} paddingBottom={2}>
              <AppGroupCard appGroup={appGroup} handleAppGroupDialog={handleAppGroupDialog} />
            </Grid>
          ))}
      </Grid>

      <AppGroupContext.Provider value={appGroupContextProviderValue}>
        <AppGroupDialog
          isCreate={isCreate}
          openAppGroupDialog={openappGroupDialog}
          handleAppGroupDialog={handleAppGroupDialog}
        />
      </AppGroupContext.Provider>
      {(appGroups.message.error || appGroups.message.success) && (
        <ErrorSuccessSnackbar
          opened={appGroups.message.error || appGroups.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appGroups.message.errorMessage}
          successMessage={appGroups.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleAppGroups;
