export interface ISelectItem {
  _id: string;
  value1: string;
  value2: string;
}
export interface IAppleInvitationPayload {
  groupId?: string;
  userId: string;
  ownership: string;
  sendEmail: boolean;
}
export interface IAndroidInvitationPayload extends IAppleInvitationPayload {
  enterpriseId: string;
  userEmail: string;
  userName: string;
  policy: string;
}

export type EnrollmentButtonLabels = {
  [key: string]: string;
};

export const enrollmentButtonLabels: EnrollmentButtonLabels = {
  'send-email': 'Send Enrollment Email',
  'show-details': 'Show Enrollment Details',
};

export interface IEnrollmentDetails {
  qrCode: string;
  enrollmentToken: string;
  steps: string[];
}

export const enrollmentDetailsInit: IEnrollmentDetails = {
  qrCode: '',
  enrollmentToken: '',
  steps: [],
};
