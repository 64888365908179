import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../components/CustomTable';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import {
  createUser,
  deleteUser,
  getUsers,
  updateMessage,
} from '../../redux/slices/users/usersSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { IUser, messageInit, userInit } from '../../utils/common-constants';
import CustomHeader from '../../components/CustomHeader';
import DeleteDialogBox from '../../components/DeleteDialogbox';

interface ISelectedUser {
  user: IUser;
  loading: boolean;
}

function Users() {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>(userInit);
  const [selectedUser, setSelectedUser] = useState<ISelectedUser | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const addUser = () => {
    if (!user.email.includes('@')) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Invalid Email',
        }),
      );
      return;
    }
    if (!user.userName || user.userName === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Username is required',
        }),
      );
      return;
    }

    const payload = {
      email: user.email,
      username: user.userName,
    };
    dispatch(createUser(payload));
    setUser(userInit);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getUsers()); // get all users
  }, []);

  const handleDialog = () => {
    setOpen((prev) => !prev);
  };

  const handleDeleteDialog = (user: IUser) => {
    setSelectedUser({ user, loading: false });
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteUser = async () => {
    if (selectedUser && selectedUser.user._id) {
      setSelectedUser({ user: selectedUser.user, loading: true });
      const deletePayload = {
        userId: selectedUser.user._id,
      };
      await dispatch(deleteUser(deletePayload));
      setDeleteOpen(false);
      setSelectedUser(null);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: '_id',
      headerName: '_id',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <ButtonGroup disableElevation>
          <Tooltip title='Delete'>
            <Button
              variant='outlined'
              size='small'
              color='error'
              onClick={() => handleDeleteDialog(params.row)}
              disabled={selectedUser?.loading && selectedUser?.user._id === params.row._id}
            >
              {selectedUser?.loading && selectedUser?.user._id === params.row._id ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <DeleteIcon />
              )}
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader title='Users' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={handleDialog}>
            <AddIcon /> Add User
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={users.users} columns={columns} />
      <Dialog fullWidth onClose={handleDialog} open={open}>
        <DialogTitle>Add user</DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            padding={1}
            spacing={1}
          >
            <Grid item sm={3}>
              <Typography>Email</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='email'
                name='email'
                placeholder='Email'
                value={user.email}
                onChange={onChange}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>User Name</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='userName'
                name='userName'
                placeholder='UserName'
                value={user.userName}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center' }}>
            <Button variant='contained' onClick={addUser}>
              Add User
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <DeleteDialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        onDelete={handleDeleteUser}
        item='user'
      />

      {(users.message.error || users.message.success) && (
        <ErrorSuccessSnackbar
          opened={users.message.error || users.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={users.message.errorMessage}
          successMessage={users.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default Users;
