import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PolicyContext } from '../../../../..';
import { updateMessage } from '../../../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch, RootState } from '../../../../../../../redux/store';
import { messageInit } from '../../../../../../../utils/common-constants';
import { IAndroidApp } from '../../../../../definitions';

// eslint-disable-next-line
declare const gapi: any;

interface IProps {
  app: IAndroidApp;
  open: boolean;
  onClose: () => void;
}

function ManageAppConfigComponent(props: IProps) {
  const { app, open, onClose } = props;
  const { setPolicy } = useContext(PolicyContext);
  const webtoken = useSelector((state: RootState) => state.androidPolicy.webtoken);

  const dispatch = useDispatch<AppDispatch>();
  const { appname } = app;
  const templateId = app.appManagedConfiguration?.appConfigTemplate;

  const loadManagedAppIframe = () => {
    const url =
      templateId !== ''
        ? `https://play.google.com/managed/mcm?token=${webtoken}&packageName=${appname}&mcmId=${templateId}&canDelete=TRUE`
        : `https://play.google.com/managed/mcm?token=${webtoken}&packageName=${appname}`;

    gapi.load('gapi.iframes', () => {
      const options = {
        url,
        where: document.getElementById('google-iframe-container'),
        attributes: { style: 'width: 100%; height: 100%', scrolling: 'yes' },
      };
      const iframe = gapi.iframes.getContext().openChild(options);
      iframe.register(
        'onconfigupdated',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (event: any) => {
          setPolicy((prev) => {
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            let allApps = prev.appCatalog.applications as any;
            allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

            const selectedAppIndex = allApps.findIndex(
              (item: IAndroidApp) => item.appname === appname,
            );
            if (selectedAppIndex !== -1) {
              allApps[selectedAppIndex].appManagedConfiguration.appConfigTemplate = event.mcmId;
            }
            return {
              ...prev,
              appCatalog: {
                ...prev.appCatalog,
                applications: allApps,
              },
            };
          });
          dispatch(
            updateMessage({
              ...messageInit,
              success: true,
              successMessage: `${app.appMeta.title} configured successfully. Please save the policy.`,
            }),
          );
          setTimeout(() => onClose(), 2000);
        },
        gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      );
      iframe.register(
        'onconfigdeleted',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        () => {
          setPolicy((prev) => {
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            let allApps = prev.appCatalog.applications as any;
            allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

            const selectedAppIndex = allApps.findIndex(
              (item: IAndroidApp) => item.appname === appname,
            );
            if (selectedAppIndex !== -1) {
              allApps[selectedAppIndex].appManagedConfiguration.appConfigTemplate = '';
            }
            return {
              ...prev,
              appCatalog: {
                ...prev.appCatalog,
                applications: allApps,
              },
            };
          });
          dispatch(
            updateMessage({
              ...messageInit,
              success: true,
              successMessage: `${app.appMeta.title} deleted successfully. Please save the policy.`,
            }),
          );
          setTimeout(() => onClose(), 2000);
        },
        gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      );
    });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        loadManagedAppIframe();
      }, 500);
    }
  }, [open]);
  return (
    <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open}>
      <DialogTitle>Manage App Configuration</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div id='google-iframe-container' style={{ height: '75vh' }} />
      </DialogContent>
    </Dialog>
  );
}

export default ManageAppConfigComponent;
