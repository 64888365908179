import { Popover, Box, Typography, List, ListSubheader, ListItem } from '@mui/material';
import {
  ANDROID_BUG_REPORT_SECTION_DESC,
  ANDROID_DEVELOPER_MODE_ENABLE_DESC,
  ANDROID_DEVELOPER_MODE_ENABLE_STEPS,
  ANDROID_BUG_REPORT_STEPS_DESC,
  ANDROID_BUG_REPORT_STEPS,
  ANDROID_BUG_REPORT_SECTION_NOTE,
} from './constants';

interface IProps {
  openBugReportSteps: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

function BugReportSteps(props: IProps) {
  const { openBugReportSteps, anchorEl, handleClose } = props;
  return (
    <Popover
      open={openBugReportSteps}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorReference='anchorEl'
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box role='presentation' sx={{ width: '40vw' }}>
        <div>
          <div style={{ background: '#d9d9d9', padding: '10px' }}>
            <Typography variant='h5' align='center'>
              How to take a Bug report from mobile?
            </Typography>
          </div>
          <div style={{ padding: '20px' }}>
            <Typography variant='subtitle1'>{ANDROID_BUG_REPORT_SECTION_DESC}</Typography>

            <List>
              <ListSubheader component='div'>{ANDROID_DEVELOPER_MODE_ENABLE_DESC} </ListSubheader>
              {ANDROID_DEVELOPER_MODE_ENABLE_STEPS.map((step, index) => (
                <ListItem key={step}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: `${index + 1}. ${step}` }} />
                </ListItem>
              ))}
            </List>

            <List>
              <ListSubheader component='div'>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: ANDROID_BUG_REPORT_STEPS_DESC }} />
              </ListSubheader>
              {ANDROID_BUG_REPORT_STEPS.map((step, index) => (
                <ListItem key={step}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: `${index + 1}. ${step}` }} />
                </ListItem>
              ))}
            </List>
            <Typography variant='body2' paddingTop='15px'>
              {ANDROID_BUG_REPORT_SECTION_NOTE}
            </Typography>
          </div>
        </div>
      </Box>
    </Popover>
  );
}

export default BugReportSteps;
