import { Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import ComplianceRule from './ComplianceRule';

function Security() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const {
    developerSettingsScope,
    factoryResetSetting,
    modifyAccountsDisabled,
  } = policy.advanceControls.securityPermissions;

  const handleChangeSecurityPermissions = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      advanceControls: {
        ...prev.advanceControls,
        securityPermissions: {
          ...prev.advanceControls?.securityPermissions,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Developer Settings',
      name: 'developerSettingsScope',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disabled' },
      ],
      value: developerSettingsScope,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
    {
      property: 'Factory Reset',
      name: 'factoryResetSetting',
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: factoryResetSetting,
      androidVersion: '5.1+',
      profileType: `${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
    {
      property: 'Add New Accounts',
      name: 'modifyAccountsDisabled',
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: modifyAccountsDisabled,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
  ];

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        {propertyList.map((item) => (
          <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
        ))}
      </Grid>
      <Grid item sm={6}>
        <ComplianceRule />
      </Grid>
    </Grid>
  );
}
export default Security;
