import { Box, Grid, List, ListItem } from '@mui/material';
import React from 'react';

interface IProps {
  deviceType: 'android' | 'apple';
  qrCode: string;
  enrollmentToken: string;
  steps: string[];
}
function EnrollmentSteps(props: IProps) {
  const { deviceType, qrCode, enrollmentToken, steps } = props;
  const tokenStep = `If the QR code does not work, then please use the enrollment token <strong>${enrollmentToken}</strong>`;

  return (
    <Box sx={{ justifyContent: 'center !important', alignItems: 'center !important' }}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        paddingTop={2}
        paddingBottom={2}
        sx={{ backgroundColor: '#e4e4e4' }}
      >
        <Grid item sm={5}>
          <List>
            {steps.map((step, index) => (
              <ListItem>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: `${index + 1}. ${step}` }} />
              </ListItem>
            ))}
            {deviceType === 'android' ? (
              <ListItem key='token'>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: `${steps.length + 1}. ${tokenStep}` }} />
              </ListItem>
            ) : null}
          </List>
        </Grid>
        <Grid item sm={5} display='flex' justifyContent='center'>
          <img src={qrCode} alt='QR Code' width={200} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(EnrollmentSteps);
