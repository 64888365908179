export const GLOBAL_MSG = {};

export const ANDROID_ENROLLMENT_MSG = {
  PAGE_DISABLED_AE: 'Please register for Android Enterprise to continue...',
};

export const APPLE_ENROLLMENT_MSG = {
  PAGE_DISABLED_APNS: 'Please configure the Apple APNS Certificate to continue...',
};

export const ANDROID_DEVICE_MSG = {};

export const APPLE_DEVICE_MSG = {};

export const ANDROID_POLICY_MSG = {
  DEFAULT_POLICY_DELETION_NOT_ALLOWED: 'Default policy can not be deleted',
};

export const APPLE_PROFILE_MSG = {
  PROFILE_NAME_REQUIRED: 'Profile name is required',
};

export const APPLE_APPLICATION_MSG = {};
