import React, { useContext } from 'react';
import { Checkbox, CircularProgress, Grid, Typography } from '@mui/material';
import CustomAccordion from '../../../components/CustomAccordion';
import { IAppleApp } from '../definitions';
import { AppGroupContext } from '..';

interface IProps {
  app: IAppleApp;
}
export default function AppCard(props: IProps) {
  const { app } = props;

  const { setAppGroup } = useContext(AppGroupContext);

  const handleAppSelection = (app: IAppleApp, e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e?.target.checked;
    setAppGroup((prev) => {
      const newApplicationsState = prev.apps.map((obj) => {
        if (obj.id === app.id) {
          return { ...obj, checked: selected };
        }
        return obj;
      });

      return {
        ...prev,
        apps: newApplicationsState,
      };
    });
  };

  return (
    <CustomAccordion
      key={app.bundleId}
      ariaControls={app.bundleId}
      summary={
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item sm={2}>
            <Checkbox checked={app.checked} onChange={(e) => handleAppSelection(app, e)} />
          </Grid>
          <Grid item sm={3}>
            {app.iconUrl ? (
              <img src={app.iconUrl} alt={app.trackName} height={50} width={50} />
            ) : (
              <CircularProgress size={35} />
            )}
          </Grid>
          <Grid item sm={6}>
            <Typography variant='body1'>
              {app.trackName ? app.trackName : 'App Name Loading...'}
            </Typography>
          </Grid>
        </Grid>
      }
      details={
        <Grid container direction='row' padding={0}>
          <Grid item sm={5} margin={0} />
          <Grid item sm={6} marginLeft={1} paddingLeft={1}>
            <Typography variant='subtitle2'>
              <strong>Seller</strong>
            </Typography>
            <Typography variant='body2'>{app.sellerName ? app.sellerName : ''}</Typography>
          </Grid>
        </Grid>
      }
    />
  );
}
