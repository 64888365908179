import { SelectChangeEvent } from '@mui/material';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import EnrollmentDialog from '../../components/Enrollments/EnrollmentDialog';
import { ISelectItem } from '../../components/Enrollments/definitions';
import { sendEnrollmentInvitation } from '../../redux/slices/appleDevice/appleDeviceSlice';
import { RootState } from '../../redux/store';
import { IAppleDeviceGroup, groupInit } from '../AppleDeviceGroups';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}
function DeviceEnrollment(props: IProps) {
  const { open, handleDialog } = props;

  const appleDeviceGroups = useSelector(
    (state: RootState) => state.appleDeviceGroup.appleDeviceGroups,
  );

  const [group, setGroup] = useState<IAppleDeviceGroup>(groupInit);

  const selectGroup = (e: SelectChangeEvent<ISelectItem>) => {
    const groupDetail = e.target.value as ISelectItem;
    setGroup({
      _id: groupDetail._id,
      name: groupDetail.value1,
      description: groupDetail.value2,
    });
  };

  const groupList: ISelectItem[] = [];
  appleDeviceGroups.map((group) =>
    groupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.description,
    }),
  );

  return (
    <EnrollmentDialog
      deviceType='apple'
      title='Enroll Apple (iOS / Mac) Device'
      open={open}
      handleDialog={handleDialog}
      groupList={groupList}
      sendEnrollmentInvitation={sendEnrollmentInvitation}
      selectGroup={selectGroup}
      selectedGroup={{
        _id: group._id,
        value1: group.name,
        value2: group.description,
      }}
    />
  );
}

export default DeviceEnrollment;
