import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import {
  createAppleDeviceGroup,
  deleteAppleDeviceGroup,
  getAppleDeviceGroups,
  updateMessage,
} from '../../redux/slices/appleDeviceGroup/appleDeviceGroupSlice';
import { getAppleEnrollmentDetails } from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import { IAppleProfile } from '../AppleProfiles/definitions';
import AppGroupsDialog from './AppGroupsDialog';
import ProfilesDialog from './ProfilesDialog';
import GROUP_NAME_REQUIRED_ERROR_MSG from './constants';
import DeleteDialogBox from '../../components/DeleteDialogbox';

export interface IAppleDeviceGroup {
  _id: string;
  name: string;
  description: string;
  AppGroups?: [];
  Profiles?: IAppleProfile[];
}
export const groupInit: IAppleDeviceGroup = {
  _id: '',
  name: '',
  description: '',
  AppGroups: [],
  Profiles: [],
};
export interface ISelectedDevicegroup {
  group: IAppleDeviceGroup;
  loading: boolean;
}

function AppleDeviceGroup() {
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const appleDeviceGroup = useSelector((state: RootState) => state.appleDeviceGroup);

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<IAppleDeviceGroup>(groupInit);
  const [selectedRow, setSelectedRow] = useState<IAppleDeviceGroup>(groupInit);
  const [openDeviceGroupProfilesDialog, setOpenDeviceGroupProfilesDialog] = useState<boolean>(
    false,
  );
  const [openDeviceGroupAppGroupsDialog, setOpenDeviceGroupAppGroupsDialog] = useState<boolean>(
    false,
  );
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<ISelectedDevicegroup | null>(null);
  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setGroup((prev) => ({
      ...prev,
      [name]: name === 'name' ? value.trim() : value,
    }));
  };

  const addAppleDeviceGroup = () => {
    if (group.name === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: GROUP_NAME_REQUIRED_ERROR_MSG,
        }),
      );
      return;
    }
    const payload: IAppleDeviceGroup = {
      ...group,
    };

    dispatch(createAppleDeviceGroup(payload));
    setOpen(false);
    setGroup(groupInit);
  };

  useEffect(() => {
    dispatch(getAppleDeviceGroups()); // get all groups
    if (
      appleEnrollment.appleEnrollment.appleId === '' ||
      appleEnrollment.appleEnrollment.apnsTopic === ''
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
  }, []);

  const handleDialog = () => {
    setOpen((prev) => !prev);
    setIsCreate(true);
    setGroup(groupInit);
  };
  const handleDeleteDialog = (group: IAppleDeviceGroup) => {
    setSelectedDeviceGroup({ group, loading: false });
    setDeleteOpen(true);
  };

  const handleDeleteDeviceGroup = async () => {
    if (selectedDeviceGroup?.group?._id) {
      setSelectedDeviceGroup({ group: selectedDeviceGroup.group, loading: true });
      const deletePayload = { id: selectedDeviceGroup.group._id };
      await dispatch(deleteAppleDeviceGroup(deletePayload));
      setDeleteOpen(false);
      setSelectedDeviceGroup(null);
    }
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedDeviceGroup(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
    },
    {
      field: 'ProfilesCount',
      headerName: 'Profiles',
      width: 200,
      renderCell: (params) => (
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setSelectedRow(params.row);
            setOpenDeviceGroupProfilesDialog(true);
          }}
        >
          {params.row.ProfilesCount}
        </Button>
      ),
    },
    {
      field: 'AppGroupsCount',
      headerName: 'App Groups',
      width: 200,
      renderCell: (params) => (
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setSelectedRow(params.row);
            setOpenDeviceGroupAppGroupsDialog(true);
          }}
        >
          {params.row.AppGroupsCount}
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <ButtonGroup disableElevation variant='contained'>
          <Tooltip title='Delete'>
            <Button
              variant='outlined'
              size='small'
              onClick={() => handleDeleteDialog(params.row)}
              disabled={
                selectedDeviceGroup?.loading && selectedDeviceGroup?.group._id === params.row._id
              }
            >
              {selectedDeviceGroup?.loading && selectedDeviceGroup?.group._id === params.row._id ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <DeleteIcon color='error' />
              )}
            </Button>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  const rows = appleDeviceGroup.appleDeviceGroups.map((group) => ({
    ...group,
    ProfilesCount: group.Profiles?.length,
    AppGroupsCount: group.AppGroups?.length,
  }));

  return (
    <MainLayout>
      <CustomHeader title='Device Groups' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={handleDialog}>
            <AddIcon /> Add Group
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={rows} columns={columns} />
      <Dialog fullWidth onClose={handleDialog} open={open}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            padding={1}
            spacing={1}
          >
            <Grid item sm={3}>
              <Typography>Group name</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='name'
                name='name'
                placeholder='Name'
                value={group.name}
                disabled={!isCreate}
                onChange={onChange}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item sm={9}>
              <TextField
                required
                fullWidth
                size='small'
                id='description'
                name='description'
                placeholder='Description'
                value={group.description}
                disabled={!isCreate}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center' }}>
            <Button variant='contained' onClick={addAppleDeviceGroup}>
              Add Group
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <DeleteDialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        onDelete={handleDeleteDeviceGroup}
        item='device group'
      />

      <ProfilesDialog
        open={openDeviceGroupProfilesDialog}
        setOpen={setOpenDeviceGroupProfilesDialog}
        deviceGroup={selectedRow}
      />
      <AppGroupsDialog
        open={openDeviceGroupAppGroupsDialog}
        setOpen={setOpenDeviceGroupAppGroupsDialog}
        deviceGroup={selectedRow}
      />

      {(appleDeviceGroup.message.error || appleDeviceGroup.message.success) && (
        <ErrorSuccessSnackbar
          opened={appleDeviceGroup.message.error || appleDeviceGroup.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appleDeviceGroup.message.errorMessage}
          successMessage={appleDeviceGroup.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleDeviceGroup;
