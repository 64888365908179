import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axiosInstance from '../../../utils/axiosInstance';
import { CUSTOMER_AUTH_ROUTE } from '../../../utils/routes-defs';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
function jwtExpToJSDate(jwtExp: number) {
  const expInSeconds = jwtExp;
  const expInMilliseconds = expInSeconds * 1000;
  const expirationDate = new Date(expInMilliseconds);
  return expirationDate;
}

export interface IAuth {
  token: string;
  customerEmail: string;
  loading: boolean;
  success: string;
  error: string;
}

const initialState: IAuth = {
  token: '',
  customerEmail: '',
  loading: false,
  success: '',
  error: '',
};
interface ILoginPayload {
  email: string;
  password: string;
}

export const customerLogin = createAsyncThunk(
  'auth/login',
  async (payload: ILoginPayload, thunkApi) => {
    try {
      const response = await axiosInstance().post(CUSTOMER_AUTH_ROUTE, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkApi.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      Cookies.remove('token');
      state.token = '';
      state.customerEmail = '';
      window.location.href = '/login';
    },
    isUserLoggedin: (state) => {
      const token = Cookies.get('token');
      if (token) {
        state.token = token;
        state.customerEmail = parseJwt(token).customerEmail;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customerLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(customerLogin.fulfilled, (state, action) => {
      const { token } = action.payload;
      const decodedToken = parseJwt(token);
      state.loading = false;
      state.customerEmail = decodedToken.customerEmail;
      state.token = token;
      Cookies.set('token', token, {
        expires: jwtExpToJSDate(decodedToken.exp),
      });
    });
    builder.addCase(customerLogin.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload as string;
      } else {
        state.error = action.error.message as string;
      }
    });
  },
});

export const { logout, isUserLoggedin } = authSlice.actions;
export default authSlice;
