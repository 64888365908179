import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteAndroidPolicy,
  updateMessage,
} from '../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch } from '../../../../redux/store';
import { messageInit } from '../../../../utils/common-constants';
import { IAndroidPolicy } from '../../definitions';
import { ANDROID_POLICY_MSG } from '../../../../messages';
import DeleteDialogBox from '../../../../components/DeleteDialogbox';

interface IProps {
  policy: IAndroidPolicy;
  openDeleteDialog: boolean;
  handleDeleteDialog: (isOpen: boolean) => void;
}

function DeletePolicyDialog(props: IProps) {
  const { policy, openDeleteDialog, handleDeleteDialog } = props;
  const dispatch = useDispatch<AppDispatch>();

  const handleDeletePolicy = () => {
    if (policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: ANDROID_POLICY_MSG.DEFAULT_POLICY_DELETION_NOT_ALLOWED,
        }),
      );
      handleDeleteDialog(false);
      return;
    }
    dispatch(deleteAndroidPolicy({ androidPolicyId: policy.policyId as string }));
    handleDeleteDialog(false);
  };

  return (
    <DeleteDialogBox
      open={openDeleteDialog}
      onClose={() => handleDeleteDialog(false)}
      onDelete={handleDeletePolicy}
      item='policy'
    />
  );
}
export default React.memo(DeletePolicyDialog);
