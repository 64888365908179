import { Alert, Snackbar } from '@mui/material';

interface IProps {
  opened: boolean;
  onClose: () => void;
  errorMessage: string;
  successMessage: string;
}
function ErrorSuccessSnackbar(props: IProps) {
  const { errorMessage, successMessage, opened, onClose } = props;
  return (
    <Snackbar
      autoHideDuration={errorMessage !== '' ? null : 3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={opened}
      onClose={onClose}
    >
      <Alert
        severity={errorMessage !== '' ? 'error' : 'success'}
        variant='filled'
        onClose={onClose}
        sx={{ width: '100%' }}
      >
        {errorMessage !== '' ? errorMessage : successMessage}
      </Alert>
    </Snackbar>
  );
}

export default ErrorSuccessSnackbar;
