import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppleDeviceGroup } from '.';
import { getAppleAppGroups } from '../../redux/slices/appleAppGroup/appleAppGroupSlice';
import {
  attachAppGroupToDeviceGroup,
  detachAppGroupFromDeviceGroup,
  updateMessage,
} from '../../redux/slices/appleDeviceGroup/appleDeviceGroupSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import PropertyAndSelectInput from '../AndroidPolicies/components/PropertyAndSelectInput';
import AppGroupCard from '../AppleAppGroups/AppGroupCard';
import { APPGROUP_REQUIRED_ERROR_MSG } from './constants';
import { IAppleAppGroup } from '../AppleAppGroups/definitions';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deviceGroup: IAppleDeviceGroup;
}
function AppGroupsDialog(props: IProps) {
  const { open, setOpen, deviceGroup } = props;
  const dispatch = useDispatch<AppDispatch>();
  const appleAppGroups = useSelector((state: RootState) => state.appleAppGroup.appleAppGroups);
  const [selectedAppGroupId, setSelectedAppGroupId] = useState<string>('');

  useEffect(() => {
    dispatch(getAppleAppGroups());
  }, []);

  const handleAttach = (attach: boolean) => {
    if (!selectedAppGroupId) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: APPGROUP_REQUIRED_ERROR_MSG,
        }),
      );
      return;
    }

    const payload = {
      appGroupId: selectedAppGroupId,
      deviceGroupId: deviceGroup._id,
    };
    dispatch(
      attach ? attachAppGroupToDeviceGroup(payload) : detachAppGroupFromDeviceGroup(payload),
    );
  };

  const menuItems = appleAppGroups.map((item) => ({ view: item.name, value: item.id! }));

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      PaperProps={{
        sx: {
          height: '80vh',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{deviceGroup.name} | Attach or detach an App Group</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          direction='row'
          justifyContent='flex-between'
          alignItems='center'
          paddingBottom={2}
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <PropertyAndSelectInput
              data={{
                property: 'Attach an app group',
                name: 'appGroup',
                menuItems,
                handleChange: (e: SelectChangeEvent) => setSelectedAppGroupId(e.target.value),
                selectFieldLabel: 'App Group',
                direction: 'row',
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ pb: 2 }}>
            <Button variant='contained' size='medium' onClick={() => handleAttach(true)}>
              Attach
            </Button>
            <Button
              variant='contained'
              sx={{ ml: 1 }}
              size='medium'
              color='error'
              onClick={() => handleAttach(false)}
            >
              Detach
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='flex-between'
          alignItems='center'
          paddingBottom={2}
          spacing={2}
        >
          {deviceGroup?.AppGroups &&
            deviceGroup?.AppGroups.length > 0 &&
            deviceGroup?.AppGroups.map((item: IAppleAppGroup) => (
              <Grid item key={item.name} xs={12} sm={3} sx={{ pb: 2 }}>
                <AppGroupCard appGroup={item} handleAppGroupDialog={() => {}} noEdit />
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AppGroupsDialog;
