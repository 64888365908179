import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Icloud() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_device_backup_icloud,
    allow_managed_app_icloud_sync,
    allow_icloud_document_sync,
    allow_shared_stream_icloud_sync,
    allow_keychain_icloud_sync,
    allow_icloud_photo_library,
    allow_ent_book_backup_icloud,
    allow_ent_book_metadata_icloud_sync,
    allow_icloud_private_relay,
  } = profile.restrictions.icloud;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        icloud: {
          ...prev.restrictions.icloud,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Device backup',
      name: 'allow_device_backup_icloud',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_device_backup_icloud,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Sync data and documents from managed apps',
      name: 'allow_managed_app_icloud_sync',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_managed_app_icloud_sync,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
        },
      },
    },
    {
      property: 'Sync device data and documents',
      name: 'allow_icloud_document_sync',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_icloud_document_sync,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
          isSupervised: true,
          supervisedOver: '13',
        },
        macos: {
          version: '10.11',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Sync shared photo stream',
      name: 'allow_shared_stream_icloud_sync',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_shared_stream_icloud_sync,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Sync keychain',
      name: 'allow_keychain_icloud_sync',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_keychain_icloud_sync,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
          isSupervised: true,
        },
        macos: {
          version: '10.12',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Sync iCloud photo library',
      name: 'allow_icloud_photo_library',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_icloud_photo_library,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
        macos: {
          version: '10.12',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Enterprise books backup',
      name: 'allow_ent_book_backup_icloud',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_ent_book_backup_icloud,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Enterprise books, notes and highlights sync',
      name: 'allow_ent_book_metadata_icloud_sync',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_ent_book_metadata_icloud_sync,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
          isSupervised: true,
        },
      },
    },
    {
      property: 'iCloud private relay',
      name: 'allow_icloud_private_relay',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_icloud_private_relay,
      handleChange,
      supportedOS: {
        ios: {
          version: '15',
          isSupervised: true,
        },
        macos: {
          version: '12',
          isSupervised: true,
        },
      },
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Icloud;
