import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useDispatch } from 'react-redux';
import { AppGroupContext } from '..';
import { updateMessage } from '../../../redux/slices/appleAppGroup/appleAppGroupSlice';
import { AppDispatch } from '../../../redux/store';
import { messageInit } from '../../../utils/common-constants';
import { IAPIParams, searchAppleApps } from '../api';
import { IAppleApp } from '../definitions';

interface ISearchedAppState {
  apps: IAppleApp[];
  loading: boolean;
}

function AppSearch() {
  const dispatch = useDispatch<AppDispatch>();

  const { setAppGroup } = useContext(AppGroupContext);

  const searchApiParamsInit: IAPIParams = {
    term: '',
    country: 'US',
  };

  const searchedAppStateInit: ISearchedAppState = {
    apps: [],
    loading: false,
  };

  const [searchApiParams, setSearchApiParams] = useState<IAPIParams>(searchApiParamsInit);

  const handleCountryChange = (value: string) => {
    setSearchApiParams((prev) => ({
      ...prev,
      country: value,
    }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchApiParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [searchedApps, setSearchedApps] = useState<ISearchedAppState>(searchedAppStateInit);

  const handleSearch = () => {
    if (searchApiParams.term?.toString().trim() === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please enter something to search',
        }),
      );
      return;
    }
    setSearchedApps({ apps: [], loading: true });

    searchAppleApps(
      `country=${searchApiParams.country}&term=${searchApiParams.term}&entity=software`,
    )
      .then((apps) => {
        setSearchedApps({ apps, loading: false });
      })
      .catch((error) => {
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: error.response.data.errors[0].message,
          }),
        );
      });
  };

  const handleSearchedAppSelectionChange = (app: IAppleApp) => {
    app.checked = true;
    setAppGroup((prev) => {
      let appsList = prev.apps;
      const existingApp = prev.apps.some((obj) => obj.id === app.id);
      if (!existingApp) {
        appsList = [...prev.apps, app];
      }

      return {
        ...prev,
        apps: [...appsList],
      };
    });
  };

  return (
    <Card sx={{ height: '100%', overflow: 'visible' }}>
      <Stack direction='row' justifyContent='space-between' padding={2}>
        <TextField
          fullWidth
          type='text'
          size='small'
          id='term'
          name='term'
          placeholder='Enter app name to search'
          variant='outlined'
          onChange={handleInputChange}
        />
        <div
          style={{ maxWidth: '30%', marginLeft: 5, marginRight: 5, maxHeight: 35, zIndex: 9999 }}
        >
          <ReactFlagsSelect
            selected={searchApiParams.country.toString()}
            onSelect={handleCountryChange}
          />
        </div>
        <Button variant='contained' size='small' onClick={handleSearch}>
          Search
        </Button>
      </Stack>

      <Grid direction='column' container paddingBottom={2}>
        {searchedApps.apps.length > 0 ? (
          searchedApps.apps.map((app) => (
            <Grid key={app.bundleId} item sm={12} marginBottom={1}>
              <Card>
                <CardContent>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item sm={3}>
                      <Button
                        variant='outlined'
                        onClick={() => handleSearchedAppSelectionChange(app)}
                      >
                        Add
                      </Button>
                    </Grid>
                    <Grid item sm={3}>
                      {app.iconUrl ? (
                        <img src={app.iconUrl} alt={app.trackName} height={50} width={50} />
                      ) : (
                        <CircularProgress size={35} />
                      )}
                    </Grid>
                    <Grid item sm={5}>
                      <Typography variant='body1'>
                        {app.trackName ? app.trackName : 'App Name Loading...'}
                      </Typography>
                      <Typography variant='body2'>
                        <strong>Seller: </strong>
                        {app.sellerName ? app.sellerName : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            {searchedApps.loading ? (
              <CircularProgress />
            ) : (
              <Typography>Searched apps will appear here</Typography>
            )}
          </div>
        )}
      </Grid>
    </Card>
  );
}

export default AppSearch;
