import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteAppleAppGroup } from '../../../redux/slices/appleAppGroup/appleAppGroupSlice';
import { IAppleAppGroup } from '../definitions';
import { AppDispatch } from '../../../redux/store';
import DeleteDialogBox from '../../../components/DeleteDialogbox';

interface IProps {
  appGroup: IAppleAppGroup;
  openDeleteDialog: boolean;
  handleDeleteDialog: (isOpen: boolean) => void;
}

function DeleteAppGroupDialog(props: IProps) {
  const { appGroup, openDeleteDialog, handleDeleteDialog } = props;
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteAppGroup = () => {
    dispatch(deleteAppleAppGroup({ appGroupId: appGroup.id as string }));
    handleDeleteDialog(false);
  };

  return (
    <DeleteDialogBox
      open={openDeleteDialog}
      onClose={() => handleDeleteDialog(false)}
      onDelete={handleDeleteAppGroup}
      item='app group'
    />
  );
}

export default React.memo(DeleteAppGroupDialog);
