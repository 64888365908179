export const PERSONAL_PROFILE_DESCRIPTION =
  'Selecting this option enables the Work profile setup on personal devices';

export const CORPORATE_PROFILE_DESCRIPTION = 'Select this option if the device is company-owned';

export const ENROLLMENT_EMAIL_OPTION_DESCRIPTION =
  'Enrollment steps will be sent over email to the selected user(Recommended for personal devices)';

export const ENROLLMENT_STEPS_DISPLAY_DESCRIPTION =
  'Enrollment steps will be shown here(Recommended for corporate devices)';

export const CHOICE_SEND_EMAIL = 'send-email';
export const CHOICE_SHOW_DETAILS = 'show-details';

export const DEVICE_PERSONAL = 'personal';
export const DEVICE_CORPORATE = 'corporate';
