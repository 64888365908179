import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Security() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_open_from_managed_to_unmanaged,
    allow_open_from_unmanaged_to_managed,
    require_managed_pasteboard,
    force_encrypted_backup,
    allow_erase_content_settings,
    allow_configure_screentime_restriction,
    allow_passbook_when_locked,
    allow_biometric_unlock,
    allow_biometric_modification,
  } = profile.restrictions.security;

  const valueToFrontend = () => {
    if (allow_open_from_managed_to_unmanaged && allow_open_from_unmanaged_to_managed)
      return 'allow';
    if (allow_open_from_managed_to_unmanaged && !allow_open_from_unmanaged_to_managed)
      return 'unmanaged_to_managed';
    if (allow_open_from_unmanaged_to_managed && !allow_open_from_managed_to_unmanaged)
      return 'managed_to_unmanaged';
    return 'deny';
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'allow_open_from_managed_to_unmanaged_combined') {
      const values = {
        allow_open_from_managed_to_unmanaged: true,
        allow_open_from_unmanaged_to_managed: true,
      };
      if (value === 'deny') {
        values.allow_open_from_managed_to_unmanaged = false;
        values.allow_open_from_unmanaged_to_managed = false;
      } else if (value === 'unmanaged_to_managed') {
        values.allow_open_from_unmanaged_to_managed = false;
        values.allow_open_from_managed_to_unmanaged = true;
      } else if (value === 'managed_to_unmanaged') {
        values.allow_open_from_managed_to_unmanaged = false;
        values.allow_open_from_unmanaged_to_managed = true;
      }

      setProfile((prev) => ({
        ...prev,
        restrictions: {
          ...prev.restrictions,
          security: {
            ...prev.restrictions.security,
            ...values,
          },
        },
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        restrictions: {
          ...prev.restrictions,
          security: {
            ...prev.restrictions.security,
            [name]: value,
          },
        },
      }));
    }
  };

  const propertyList = [
    {
      property: 'Share data between managed and unmanaged apps',
      name: 'allow_open_from_managed_to_unmanaged_combined',
      menuItems: [
        {
          view: 'Allow both ways',
          value: 'allow',
        },
        {
          view: 'Restrict from unmanaged to managed',
          value: 'unmanaged_to_managed',
        },
        {
          view: 'Restrict from managed to unmanaged',
          value: 'managed_to_unmanaged',
        },
        {
          view: 'Restrict both ways',
          value: 'deny',
        },
      ],
      value: valueToFrontend(),
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
        },
      },
    },
    {
      property: 'Copy-Paste ',
      name: 'require_managed_pasteboard',
      menuItems: [
        {
          view: 'Conforms to above',
          value: true,
        },
        {
          view: 'No',
          value: false,
        },
      ],
      value: require_managed_pasteboard,
      handleChange,
      supportedOS: {
        ios: {
          version: '15',
        },
      },
    },
    {
      property: 'Force encrypted backup',
      name: 'force_encrypted_backup',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_encrypted_backup,
      handleChange,
      supportedOS: {
        ios: {
          version: '14',
        },
      },
    },
    {
      property: 'Wipe device by erasing all content and settings',
      name: 'allow_erase_content_settings',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_erase_content_settings,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
          isSupervised: true,
        },
        macos: {
          version: '12',
        },
      },
    },
    {
      property: 'Configure Screen Time/Restrictions on device',
      name: 'allow_configure_screentime_restriction',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_configure_screentime_restriction,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Passbook when device is locked',
      name: 'allow_passbook_when_locked',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_passbook_when_locked,
      handleChange,
      supportedOS: {
        ios: {
          version: '6',
        },
      },
    },
    {
      property: 'Bio-metric methods such as TouchID and/or FaceID to unlock devices',
      name: 'allow_biometric_unlock',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_biometric_unlock,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
          isSupervised: true,
        },
        macos: {
          version: '10.12.4',
        },
      },
      children: [
        {
          property: 'Add or modify TouchID/FaceID',
          name: 'allow_biometric_modification',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_biometric_modification,
          handleChange,
          supportedOS: {
            ios: {
              version: '8.3',
              isSupervised: true,
            },
            macos: {
              version: '14',
            },
          },
        },
      ],
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Security;
