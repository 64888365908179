import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useContext, useEffect } from 'react';
import { PolicyContext } from '../../../../..';
import { IAndroidApp, IApplicationMeta, IPermissionGrant } from '../../../../../definitions';
import PermissionAccordion from './PermissionAccordion';

interface IProps {
  app: IAndroidApp;
  open: boolean;
  onClose: () => void;
  permissionsList: IApplicationMeta['permissions'];
}

const getAndroidPermissionName = (name: string): string =>
  name.replace(
    /\w\S*/g,
    (match: string) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase(),
  );
const getAndroidPermissionId = (permissionId: string): string | undefined =>
  permissionId
    ?.split('.')
    .pop()
    ?.split('_')
    .join(' ')
    .replace(
      /\w\S*/g,
      (match: string) => match.charAt(0).toUpperCase() + match.substr(1).toLowerCase(),
    );
const getAllGrants = (
  arr1: IPermissionGrant[],
  arr2: IApplicationMeta['permissions'],
): IPermissionGrant[] => {
  const set1 = new Set(arr1.map((obj) => obj.permission)); // Convert objects to strings for efficient comparison
  const newArr2 = arr2.filter((obj) => !set1.has(obj.permissionId)); // Filter out elements present in arr2
  const newArr3 = newArr2.map((item) => ({
    selected: false,
    permission: item.permissionId,
    policy: 'prompt',
  }));
  return [...arr1, ...newArr3];
};

function RuntimePermissionsComponent(props: IProps) {
  const { app, open, onClose, permissionsList } = props;
  const { policy, setPolicy } = useContext(PolicyContext);

  const handleChangePolicy = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      const allApps = policy.appCatalog.applications;
      const selectedAppIndex = allApps.findIndex(
        (item: IAndroidApp) => item.appname === app.appname,
      );

      if (selectedAppIndex !== -1) {
        allApps[selectedAppIndex].appGrants = allApps[selectedAppIndex].appGrants?.map(
          (appGrant) => {
            if (appGrant.permission === name) {
              return { ...appGrant, policy: value };
            }
            return { ...appGrant };
          },
        );
      }

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: allApps,
        },
      };
    });
  };
  const handleAllPermissions = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      let allApps = [...prev.appCatalog.applications] as any;
      allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

      const selectedAppIndex = allApps.findIndex(
        (item: IAndroidApp) => item.appname === app.appname,
      );
      if (selectedAppIndex !== -1) {
        allApps[selectedAppIndex][name] = value;
      }
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: allApps,
        },
      };
    });
  };

  const handleSelectPermission = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setPolicy((prev) => {
      let allApps = [...prev.appCatalog.applications];
      allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

      const selectedAppIndex = allApps.findIndex(
        (item: IAndroidApp) => item.appname === app.appname,
      );
      if (selectedAppIndex !== -1) {
        allApps[selectedAppIndex].appGrants = allApps[selectedAppIndex].appGrants?.map(
          (appGrant) => {
            if (appGrant.permission === name) {
              return { ...appGrant, selected: checked };
            }
            return { ...appGrant };
          },
        );
      }
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: allApps,
        },
      };
    });
  };

  useEffect(() => {
    setPolicy((prev) => {
      let allApps = [...prev.appCatalog.applications];
      allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

      const selectedAppIndex = allApps.findIndex(
        (item: IAndroidApp) => item.appname === app.appname,
      );
      if (selectedAppIndex !== -1) {
        allApps[selectedAppIndex].appGrants = getAllGrants(
          allApps[selectedAppIndex].appGrants,
          permissionsList,
        );
      }
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: allApps,
        },
      };
    });
  }, [JSON.stringify(permissionsList)]);
  return (
    <Dialog fullWidth maxWidth='md' onClose={onClose} open={open}>
      <DialogTitle>Runtime Permissions</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <PermissionAccordion
          key={`${app.appname} appPolicy`}
          appname={app.appname}
          permissionId='appPolicy'
          selected
          name='Default response for permission requests'
          value={app.appPolicy}
          description='Define how permission requests should be handled by default. If you explicitly configure any of the below permissions it will override the default permission.'
          handleChangePolicy={handleAllPermissions}
          handleSelectPermission={handleSelectPermission}
        />
        {permissionsList?.map((item) => {
          let { name } = item;
          let { description } = item;
          const { permissionId } = item;

          if (name) {
            name = getAndroidPermissionName(name);
          }
          if (!name) {
            name = getAndroidPermissionId(permissionId)!;
          }
          if (!description) {
            description = '';
          }

          let permissionValue = 'prompt';
          let selected = false;
          const permission = app.appGrants.find(
            (item: IPermissionGrant) => item.permission === permissionId,
          );
          if (permission) {
            permissionValue = permission.policy;
            selected = Boolean(permission.selected);
          }
          return (
            <PermissionAccordion
              key={`${app.appname} ${permissionId}`}
              appname={app.appname}
              permissionId={permissionId}
              selected={selected}
              name={name}
              value={permissionValue}
              description={description}
              handleChangePolicy={handleChangePolicy}
              handleSelectPermission={handleSelectPermission}
            />
          );
        })}
      </DialogContent>
    </Dialog>
  );
}

export default RuntimePermissionsComponent;
