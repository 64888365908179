export const EMPTY_STRING = '';
export const NULL = null;
export const UNDEFINED = undefined;
export const HTTP_STATUS_CODE = {
  FORBIDDEN: 403,
};
export const GLOBAL_CONST = {
  SELECT_ALLOW_DENY_TRUE_FALSE: [
    { view: 'Allow', value: true },
    { view: 'Deny', value: false },
  ],
  SELECT_YES_NO_TRUE_FALSE: [
    { view: 'Yes', value: true },
    { view: 'No', value: false },
  ],
};

export const ANDROID_ENROLLMENT_CONST = {};

export const ANDROID_DEVICE_GROUP_CONST = {};

export const ANDROID_DEVICE_CONST = {};

export const ANDROID_POLICY_CONST = {};

export const APPLE_ENROLLMENT_CONST = {
  APNS_DEFAULT_REMAINING_DAYS: 366,
  APNS_REMAINING_DAYS_THREE_MONTHS: 30,
  APNS_DEFAULT_VALIDITY: NULL,
};

export const APPLE_DEVICE_GROUP_CONST = {};

export const APPLE_DEVICE_CONST = {
  PAGE_HEADER: 'Apple Devices',

  VPN_ACTIVATE: 'Activate',
  VPN_INITIATED: 'Initiated',
  VPN_ACTIVATED: 'Activated',
  VPN_RETRY: 'Retry',

  DEVICE_ACTIVE: 'Active',
  DEVICE_INACTIVE: 'Inactive',
};

export const APPLE_PROFILE_CONST = {};

export const APPLE_APPLICATION_CONST = {};

export const APPLE_OS_TITLES = {
  ios: 'iOS',
  macos: 'macOS',
  tvos: 'tvOS',
  watchos: 'watchOS',
};
export const APPLE_OS_TYPES = {
  ios: 'ios',
  macos: 'macos',
  tvos: 'tvos',
  watchos: 'watchos',
};
