import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../../..';
import CustomSelectInput from '../../../../../../components/CustomSelectInput';
import permissionsList from './definitions';

interface IProps {
  open: boolean;
  onClose: () => void;
}
function RuntimePermissionsDialog(props: IProps) {
  const { open, onClose } = props;
  const { policy, setPolicy } = useContext(PolicyContext);

  const addNewPermission = () => {
    setPolicy((prev) => {
      const allPermissionsGrants = [...prev.appCatalog.allAppsPermissionGrant];
      allPermissionsGrants.unshift({ permission: '', policy: '' });
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          allAppsPermissionGrant: allPermissionsGrants,
        },
      };
    });
  };

  const deletePermission = (index: number) => {
    setPolicy((prev) => {
      const allPermissionsGrants = [...prev.appCatalog.allAppsPermissionGrant].filter(
        (item, idx) => idx !== index,
      );
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          allAppsPermissionGrant: allPermissionsGrants,
        },
      };
    });
  };

  const handlePermissionId = (event: SelectChangeEvent) => {
    const { name: index, value: permission } = event.target;
    const permissionFromList = permissionsList.find((item) => item.value === permission);
    setPolicy((prev) => {
      const allPermissionsGrants = prev.appCatalog.allAppsPermissionGrant.map((item) =>
        JSON.parse(JSON.stringify(item)),
      );
      allPermissionsGrants[Number(index)].permission = permissionFromList?.value;

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          allAppsPermissionGrant: allPermissionsGrants,
        },
      };
    });
  };

  const handlePermissionPolicy = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      const allPermissionsGrants = prev.appCatalog.allAppsPermissionGrant.map((item) =>
        JSON.parse(JSON.stringify(item)),
      );

      const permissionFoundIndex = allPermissionsGrants.findIndex(
        (item) => item.permission === name,
      );
      if (permissionFoundIndex >= 0) {
        allPermissionsGrants[permissionFoundIndex].policy = value;
      }

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          allAppsPermissionGrant: allPermissionsGrants,
        },
      };
    });
  };
  return (
    <Dialog fullWidth maxWidth='md' onClose={onClose} open={open}>
      <DialogTitle>Runtime Permissions</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Button variant='contained' onClick={addNewPermission} sx={{ marginBottom: 2 }}>
          Add New Permission
        </Button>
        {policy.appCatalog.allAppsPermissionGrant?.map((item, index) => (
          <Stack key={item.permission + item.policy} direction='row' spacing={2} paddingBottom={2}>
            <CustomSelectInput
              name={String(index)}
              menuItems={permissionsList}
              value={item.permission}
              handleChange={handlePermissionId}
            />
            <CustomSelectInput
              name={item.permission}
              menuItems={[
                { view: 'Prompt', value: 'prompt' },
                { view: 'Grant', value: 'grant' },
                { view: 'Deny', value: 'deny' },
              ]}
              value={item.policy}
              handleChange={handlePermissionPolicy}
            />
            <IconButton onClick={() => deletePermission(index)}>
              <RemoveCircleOutlineIcon sx={{ color: 'red' }} />
            </IconButton>
          </Stack>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default RuntimePermissionsDialog;
