import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function DeviceFunctionality() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_use_of_camera,
    allow_face_time,
    allow_screen_capture,
    allow_airdrop,
    force_airdrop_unmanaged,
    allow_imessage,
    allow_spotlight_internet,
    allow_siri,
    allow_siri_when_locked,
    allow_siri_to_query_web,
    allow_modify_device_name,
    allow_airprint,
    allow_cred_store_airprint,
    allow_beacon_discovery_airprint,
    enforce_tls_trusted_cert_airprint,
  } = profile.restrictions.deviceFunctionality;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        deviceFunctionality: {
          ...prev.restrictions.deviceFunctionality,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Camera',
      name: 'allow_use_of_camera',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_use_of_camera,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
        },
        macos: {
          version: '10.11',
        },
        tvos: {
          version: '17',
        },
      },
    },
    {
      property: 'FaceTime',
      name: 'allow_face_time',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_face_time,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
        },
        macos: {
          version: '10.11',
        },
        tvos: {
          version: '17',
        },
      },
    },
    {
      property: 'Screenshots & Screen Recording',
      name: 'allow_screen_capture',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_screen_capture,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
        },
        macos: {
          version: '10.14.4',
        },
      },
    },
    {
      property: 'Airdrop',
      name: 'allow_airdrop',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_airdrop,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
          isSupervised: true,
        },
        macos: {
          version: '10.13',
        },
      },
    },
    {
      property: 'Airdrop as unmanaged drop point',
      name: 'force_airdrop_unmanaged',
      menuItems: GLOBAL_CONST.SELECT_YES_NO_TRUE_FALSE,
      value: force_airdrop_unmanaged,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
        },
      },
    },
    {
      property: 'Spotlight internet search',
      name: 'allow_spotlight_internet',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_spotlight_internet,
      handleChange,
      supportedOS: {
        ios: {
          version: '8',
          isSupervised: true,
        },
        macos: {
          version: '10.11',
        },
      },
    },
    {
      property: 'iMessage',
      name: 'allow_imessage',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_imessage,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Siri',
      name: 'allow_siri',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_siri,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
        },
        macos: {
          version: '14',
        },
      },
      children: [
        {
          property: 'Siri when device is locked',
          name: 'allow_siri_when_locked',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_siri_when_locked,
          handleChange,
          supportedOS: {
            ios: {
              version: '5.1',
            },
          },
        },
        {
          property: 'Siri to query from the web',
          name: 'allow_siri_to_query_web',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_siri_to_query_web,
          handleChange,
          supportedOS: {
            ios: {
              version: '7',
            },
            watchos: {
              version: '10',
            },
          },
        },
      ],
    },

    {
      property: 'Modify device name',
      name: 'allow_modify_device_name',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_device_name,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
        macos: {
          version: '14',
        },
        tvos: {
          version: '11.0',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Airprint',
      name: 'allow_airprint',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_airprint,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
      children: [
        {
          property: 'Store AirPrint credentials in iCloud Keychain',
          name: 'allow_cred_store_airprint',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_cred_store_airprint,
          handleChange,
          supportedOS: {
            ios: {
              version: '11',
              isSupervised: true,
            },
          },
        },
        {
          property: 'Enforce TLS trusted certificates for AirPrint',
          name: 'enforce_tls_trusted_cert_airprint',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: enforce_tls_trusted_cert_airprint,
          handleChange,
          supportedOS: {
            ios: {
              version: '11',
              isSupervised: true,
            },
          },
        },
        {
          property: 'Discover AirPrint printers using Bluetooth iBeacons',
          name: 'allow_beacon_discovery_airprint',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_beacon_discovery_airprint,
          handleChange,
          supportedOS: {
            ios: {
              version: '11',
              isSupervised: true,
            },
          },
        },
      ],
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default DeviceFunctionality;
