import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APPLE_ENROLLMENT_CONST, EMPTY_STRING, NULL } from '../../../constants';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_ENROLLMENT_CREATE_ROUTE,
  APPLE_ENROLLMENT_GET_ROUTE,
} from '../../../utils/routes-defs';

export interface IAppleEnrollment {
  appleId: string;
  apnsTopic: string;
  apnsValidity: Date | null;
  apnsRenewalDaysRemaining: number;
  apnsCertFile: File | null;
}

export const appleEnrollmentInit: IAppleEnrollment = {
  appleId: EMPTY_STRING,
  apnsTopic: EMPTY_STRING,
  apnsValidity: APPLE_ENROLLMENT_CONST.APNS_DEFAULT_VALIDITY,
  apnsRenewalDaysRemaining: APPLE_ENROLLMENT_CONST.APNS_DEFAULT_REMAINING_DAYS,
  apnsCertFile: NULL,
};

interface IAppleEnrollmentSliceState {
  appleEnrollment: IAppleEnrollment;
  isEnrolled: boolean;
  loading: boolean;
  message: IMessage;
}

const initialState: IAppleEnrollmentSliceState = {
  appleEnrollment: appleEnrollmentInit,
  isEnrolled: false,
  loading: false,
  message: messageInit,
};

export const getAppleEnrollmentDetails = createAsyncThunk(
  `getAppleEnrollmentDetails`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(APPLE_ENROLLMENT_GET_ROUTE);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const createAppleEnrollment = createAsyncThunk(
  'createAppleEnrollment',
  async (payload: FormData, thunkApI) => {
    try {
      const response = await axiosInstance().post(APPLE_ENROLLMENT_CREATE_ROUTE, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      thunkApI.dispatch(getAppleEnrollmentDetails());
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkApI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const appleEnrollmentSlice = createSlice({
  name: 'appleEnrollment',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppleEnrollmentDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleEnrollmentDetails.fulfilled, (state, action) => {
      state.loading = false;
      const { AppleID, ApnsTopic, ApnsValidity } = action.payload;
      if (AppleID) {
        state.isEnrolled = true;
        state.appleEnrollment.appleId = AppleID;
        state.appleEnrollment.apnsTopic = ApnsTopic;
        state.appleEnrollment.apnsValidity = ApnsValidity;
        state.appleEnrollment.apnsRenewalDaysRemaining = Math.round(
          (new Date(ApnsValidity).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
        );
      }
    });
    builder.addCase(getAppleEnrollmentDetails.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });
    builder.addCase(createAppleEnrollment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAppleEnrollment.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          success: true,
          successMessage: action.payload.message,
        };
      }
    });
    builder.addCase(createAppleEnrollment.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = appleEnrollmentSlice.actions;
export default appleEnrollmentSlice;
