import axiosInstance from './utils/axiosInstance';
import {
  ANDROID_APPLICATION_DETAILS_ROUTE,
  ANDROID_POLICY_DETAILS_ROUTE,
} from './utils/routes-defs';

export const getApp = (packageName: string, enterpriseId: string) =>
  axiosInstance().get(
    `${ANDROID_APPLICATION_DETAILS_ROUTE}?packageName=${packageName}&enterpriseId=${enterpriseId}`,
  );

export const getAppsList = (policyName: string) =>
  axiosInstance().get(`${ANDROID_POLICY_DETAILS_ROUTE}?policyName=${policyName}`);
