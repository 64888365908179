import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import MiniOrangelogo from '../../assets/images/miniorange-logo.webp';
import ErrorMessage from '../../components/ErrorMessage';
import { customerLogin } from '../../redux/slices/auth/authSlice';
import { AppDispatch, RootState } from '../../redux/store';

interface ILoginInputFields {
  email: string;
  password: string;
}
interface IError {
  email: boolean;
  password: boolean;
  message: string;
}

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((state: RootState) => state.auth);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };
  const [loginInputFields, setLoginInputFields] = useState<ILoginInputFields>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<IError>({
    email: false,
    password: false,
    message: '',
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setError((prevValue) => ({
      ...prevValue,
      [name]: false,
    }));
    setLoginInputFields((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const loginUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!loginInputFields.email && !loginInputFields.password) {
      setError({
        email: true,
        password: true,
        message: '',
      });
      return;
    }
    if (!loginInputFields.email) {
      setError({
        email: true,
        password: false,
        message: '',
      });
      return;
    }
    if (!loginInputFields.password) {
      setError({ email: false, password: true, message: '' });
      return;
    }
    const payload = {
      email: loginInputFields.email,
      password: loginInputFields.password,
    };
    dispatch(customerLogin(payload));
  };

  useEffect(() => {
    if (Cookies.get('token')) {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    if (auth.token && Cookies.get('token')) {
      navigate('/');
    }
  }, [auth.token]);

  return (
    <Box
      component='span'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Poppins !important',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '1rem',
        }}
      >
        <img src={MiniOrangelogo} height='60' alt='miniorange-logo' />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: '4rem',
        }}
      >
        <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography variant='h4' align='center' color='#3D3D3D'>
            Welcome Back, Login
          </Typography>
          <Typography variant='h6' align='center' color='#717171'>
            Log in with your data that entered during your registration
          </Typography>
          {auth.error !== '' && <ErrorMessage message={auth.error} />}
          <Box>
            <FormLabel>Email</FormLabel>
            <TextField
              required
              fullWidth
              size='small'
              id='email'
              name='email'
              onChange={onChange}
            />
            {error.email && (
              <Typography sx={{ color: 'red' }}>Please enter an email address.</Typography>
            )}
          </Box>
          <Box>
            <FormLabel>Password</FormLabel>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
              <OutlinedInput
                id='password'
                name='password'
                onChange={onChange}
                size='small'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                required
              />
            </FormControl>
            {error.password && <Typography sx={{ color: 'red' }}>Enter a password.</Typography>}
          </Box>

          <Button
            onClick={(e) => loginUser(e)}
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              backgroundColor: '#2D3748',
              textTransform: 'none',
              '&:hover': { background: '#2D3748' },
            }}
          >
            {auth.loading ? <CircularProgress size={25} sx={{ color: '#fff' }} /> : 'LOGIN'}
          </Button>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color='#717171'>
              Don’t have an account?{' '}
              <NavLink to='/signup' style={{ textDecoration: 'none', color: '#1976d2' }}>
                Sign up
              </NavLink>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
