import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageInit } from '../../../utils/common-constants';
import { ANDROID_ESCALATION_ROUTE } from '../../../utils/routes-defs';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  loading: false,
  message: messageInit,
};

export interface IAndroidEscalationFields {
  category: string;
  issueDescription: string;
  documents: string;
}

export const sendEscalation = createAsyncThunk(
  'androidEscalation',
  async (payload: IAndroidEscalationFields, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_ESCALATION_ROUTE, payload);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'Query raised successfully. We will reach out to you soon.',
        }),
      );
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const adnroidEscalationSlice = createSlice({
  name: 'androidEscalation',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendEscalation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEscalation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEscalation.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = adnroidEscalationSlice.actions;
export default adnroidEscalationSlice;
