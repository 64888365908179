import AppleIcon from '@mui/icons-material/Apple';
import AppsIcon from '@mui/icons-material/Apps';
import DevicesIcon from '@mui/icons-material/Devices';
import GoogleIcon from '@mui/icons-material/Google';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import {
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { COLOR_ACTIVE, COLOR_BLACK, COLOR_WHITE } from '../../theme';

interface ISidebarMenu {
  text: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  icon: any;
  path: string;
  clicked: boolean;
  children: Array<ISidebarMenu>;
}
type SidebarOptionsType = Array<ISidebarMenu>;
const sidebarOptions: SidebarOptionsType = [
  {
    text: 'DASHBOARD',
    icon: <HomeIcon />,
    path: '/',
    clicked: false,
    children: [],
  },
  {
    text: 'USERS',
    icon: <SentimentVerySatisfiedIcon />,
    path: '/users',
    clicked: false,
    children: [],
  },

  {
    text: 'ANDROID',
    icon: <SentimentVerySatisfiedIcon />,
    path: '/android',
    clicked: false,
    children: [
      {
        text: 'Android Enterprise',
        icon: <GoogleIcon />,
        path: '/androidenterprise',
        clicked: false,
        children: [],
      },
      {
        text: 'Devices',
        icon: <PhoneAndroidIcon />,
        path: '/androiddevices',
        clicked: false,
        children: [],
      },
      {
        text: 'Device Groups',
        icon: <SpeakerGroupIcon />,
        path: '/androiddevicegroups',
        clicked: false,
        children: [],
      },
      {
        text: 'Policies',
        icon: <SettingsIcon />,
        path: '/androidpolicies',
        clicked: false,
        children: [],
      },
      {
        text: 'Need Help',
        icon: <HelpIcon />,
        path: '/androidhelp',
        clicked: false,
        children: [],
      },
    ],
  },
  {
    text: 'APPLE',
    icon: <SentimentVerySatisfiedIcon />,
    path: '/apple',
    clicked: false,
    children: [
      {
        text: 'Apple Enrollment',
        icon: <AppleIcon />,
        path: '/appleenrollment',
        clicked: false,
        children: [],
      },
      {
        text: 'Device Groups',
        icon: <DevicesIcon />,
        path: '/appledevicegroups',
        clicked: false,
        children: [],
      },
      {
        text: 'Devices',
        icon: <PhoneAndroidIcon />,
        path: '/appledevices',
        clicked: false,
        children: [],
      },
      {
        text: 'Profiles',
        icon: <SettingsIcon />,
        path: '/appleprofiles',
        clicked: false,
        children: [],
      },
      {
        text: 'Applications',
        icon: <AppsIcon />,
        path: '/appleapplications',
        clicked: false,
        children: [],
      },
    ],
  },
];
const drawerWidth = 260;

interface ISidebarProps {
  openSidebar: boolean;
}
function Sidebar(props: ISidebarProps) {
  const { openSidebar } = props;
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<SidebarOptionsType>(sidebarOptions);

  const handleMenuClick = (index: number) => {
    setOpenMenu(() => {
      const menus = sidebarOptions;
      menus[index].clicked = !menus[index].clicked;
      return [...menus];
    });
  };

  return (
    <Collapse
      in={openSidebar}
      timeout='auto'
      unmountOnExit
      sx={{
        width: drawerWidth,
      }}
    >
      <List disablePadding>
        {sidebarOptions.map((item, index) => {
          const isActive = location.pathname === item.path;
          return (
            <div key={item.path}>
              <NavLink
                to={item.children.length > 0 ? location.pathname : item.path}
                style={{ textDecoration: 'none' }}
              >
                <ListItemButton
                  onClick={() => handleMenuClick(index)}
                  sx={{
                    p: '15px',
                    pl: '30px',
                    borderLeft: isActive ? `6px solid ${COLOR_ACTIVE}` : `6px solid ${COLOR_WHITE}`,
                    backgroundColor: isActive ? '#4339F205' : COLOR_WHITE,
                  }}
                >
                  <ListItemText
                    primary={
                      <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: isActive ? COLOR_ACTIVE : COLOR_BLACK,
                          }}
                        >
                          {item.text}
                        </Typography>
                        <IconButton sx={{ padding: 0 }}>
                          {item.children.length > 0 &&
                            (openMenu[index].clicked ? (
                              <KeyboardArrowUpIcon
                                sx={{
                                  color: '#9A9A9A',
                                }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: '#9A9A9A',
                                }}
                              />
                            ))}
                        </IconButton>
                      </Grid>
                    }
                  />
                </ListItemButton>
              </NavLink>
              <Collapse in={openMenu[index].clicked} timeout='auto' unmountOnExit>
                {item.children.length > 0 &&
                  item.children.map((child) => {
                    const isActiveChild = location.pathname === child.path;
                    return (
                      <NavLink to={child.path} key={child.path} style={{ textDecoration: 'none' }}>
                        <ListItemButton
                          sx={{
                            p: '10px 40px',
                            color: '#9A9A9A',
                            borderLeft: isActiveChild
                              ? `6px solid ${COLOR_ACTIVE}`
                              : `6px solid ${COLOR_WHITE}`,
                            backgroundColor: isActiveChild ? '#4339F205' : COLOR_WHITE,
                          }}
                        >
                          <ListItemText
                            primary={
                              <Grid
                                container
                                direction='row'
                                alignItems='center'
                                justifyContent='flex-start'
                                sx={{ color: isActiveChild ? COLOR_ACTIVE : COLOR_BLACK }}
                              >
                                <IconButton sx={{ color: '#3D3D3D', height: '8px' }}>
                                  {child.icon}
                                </IconButton>
                                <Typography variant='body2'>{child.text}</Typography>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      </NavLink>
                    );
                  })}
              </Collapse>
            </div>
          );
        })}
      </List>
    </Collapse>
  );
}

export default React.memo(Sidebar);
