import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteAppleProfile } from '../../../../redux/slices/appleProfile/appleProfileSlice';
import { AppDispatch } from '../../../../redux/store';
import { IAppleProfile } from '../../definitions';
import DeleteDialogBox from '../../../../components/DeleteDialogbox';

interface IProps {
  profile: IAppleProfile;
  openDeleteDialog: boolean;
  handleDeleteDialog: (isOpen: boolean) => void;
}

function DeleteProfileDialog(props: IProps) {
  const { profile, openDeleteDialog, handleDeleteDialog } = props;
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteProfile = () => {
    dispatch(deleteAppleProfile({ profileId: profile.id as string }));
    handleDeleteDialog(false);
  };

  return (
    <DeleteDialogBox
      open={openDeleteDialog}
      onClose={() => handleDeleteDialog(false)}
      onDelete={handleDeleteProfile}
      item='profile'
    />
  );
}

export default React.memo(DeleteProfileDialog);
