import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ProfileContext } from '../..';
import { APPLE_OS_TITLES, EMPTY_STRING } from '../../../../constants';
import { APPLE_PROFILE_MSG } from '../../../../messages';
import {
  createAppleProfile,
  updateAppleProfile,
  updateMessage,
} from '../../../../redux/slices/appleProfile/appleProfileSlice';
import { AppDispatch } from '../../../../redux/store';
import { messageInit } from '../../../../utils/common-constants';
import { IAppleProfile, initAppleProfile } from '../../definitions';
import TabNavigation from '../TabNavigation';

interface IProps {
  isCreate: boolean;
  openProfileDialog: boolean;
  handleProfileDialog: (create: boolean, profile: IAppleProfile) => void;
}

function ProfileDialog(props: IProps) {
  const { isCreate, openProfileDialog, handleProfileDialog } = props;
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateProfile = () => {
    if (profile.name === EMPTY_STRING) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: APPLE_PROFILE_MSG.PROFILE_NAME_REQUIRED,
        }),
      );
      return;
    }
    dispatch(createAppleProfile(profile));

    handleProfileDialog(true, profile);
    setProfile(initAppleProfile);
  };

  const handleUpdateProfile = () => {
    dispatch(updateAppleProfile(profile));

    handleProfileDialog(false, profile);
    setProfile(initAppleProfile);
  };

  const dialogTitle = `${isCreate ? 'Create' : 'Edit'} ${APPLE_OS_TITLES[osType]} Profile`;

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      PaperProps={{
        sx: {
          height: '80vh',
        },
      }}
      open={openProfileDialog}
      onClose={() => handleProfileDialog(true, initAppleProfile)}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => handleProfileDialog(true, initAppleProfile)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          direction='row'
          justifyContent='flex-between'
          alignItems='center'
          paddingBottom={2}
          spacing={2}
        >
          <Grid item sm={4}>
            <TextField
              fullWidth
              size='small'
              placeholder='Profile Name'
              disabled={!isCreate}
              value={profile.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setProfile((prev) => ({ ...prev, name: event.target.value.trim() }));
              }}
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              fullWidth
              size='small'
              placeholder='Description'
              value={profile.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setProfile((prev) => ({ ...prev, description: event.target.value }));
              }}
            />
          </Grid>
        </Grid>
        <TabNavigation />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleProfileDialog(true, initAppleProfile)}>
          Cancel
        </Button>
        {isCreate ? (
          <Button variant='contained' onClick={handleCreateProfile}>
            Create
          </Button>
        ) : (
          <Button variant='contained' onClick={handleUpdateProfile} disabled={false}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ProfileDialog;
