export interface IPermissionGrant {
  selected?: boolean;
  permission: string;
  policy: string;
}
export interface IApplicationMeta {
  title: string;
  managedConfiguration: boolean;
  iconUrl: string;
  permissions: [
    {
      permissionId: string;
      name?: string;
      description?: string;
    },
  ];
}
export interface IAndroidApp {
  appname: string;
  appInstallationType: string;
  appPolicy: string;
  appGrants: IPermissionGrant[];
  appDisabled: boolean;
  appMiniVersion: number;
  appScopes: string[];
  appManagedConfiguration?: {
    appConfigTemplate: string;
  };
  appTrackIds?: string[];
  appConnectedWorkAndPersonal: string;
  appUpdateMode: string;
  appOnVpnLockdownExemption?: string;
  appWidgets: string;
  appMeta: IApplicationMeta;
}
export interface IAppCatalog {
  applications: IAndroidApp[];
  appAutoUpdatePolicy: string;
  allAppsPolicy: string;
  allAppsPermissionGrant: IPermissionGrant[];
}
export interface IPasswordSetting {
  minLength: number;
  minLetters: number;
  minLowerCase: number;
  minimumNonLetter: number;
  minimumNumbers: number;
  minimumSymbols: number;
  minimumUpperCase: number;
  quality: string;
  historyLength: number;
  maxifailedPasswords: number;
  expirationTimeout: string;
  scope: string;
  requireUnlock: string;
  unifiedLockSettings: string;
}
export interface IPolicyEnforcementBlockAction {
  blockAfterDays: number;
  blockScope: string;
}
export interface IPolicyEnforcementWipeAction {
  wipeAfterDays: number;
  preserveFrp: boolean;
}
export interface IPolicyEnforcementRule {
  blockAction: IPolicyEnforcementBlockAction;
  wipeAction: IPolicyEnforcementWipeAction;
  settingName: string;
}
export interface ICrossProfileScopes {
  workContactsScope: string;
  copyPasteScope: string;
  dataSharingScope: string;
  widgetsDefaultScope: string;
}

export interface IPolicyControls {
  passwordSettings: IPasswordSetting[];
  policyEnforcementRules: IPolicyEnforcementRule[];
  crossProfileScopes: ICrossProfileScopes;
}

export interface ISystemUpdateFreezePeriod {
  startDate: {
    year: '0';
    month: string;
    day: string;
  };
  endDate: {
    year: '0';
    month: string;
    day: string;
  };
}
export interface ISystemUpdate {
  type: string;
  startMinutes?: string;
  endMinutes?: string;
  freezePeriods?: ISystemUpdateFreezePeriod[];
}

export interface ISecurityPermissions {
  untrustedApps: string;
  playProtectVerifyApps: string;
  developerSettingsScope: string;
  factoryResetSetting: boolean;
  systemUpdateSetting: ISystemUpdate;
  modifyAccountsDisabled: boolean;
}
export interface IDeviceConnectivitySettings {
  usbAccess: string;
  bluetoothDisabled: boolean;
  printingPolicy: string;
}
export interface IAdvanceControls {
  screenCapturePermission: boolean;
  physicalMediaPermission: boolean;
  cameraPermission: string;
  microphonePermission: string;
  securityPermissions: ISecurityPermissions;
  deviceConnectivitySettings: IDeviceConnectivitySettings;
}

export interface ITimeRestriction {
  enable: boolean;
  startTime: string; // UTC => HH:MM
  endTime: string; // UTC => HH:MM
  timezone: string; // actual timezone
  offset: string; // offset in the format HH:MM from UTC
}
export interface ICustomPermissions {
  timeRestriction: ITimeRestriction;
}

export interface IopenNetworkConfiguration {
  networkConfigurations: INetworkConfigurations[];
  certificates: ICertificates[];
  wifiConfigDisabled: boolean;
}

export interface INetworkConfigurations {
  guid: string;
  name: string;
  type: string;
  wifi: IWifi;
}
export interface IWifi {
  ssid: string;
  eap: IEAP;
  security: string;
  autoconnect: boolean;
  passphrase: string;
  macAddressRandomizationMode: string;
}
export interface IEAP {
  eapInner: string;
  eapOuter: string;
  eapIdentity: string;
  eapDomainSuffixMatch: string[];
  eapServerCARef: string;
  eapClientCertType: string;
  eapClientCertRef: string;
}

export interface ICertificates {
  guid?: string;
  type?: string;
  x509?: string;
  pkcs12?: string;
}

export interface INetwork {
  vpn: {
    vpnConfigDisabled: boolean;
    alwaysOnVpnPackage: {
      packageName: string;
      lockdownEnabled: boolean;
    };
  };
  openNetworkConfiguration: IopenNetworkConfiguration;
}

export interface IAndroidPolicy {
  policyId?: string;
  policyName: string;
  policyDescription: string;
  appCatalog: IAppCatalog;
  policyControls: IPolicyControls;
  advanceControls: IAdvanceControls;
  network: INetwork;
  customPermissions: ICustomPermissions;
}

export const initAndroidPolicy: IAndroidPolicy = {
  policyName: '',
  policyDescription: '',
  appCatalog: {
    applications: [],
    appAutoUpdatePolicy: 'always',
    allAppsPolicy: 'prompt',
    allAppsPermissionGrant: [],
  },
  policyControls: {
    passwordSettings: [
      {
        minLength: 6,
        minLetters: 0,
        minLowerCase: 0,
        minimumNonLetter: 0,
        minimumNumbers: 0,
        minimumSymbols: 0,
        minimumUpperCase: 0,
        quality: 'numeric',
        historyLength: 2,
        maxifailedPasswords: 4,
        expirationTimeout: '123456789.0s',
        scope: 'scope_profile',
        requireUnlock: 'default',
        unifiedLockSettings: 'unified_lock',
      },
    ],
    policyEnforcementRules: [],
    crossProfileScopes: {
      workContactsScope: 'allowed',
      copyPasteScope: 'allowed',
      dataSharingScope: 'allowed',
      widgetsDefaultScope: 'allowed',
    },
  },
  advanceControls: {
    screenCapturePermission: true,
    physicalMediaPermission: true,
    cameraPermission: 'enforced',
    microphonePermission: 'enforced',
    securityPermissions: {
      untrustedApps: 'allow_device_wide',
      playProtectVerifyApps: 'enforced',
      developerSettingsScope: 'disabled',
      factoryResetSetting: true,
      modifyAccountsDisabled: false,
      systemUpdateSetting: {
        type: 'postpone',
        startMinutes: '00:00',
        endMinutes: '04:00',
        freezePeriods: [],
      },
    },
    deviceConnectivitySettings: {
      usbAccess: 'disallow_data_transfer',
      bluetoothDisabled: false,
      printingPolicy: 'disallowed',
    },
  },
  network: {
    vpn: {
      vpnConfigDisabled: false,
      alwaysOnVpnPackage: {
        packageName: '',
        lockdownEnabled: false,
      },
    },
    openNetworkConfiguration: {
      networkConfigurations: [],
      certificates: [],
      wifiConfigDisabled: false,
    },
  },
  customPermissions: {
    timeRestriction: {
      enable: false,
      startTime: '17:00', // UTC => HH:MM
      endTime: '09:00', // UTC => HH:MM
      timezone: '(GMT +5:30) Indian Standard (Mumbai, Kolkata, Chennai, New Delhi)', // actual timezone
      offset: '+05:30', // offset in the format HH:MM from UTC
    },
  },
};
