import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  CssBaseline,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import miniorangeLogo from '../../assets/images/miniorange-logo.webp';
import { logout } from '../../redux/slices/auth/authSlice';
import { AppDispatch, RootState } from '../../redux/store';

interface IHeaderProps {
  handleSidebar: () => void;
}
function Header(props: IHeaderProps) {
  const { handleSidebar } = props;
  const dispatch = useDispatch<AppDispatch>();

  const customerAuth = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    dispatch(logout());
    handleCloseMenu();
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position='sticky'
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'white',
          padding: '0 10px',
          boxShadow: '0px 0px 0px 0px',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Toolbar>
          <Grid container direction='row' alignItems='center' justifyContent='space-evenly'>
            <Grid item xs={9} sm={7} sx={{ mt: { xs: '5px', sm: '7.5px' } }}>
              <img src={miniorangeLogo} alt='Minorange Logo' width={!isMobile ? '25%' : '60%'} />
            </Grid>
            {!isMobile ? (
              <Grid item sm={5}>
                <Stack direction='row' justifyContent='flex-end' spacing={3}>
                  {/* <SearchBox />
                  <Button
                    variant='contained'
                    disableElevation
                    sx={{ backgroundColor: '#4F46F3', height: '36px' }}
                  >
                    Switch Tenant <KeyboardArrowDownIcon />
                  </Button> */}
                  <IconButton onClick={handleClickMenuItem}>
                    <PersonIcon />
                  </IconButton>
                  <Menu
                    sx={{ mt: 2 }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem
                      onClick={handleLogout}
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <span>{customerAuth.customerEmail}</span>
                      <span>Logout</span>
                    </MenuItem>
                  </Menu>
                </Stack>
              </Grid>
            ) : (
              <>
                <Grid item xs={1}>
                  <IconButton onClick={handleClickMenuItem}>
                    <PersonIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem
                      dense
                      onClick={handleLogout}
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <span>{customerAuth.customerEmail}</span>
                      <span>Logout</span>
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => handleSidebar()}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </>
            )}

            {/* {isMobile ? (
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-around'
                sx={{ marginBottom: '10px' }}
              >
                <Grid item xs={6}>
                  <SearchBox />
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant='contained'
                    disableElevation
                    sx={{
                      backgroundColor: '#4F46F3',
                      height: '28px',
                      fontSize: '9px',
                    }}
                  >
                    Switch Tenant{' '}
                    <KeyboardArrowDownIcon sx={{ fontSize: { xs: '12px', sm: '14px' } }} />
                  </Button>
                </Grid>
              </Grid>
            ) : null} */}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default React.memo(Header);
