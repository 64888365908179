export interface IMessage {
  error: boolean;
  errorMessage: string;
  success: boolean;
  successMessage: string;
}
export const messageInit: IMessage = {
  error: false,
  errorMessage: '',
  success: false,
  successMessage: '',
};
export interface IEnterprise {
  enterpriseId: string;
  displayName: string;
}
export const enterpriseInit: IEnterprise = {
  enterpriseId: '',
  displayName: '',
};
export interface IUser {
  _id?: string;
  email: string;
  userName: string;
}
export const userInit: IUser = {
  email: '',
  userName: '',
};
