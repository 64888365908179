import React, { ChangeEvent, useContext, useRef } from 'react';
import { Button, Card, Grid, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { PolicyContext } from '../../../..';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import { AppDispatch } from '../../../../../../redux/store';
import { updateMessage } from '../../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { messageInit } from '../../../../../../utils/common-constants';
import DeviceProfileType from '../../../../constants';
import PropertyAndInput from '../../../PropertyAndInput';

const initWifiConfig = {
  guid: '',
  name: '',
  type: '',
  wifi: {
    ssid: '',
    security: '',
    autoconnect: false,
    passphrase: '',
    macAddressRandomizationMode: '',
    eap: {
      eapInner: '',
      eapOuter: '',
      eapIdentity: '',
      eapDomainSuffixMatch: [],
      eapServerCARef: '',
      eapClientCertType: '',
      eapClientCertRef: '',
    },
  },
};

function WifiConfiguration() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const dispatch = useDispatch<AppDispatch>();
  const { networkConfigurations, wifiConfigDisabled } = policy.network.openNetworkConfiguration;
  const debounceTimer = useRef<number>(0);
  const handleNameTypeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
  ) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    [name]: value,
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleWifiChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
  ) => {
    const { name, value } = event.target;

    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    wifi: {
                      ...config.wifi,
                      [name]: value,
                    },
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleEapChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { name, value } = event.target;
    const updatedValue: string | string[] =
      name === 'eapDomainSuffixMatch' ? value.split(',').map((item) => item.trim()) : value;

    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    wifi: {
                      ...config.wifi,
                      eap: {
                        ...config.wifi.eap,
                        [name]: updatedValue,
                      },
                    },
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleEapBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'eapDomainSuffixMatch') {
      clearTimeout(debounceTimer.current);
      // there is a time differnce when the focus of the field is changing from one tab to other via mouse pointer
      // so we need to delay the time between input field switch when using mouse pointer
      debounceTimer.current = window.setTimeout(() => {
        const domainSuffixMatch = value.split(',').map((item) => item.trim());
        // Domain name validation check
        const isValidDomain = domainSuffixMatch.every((domain) => {
          const parts = domain.split('.');
          return parts.length === 2 && parts.every((part) => part.length > 0);
        });

        dispatch(
          updateMessage({
            ...messageInit,
            error: !isValidDomain,
            errorMessage: isValidDomain ? '' : 'Enter comma seperated valid domain names',
          }),
        );
      }, 500);
    }
  };

  const handleAddNewNetworkConfiguration = () => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: [
            ...prev.network.openNetworkConfiguration.networkConfigurations,
            initWifiConfig,
          ],
        },
      },
    }));
  };

  const removeNetworkConfiguration = (index: number) => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.filter(
            (_, id) => id !== index,
          ),
        },
      },
    }));
  };

  const handleWifiConfig = () => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          wifiConfigDisabled: !prev.network.openNetworkConfiguration.wifiConfigDisabled,
        },
      },
    }));
  };

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item xs={6}>
        <PropertyAndSelectInput
          data={{
            property: 'Configure other WiFi networks',
            name: 'wifiConfigDisabled',
            menuItems: [
              { view: 'Allow', value: true },
              { view: 'Deny', value: false },
            ],
            value: wifiConfigDisabled,
            androidVersion: '6.0+',
            profileType: `${DeviceProfileType.COMPANY_OWNED}`,
            handleChange: () => handleWifiConfig(),
          }}
        />
      </Grid>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          {networkConfigurations.map((networkConfig, index) => (
            <Card
              key={networkConfig.guid}
              sx={{ padding: 2, marginBottom: 2, display: 'flex', flexDirection: 'column' }}
            >
              <PropertyAndInput
                property='Network Name'
                name='name'
                value={networkConfig.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleNameTypeChange(e, index)}
                androidVersion='6.0+'
                profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                isRequired
                placeholder='Network Name'
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Network Type',
                  name: 'type',
                  menuItems: [{ view: 'WiFi', value: 'WiFi' }],
                  value: networkConfig.type,
                  handleChange: (e: SelectChangeEvent<string>) => handleNameTypeChange(e, index),
                  androidVersion: '6.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />
              <PropertyAndInput
                property='SSID'
                name='ssid'
                value={networkConfig.wifi?.ssid || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleWifiChange(e, index)}
                androidVersion='6.0+'
                profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                placeholder='WiFi Name'
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Security',
                  name: 'security',
                  menuItems: [
                    { view: 'None', value: 'None' },
                    { view: 'WEP-PSK', value: 'wep_psk' },
                    { view: 'WPA-PSK', value: 'wpa_psk' },
                    { view: 'WPA-EAP', value: 'wpa_eap' },
                  ],
                  value: networkConfig.wifi?.security,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '6.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />
              {networkConfig.wifi?.security === 'wpa_psk' && (
                <PropertyAndInput
                  property='Passphrase'
                  name='passphrase'
                  value={networkConfig.wifi?.passphrase || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleWifiChange(e, index)}
                  androidVersion='6.0+'
                  profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                  placeholder='Password'
                />
              )}
              {networkConfig.wifi?.security === 'wpa_eap' && (
                <>
                  <PropertyAndInput
                    property='EAP Inner'
                    name='eapInner'
                    value={networkConfig.wifi?.eap?.eapInner || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter EAP Inner'
                  />
                  <PropertyAndInput
                    property='EAP Outer'
                    name='eapOuter'
                    value={networkConfig.wifi?.eap?.eapOuter || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter EAP Outer'
                  />
                  <PropertyAndInput
                    property='EAP Identity'
                    name='eapIdentity'
                    value={networkConfig.wifi?.eap?.eapIdentity || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter EAP Identity'
                  />
                  <PropertyAndInput
                    property='EAP Domain Suffix Match'
                    name='eapDomainSuffixMatch'
                    value={(networkConfig.wifi?.eap?.eapDomainSuffixMatch || []).join(', ')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleEapBlur(e)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter comma-separated domain suffixes'
                  />
                  <PropertyAndInput
                    property='EAP Server CA Reference'
                    name='eapServerCARef'
                    value={networkConfig.wifi?.eap?.eapServerCARef || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter CA Reference'
                  />
                  <PropertyAndInput
                    property='EAP Client Certificate Type'
                    name='eapClientCertType'
                    value={networkConfig.wifi?.eap?.eapClientCertType || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter Certificate Type'
                  />
                  <PropertyAndInput
                    property='EAP Client Certificate Reference'
                    name='eapClientCertRef'
                    value={networkConfig.wifi?.eap?.eapClientCertRef || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter Certificate Reference'
                  />
                </>
              )}
              <PropertyAndSelectInput
                data={{
                  property: 'Auto Connect',
                  name: 'autoconnect',
                  menuItems: [
                    { view: 'Allow', value: true },
                    { view: 'Deny', value: false },
                  ],
                  value: networkConfig.wifi?.autoconnect,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '6.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'MAC Address Randomization Mode',
                  name: 'macAddressRandomizationMode',
                  menuItems: [
                    { view: 'Automatic', value: 'auto' },
                    { view: 'Hardware', value: 'hard' },
                  ],
                  value: networkConfig.wifi?.macAddressRandomizationMode,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '13.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />

              <div style={{ marginTop: 'auto', width: 'fit-content', marginLeft: 'auto' }}>
                <Button
                  onClick={() => removeNetworkConfiguration(index)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
          <Button variant='contained' onClick={handleAddNewNetworkConfiguration}>
            Add Network Configuration
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WifiConfiguration;
