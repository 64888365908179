import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { APPLE_ENROLLMENT_CONST, EMPTY_STRING, NULL } from '../../constants';
import MainLayout from '../../layouts/MainLayout';
import {
  getAppleEnrollmentDetails,
  updateMessage,
} from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { COLOR_BLACK } from '../../theme';
import { messageInit } from '../../utils/common-constants';
import AppleEnrollmentDialog from './components/AppleEnrollmentDialog';

export interface IAppleEnrollment {
  appleId: string;
  apnsTopic: string;
}

export const initAppleEnrollment: IAppleEnrollment = {
  appleId: EMPTY_STRING,
  apnsTopic: EMPTY_STRING,
};

function AppleEnrollment() {
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const [open, setOpen] = useState<boolean>(false);

  const handleAppleEnrollmentDialog = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (
      appleEnrollment.appleEnrollment.appleId === EMPTY_STRING ||
      appleEnrollment.appleEnrollment.apnsTopic === EMPTY_STRING
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
  }, []);

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title='Apple Enrollment' />
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        <Button
          variant='contained'
          onClick={handleAppleEnrollmentDialog}
          disabled={!!appleEnrollment.appleEnrollment.appleId}
        >
          Enroll
        </Button>
        <AppleEnrollmentDialog
          openEnrollmentDialog={open}
          handleEnrollmentDialog={handleAppleEnrollmentDialog}
        />
      </Grid>
      <Grid>
        <Card sx={{ maxWidth: 375, ml: 2 }} elevation={2}>
          <CardContent>
            <Typography variant='h6' color='text.primary' gutterBottom>
              APNS Certificate Details
            </Typography>
            <Typography color='text.secondary' sx={{ fontSize: 14 }}>
              <span style={{ color: COLOR_BLACK }}>Apple ID</span> :{' '}
              {appleEnrollment.appleEnrollment.appleId}
            </Typography>
            <Typography color='text.secondary' sx={{ fontSize: 14 }}>
              <span style={{ color: COLOR_BLACK }}>APNS Topic</span> :{' '}
              {appleEnrollment.appleEnrollment.apnsTopic}
            </Typography>
            <Typography color='text.secondary' sx={{ fontSize: 14 }}>
              <span style={{ color: COLOR_BLACK }}>Valid till</span> :{' '}
              {appleEnrollment.appleEnrollment.apnsValidity !== NULL &&
                new Date(appleEnrollment.appleEnrollment.apnsValidity)?.toUTCString()}
            </Typography>
            <Typography color='text.secondary' sx={{ fontSize: 14 }}>
              <span style={{ color: COLOR_BLACK }}>Days Remaining</span> :{' '}
              {appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining ===
              APPLE_ENROLLMENT_CONST.APNS_DEFAULT_REMAINING_DAYS
                ? EMPTY_STRING
                : appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              size='small'
              onClick={handleAppleEnrollmentDialog}
              disabled={
                appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining >
                APPLE_ENROLLMENT_CONST.APNS_REMAINING_DAYS_THREE_MONTHS
              }
            >
              Renew APNS Cert
            </Button>
          </CardActions>
        </Card>
      </Grid>
      {(appleEnrollment.message.error || appleEnrollment.message.success) && (
        <ErrorSuccessSnackbar
          opened={appleEnrollment.message.error || appleEnrollment.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appleEnrollment.message.errorMessage}
          successMessage={appleEnrollment.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleEnrollment;
