import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMessage as updateMessageAndroid } from '../../redux/slices/androidDevice/androidDeviceSlice';
import { updateMessage as updateMessageApple } from '../../redux/slices/appleDevice/appleDeviceSlice';
import { getUsers } from '../../redux/slices/users/usersSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { IUser, messageInit, userInit } from '../../utils/common-constants';
import RadioOptions from '../RadioOptions';
import EnrollmentSelectInput from './EnrollmentSelectInput';
import EnrollmentSteps from './EnrollmentSteps';
import {
  CHOICE_SEND_EMAIL,
  CHOICE_SHOW_DETAILS,
  CORPORATE_PROFILE_DESCRIPTION,
  DEVICE_CORPORATE,
  DEVICE_PERSONAL,
  ENROLLMENT_EMAIL_OPTION_DESCRIPTION,
  ENROLLMENT_STEPS_DISPLAY_DESCRIPTION,
  PERSONAL_PROFILE_DESCRIPTION,
} from './constatnts';
import {
  IAndroidInvitationPayload,
  IAppleInvitationPayload,
  IEnrollmentDetails,
  ISelectItem,
  enrollmentButtonLabels,
  enrollmentDetailsInit,
} from './definitions';

interface IProps {
  deviceType: 'android' | 'apple';
  title: string;
  open: boolean;
  handleDialog: () => void;
  groupList: ISelectItem[];
  selectGroup: (e: SelectChangeEvent<ISelectItem>) => void;
  selectedGroup: ISelectItem;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  sendEnrollmentInvitation: any;
}
function EnrollmentDialog(props: IProps) {
  const {
    deviceType,
    title,
    open,
    handleDialog,
    groupList,
    selectGroup,
    selectedGroup,
    sendEnrollmentInvitation,
  } = props;
  const enterprise = useSelector((state: RootState) => state.androidEnterprise.enterprise);

  const users = useSelector((state: RootState) => state.users.users);
  const [user, setUser] = useState<IUser>(userInit);

  const [ownershipType, setOwnershipType] = useState<string>(DEVICE_PERSONAL);
  const [enrollmentOption, setEnrollmentOption] = useState<string>(CHOICE_SEND_EMAIL);

  const [enrollmentDetails, setEnrollmentDetails] = useState<IEnrollmentDetails>(
    enrollmentDetailsInit,
  );

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const selectUser = (e: SelectChangeEvent<ISelectItem>) => {
    const userDetails = e.target.value as ISelectItem;
    setUser({
      _id: userDetails._id,
      email: userDetails.value1,
      userName: userDetails.value2,
    });
  };

  const handleOwnershipOption = (e: ChangeEvent<HTMLInputElement>) => {
    setOwnershipType((e.target as HTMLInputElement).value);
    setEnrollmentDetails(enrollmentDetailsInit);
  };

  const handleEnrollmentButton = (e: ChangeEvent<HTMLInputElement>) => {
    setEnrollmentOption((e.target as HTMLInputElement).value);
  };

  const handleEnrollmentInvitationOption = () => {
    if (enrollmentOption === CHOICE_SEND_EMAIL) {
      sendEnrollmentInvite(true);
    } else if (enrollmentOption === CHOICE_SHOW_DETAILS) {
      sendEnrollmentInvite(false);
    }
  };

  const sendEnrollmentInvite = async (sendEmail: boolean) => {
    if (deviceType === 'android') {
      if (!user._id || user._id === '') {
        dispatch(
          updateMessageAndroid({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a user',
          }),
        );
        return;
      }
      if (!selectedGroup._id || selectedGroup._id === '') {
        dispatch(
          updateMessageAndroid({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a group',
          }),
        );
        return;
      }
      const payload: IAndroidInvitationPayload = {
        enterpriseId: enterprise.enterpriseId,
        userId: user._id!,
        userEmail: user.email,
        userName: user.userName,
        policy: selectedGroup.value2,
        ownership: ownershipType,
        sendEmail,
      };
      const resp = await dispatch(sendEnrollmentInvitation(payload));
      setEnrollmentDetails(resp.payload);
    } else if (deviceType === 'apple') {
      if (!user._id || user._id === '') {
        dispatch(
          updateMessageApple({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a user',
          }),
        );
        return;
      }
      if (!selectedGroup._id || selectedGroup._id === '') {
        dispatch(
          updateMessageApple({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a group',
          }),
        );
        return;
      }
      const payload: IAppleInvitationPayload = {
        groupId: selectedGroup._id,
        userId: user._id!,
        ownership: ownershipType,
        sendEmail,
      };
      const resp = await dispatch(sendEnrollmentInvitation(payload));
      setEnrollmentDetails(resp.payload);
    }
  };

  const userList: ISelectItem[] = [];
  users.map((user) =>
    userList.push({
      _id: user._id,
      value1: user.email,
      value2: user.userName,
    }),
  );

  return (
    <Dialog fullWidth maxWidth='md' onClose={handleDialog} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ minWidth: 120 }}>
          <Grid container direction='row' alignItems='center' paddingBottom={2}>
            <Grid item sm={4}>
              <Typography variant='body1'>User</Typography>
            </Grid>
            <Grid item sm={6}>
              <EnrollmentSelectInput
                fieldId='user-select'
                fieldLabel='User'
                entries={userList}
                selectedEntry={{ _id: user._id, value1: user.email, value2: user.userName }}
                handler={selectUser}
              />
            </Grid>
          </Grid>
          <RadioOptions
            fieldId='device-ownership'
            fieldLabel='Device Owned By'
            options={[
              {
                name: DEVICE_PERSONAL,
                label: 'Personal',
                description: PERSONAL_PROFILE_DESCRIPTION,
              },
              {
                name: DEVICE_CORPORATE,
                label: 'Corporate',
                description: CORPORATE_PROFILE_DESCRIPTION,
              },
            ]}
            handler={handleOwnershipOption}
          />
          <Grid container direction='row' alignItems='center' paddingBottom={2}>
            <Grid item sm={4}>
              <Typography variant='body1'>Assign to Group</Typography>
            </Grid>
            <Grid item sm={6}>
              <EnrollmentSelectInput
                fieldId='group-select'
                fieldLabel='Group'
                entries={groupList}
                selectedEntry={selectedGroup}
                handler={selectGroup}
              />
            </Grid>
          </Grid>
          <RadioOptions
            fieldId='enrollment-type'
            fieldLabel='Enrollment'
            options={[
              {
                name: CHOICE_SEND_EMAIL,
                label: 'By User Invite',
                description: ENROLLMENT_EMAIL_OPTION_DESCRIPTION,
              },
              {
                name: CHOICE_SHOW_DETAILS,
                label: 'By Myself',
                description: ENROLLMENT_STEPS_DISPLAY_DESCRIPTION,
              },
            ]}
            handler={handleEnrollmentButton}
          />
        </Box>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            sx={{ m: 1 }}
            onClick={handleEnrollmentInvitationOption}
            disabled={!!enrollmentDetails.qrCode && enrollmentOption === CHOICE_SHOW_DETAILS}
          >
            {enrollmentButtonLabels[enrollmentOption]}
          </Button>
        </div>
        {enrollmentDetails.qrCode && enrollmentOption === CHOICE_SHOW_DETAILS && (
          <EnrollmentSteps
            deviceType={deviceType}
            qrCode={enrollmentDetails.qrCode}
            enrollmentToken={enrollmentDetails.enrollmentToken}
            steps={enrollmentDetails.steps}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EnrollmentDialog;
