import { ReactNode, forwardRef } from 'react';
import { Grid, Typography } from '@mui/material';
import { COLOR_BLACK } from '../../theme';

interface IProps {
  title: string;
  subtitle?: string;
  logo?: string;
  additionalComponent?: ReactNode;
}

const CustomHeader = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { logo, title, subtitle, additionalComponent } = props;

  return (
    <div ref={ref}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        borderBottom={1}
        borderColor='#D9D9D9'
        padding={3}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '2rem',
          }}
        >
          {logo ? <img src={logo} alt='Google Logo' width={30} height={30} /> : null}
          <Grid container direction='column'>
            <Typography variant='h6' sx={{ color: COLOR_BLACK }}>
              {title}
            </Typography>
            {subtitle ? (
              <Typography variant='body2' sx={{ color: '#9A9A9A', whiteSpace: 'nowrap' }}>
                {subtitle}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {additionalComponent || null}
      </Grid>
    </div>
  );
});

CustomHeader.defaultProps = {
  logo: '',
  subtitle: '',
  additionalComponent: undefined,
};

export default CustomHeader;
