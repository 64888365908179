import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import Properties from '../../../Properties';

function DateTime() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const { set_device_time } = profile.restrictions.datetime;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        datetime: {
          ...prev.restrictions.datetime,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Set device time',
      name: 'set_device_time',
      menuItems: [
        {
          view: 'Automatically via location service',
          value: true,
        },
        {
          view: 'User controlled',
          value: false,
        },
      ],
      value: set_device_time,
      handleChange,
      supportedOS: {
        ios: {
          version: '12',
          isSupervised: true,
        },
        tvos: {
          version: '12.2',
          isSupervised: true,
        },
      },
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default DateTime;
