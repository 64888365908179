import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Applications() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_appstore,
    allow_untrusted_app_installation,
    allow_configurator_itunes_to_update_apps,
    allow_app_deletion,
    allow_system_app_deletion,
    allow_auto_download_apps_on_multiple_devices_with_same_appleid,

    allow_inapp_purchase,
    allow_gamecenter,
    allow_gamecenter_multiplayer,
    allow_gamecenter_add_friends,

    allow_modify_notification_setting,

    allow_itunes,
    allow_itunes_file_sharing,

    allow_news_app,
    allow_podcast_app,

    allow_music_service,
    allow_radio_service,

    allow_ibookstore,
    allow_ibookstore_erotica,
  } = profile.restrictions.applications;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        applications: {
          ...prev.restrictions.applications,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'App Store',
      name: 'allow_appstore',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_appstore,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
          supervisedOver: '13',
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Unapproved/untrusted app installation',
      name: 'allow_untrusted_app_installation',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_untrusted_app_installation,
      handleChange,
      supportedOS: {
        ios: {
          version: '17.4',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Allow configurator/itunes to install/update apps',
      name: 'allow_configurator_itunes_to_update_apps',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_configurator_itunes_to_update_apps,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'App deletion',
      name: 'allow_app_deletion',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_app_deletion,
      handleChange,
      supportedOS: {
        ios: {
          version: '4.2.1',
          isSupervised: true,
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'System App Deletion',
      name: 'allow_system_app_deletion',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_system_app_deletion,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Downloads apps automatically on multiple devices with same Apple ID',
      name: 'allow_auto_download_apps_on_multiple_devices_with_same_appleid',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_auto_download_apps_on_multiple_devices_with_same_appleid,
      handleChange,
      supportedOS: {
        ios: {
          version: '9',
          isSupervised: true,
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'In-app purchase',
      name: 'allow_inapp_purchase',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_inapp_purchase,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Gamecenter',
      name: 'allow_gamecenter',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_gamecenter,
      handleChange,
      supportedOS: {
        ios: {
          version: '6',
          isSupervised: true,
        },
        macos: {
          version: '10.13',
        },
      },
      children: [
        {
          property: 'Gamecenter Multiplayer',
          name: 'allow_gamecenter_multiplayer',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_gamecenter_multiplayer,
          handleChange,
          supportedOS: {
            ios: {
              version: '4.1',
              isSupervised: true,
            },
            macos: {
              version: '10.13',
            },
          },
        },
        {
          property: 'Gamecenter add friends',
          name: 'allow_gamecenter_add_friends',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_gamecenter_add_friends,
          handleChange,
          supportedOS: {
            ios: {
              version: '4.2.1',
              isSupervised: true,
              supervisedOver: '13',
            },
            macos: {
              version: '10.13',
            },
          },
        },
      ],
    },
    {
      property: 'Modify notification settings',
      name: 'allow_modify_notification_setting',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_notification_setting,
      handleChange,
      supportedOS: {
        ios: {
          version: '9.3',
          isSupervised: true,
        },
      },
    },
    {
      property: 'iTunes store',
      name: 'allow_itunes',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_itunes,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
          supervisedOver: '13',
        },
      },
    },
    {
      property: 'iTunes Store File Sharing',
      name: 'allow_itunes_file_sharing',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_itunes_file_sharing,
      handleChange,
      supportedOS: {
        macos: {
          version: '10.13',
        },
      },
    },
    {
      property: 'Podcast App',
      name: 'allow_podcast_app',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_podcast_app,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
          supervisedOver: '13',
        },
      },
    },
    {
      property: 'News App',
      name: 'allow_news_app',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_news_app,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
          supervisedOver: '13',
        },
      },
    },
    {
      property: 'Music Services',
      name: 'allow_music_service',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_music_service,
      handleChange,
      supportedOS: {
        ios: {
          version: '9.3',
          isSupervised: true,
        },
        macos: {
          version: '10.12',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Radio Services',
      name: 'allow_radio_service',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_radio_service,
      handleChange,
      supportedOS: {
        ios: {
          version: '9.3',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Book Store',
      name: 'allow_ibookstore',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_ibookstore,
      handleChange,
      supportedOS: {
        ios: {
          version: '6',
          isSupervised: true,
        },
      },
      children: [
        {
          property: 'Book Store Erotic Content',
          name: 'allow_ibookstore_erotica',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_ibookstore_erotica,
          handleChange,
          supportedOS: {
            ios: {
              version: '6',
              isSupervised: true,
            },
            tvos: {
              version: '11.3',
              isSupervised: true,
            },
          },
        },
      ],
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Applications;
