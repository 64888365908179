import { Checkbox, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import CustomAccordion from '../../../../../../../components/CustomAccordion';
import CustomSelectInput from '../../../../../../../components/CustomSelectInput';

interface IProps {
  appname: string;
  permissionId: string;
  selected: boolean;
  name: string;
  value: string | undefined;
  description: string;
  handleChangePolicy: (event: SelectChangeEvent) => void;
  handleSelectPermission: (event: ChangeEvent<HTMLInputElement>) => void;
}
function PermissionAccordion(props: IProps) {
  const {
    appname,
    permissionId,
    selected,
    name,
    value,
    description,
    handleChangePolicy,
    handleSelectPermission,
  } = props;
  return (
    <CustomAccordion
      ariaControls={`${appname}-${permissionId}`}
      summary={
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item sm={2}>
            <Checkbox name={permissionId} checked={selected} onChange={handleSelectPermission} />
          </Grid>
          <Grid item sm={8}>
            {name}
          </Grid>
          <Grid item sm={2}>
            <CustomSelectInput
              name={permissionId}
              menuItems={[
                { view: 'Prompt', value: 'prompt' },
                { view: 'Grant', value: 'grant' },
                { view: 'Deny', value: 'deny' },
              ]}
              value={value}
              handleChange={handleChangePolicy}
            />
          </Grid>
        </Grid>
      }
      details={
        <>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {permissionId !== 'appPolicy' ? permissionId : null}
          </Typography>
          <Typography variant='body1'>{description}</Typography>
        </>
      }
    />
  );
}

export default PermissionAccordion;
