import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IAppleAppGroup } from '../../../pages/AppleAppGroups/definitions';
import prepareAppsDataForAPI from '../../../utils/appleMDMUtilities/appleAppDetailsFiltering';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_APPLICATION_GROUP_CREATE_ROUTE,
  APPLE_APPLICATION_GROUP_DELETE_ROUTE,
  APPLE_APPLICATION_GROUP_LIST_ROUTE,
  APPLE_APPLICATION_GROUP_UPDATE_ROUTE,
} from '../../../utils/routes-defs';

interface IAppleAppGroupSliceState {
  appleAppGroups: IAppleAppGroup[];
  loading: boolean;
  message: IMessage;
}

const initialState: IAppleAppGroupSliceState = {
  appleAppGroups: [],
  loading: false,
  message: messageInit,
};

interface IAppleAppGroupDeletePayload {
  appGroupId: string;
}

export const getAppleAppGroups = createAsyncThunk(
  'appleAppGroups/getAppleAppGroups',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(APPLE_APPLICATION_GROUP_LIST_ROUTE);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const allAppGroups = response.data.appleAppGroups.map((appGroup: any) => ({
        ...appGroup,
        apps: JSON.parse(appGroup.apps),
      }));
      return allAppGroups;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const createAppleAppGroup = createAsyncThunk(
  'appleAppGroups/createAppleAppGroups',
  async (payload: IAppleAppGroup, thunkAPI) => {
    try {
      const response = await axiosInstance().post(APPLE_APPLICATION_GROUP_CREATE_ROUTE, {
        ...payload,
        apps: prepareAppsDataForAPI(payload.apps),
      });
      thunkAPI.dispatch(getAppleAppGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const deleteAppleAppGroup = createAsyncThunk(
  'appleAppGroups/deleteAppleAppGroups',
  async (payload: IAppleAppGroupDeletePayload, thunkAPI) => {
    try {
      const { appGroupId } = payload;
      await axiosInstance().delete(
        `${APPLE_APPLICATION_GROUP_DELETE_ROUTE}?appGroupId=${appGroupId}`,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'App group deleted successfully',
        }),
      );
      return appGroupId;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateAppleAppGroup = createAsyncThunk(
  'appleAppGroups/updateAppleAppGroups',
  async (payload: IAppleAppGroup, thunkAPI) => {
    try {
      const response = await axiosInstance().put(APPLE_APPLICATION_GROUP_UPDATE_ROUTE, {
        ...payload,
        apps: prepareAppsDataForAPI(payload.apps),
      });
      thunkAPI.dispatch(getAppleAppGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const appleAppGroupSlice = createSlice({
  name: 'appleAppGroups',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get appConfigs
    builder.addCase(getAppleAppGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleAppGroups.fulfilled, (state, action) => {
      const allApplications = action.payload;
      state.loading = false;
      state.appleAppGroups = allApplications;
    });
    builder.addCase(getAppleAppGroups.rejected, (state) => {
      state.loading = false;
    });

    // create appConfig
    builder.addCase(createAppleAppGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAppleAppGroup.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(createAppleAppGroup.rejected, (state) => {
      state.loading = false;
    });

    // delete appConfig
    builder.addCase(deleteAppleAppGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAppleAppGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.appleAppGroups = state.appleAppGroups.filter(
        (appGroup) => appGroup.id !== action.payload,
      );
    });
    builder.addCase(deleteAppleAppGroup.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // update appConfig
    builder.addCase(updateAppleAppGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAppleAppGroup.fulfilled, (state, action) => {
      state.loading = false;
      const responseMessage = action.payload.message;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(updateAppleAppGroup.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = appleAppGroupSlice.actions;
export default appleAppGroupSlice;
