import { Card, Stack, Typography } from '@mui/material';

interface IProps {
  title: string;
  value: number;
}
export default function DashboardCard(props: IProps) {
  const { title, value } = props;
  return (
    <Card elevation={2} sx={{ p: 1 }}>
      <Stack direction='column' alignItems='center'>
        <Typography variant='h5' sx={{ color: '#9A9A9A' }}>
          {title}
        </Typography>
        <Typography variant='h5' sx={{ color: '#9A9A9A' }}>
          {value}
        </Typography>
      </Stack>
    </Card>
  );
}
