import { Button, Checkbox, CircularProgress, Grid, SelectChangeEvent } from '@mui/material';
import React, { useContext, useState } from 'react';
import { PolicyContext } from '../../../../..';
import CustomAccordion from '../../../../../../../components/CustomAccordion';
import CustomSelectInput from '../../../../../../../components/CustomSelectInput';
import { IAndroidApp } from '../../../../../definitions';
import AdvancedSettings from '../AdvancedSettings';
import ManageAppConfigComponent from '../ManageAppConfigComponent';
import RuntimePermissionsComponent from '../RuntimePermissionsComponent';

// eslint-disable-next-line
declare const gapi: any;

interface IProps {
  app: IAndroidApp;
}
export default function AppCard(props: IProps) {
  const { app } = props;
  const { setPolicy } = useContext(PolicyContext);

  const selectInstallType = (e: SelectChangeEvent, app: IAndroidApp) => {
    const newAppInstallationType = e.target.value;
    setPolicy((prev) => {
      const newApplicationsState = prev.appCatalog?.applications?.map((obj) => {
        if (obj.appname === app.appname) {
          return { ...obj, appInstallationType: newAppInstallationType };
        }
        return obj;
      });

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: newApplicationsState,
        },
      };
    });
  };
  const handleAppSelectionChange = (e: React.ChangeEvent<HTMLInputElement>, app: IAndroidApp) => {
    const selected = e.target.checked;
    setPolicy((prev) => {
      const newApplicationsState = prev.appCatalog?.applications?.map((obj) => {
        if (obj.appname === app.appname) {
          return { ...obj, appDisabled: !selected };
        }
        return obj;
      });

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: newApplicationsState,
        },
      };
    });
  };

  const [openPlaystoreAppConfigDialog, setOpenPlaystoreAppConfigDialog] = useState<boolean>(false);
  const handlePlaystoreAppConfigDialog = () => {
    setOpenPlaystoreAppConfigDialog((prev) => !prev);
  };
  const [openRuntimePermissionsDialog, setOpenRuntimePermissionsDialog] = useState<boolean>(false);
  const handleRuntimePermissionsDialog = () => {
    setOpenRuntimePermissionsDialog((prev) => !prev);
  };
  const [openAdvancedSettingsDialog, setOpenAdvancedSettingsDialog] = useState<boolean>(false);
  const handleAdvancedSettingsDialog = () => {
    setOpenAdvancedSettingsDialog((prev) => !prev);
  };

  return (
    <CustomAccordion
      ariaControls={app.appname}
      summary={
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item sm={2}>
            <Checkbox
              checked={!app.appDisabled}
              onChange={(e) => handleAppSelectionChange(e, app)}
            />
          </Grid>
          <Grid item sm={2}>
            {app.appMeta.iconUrl ? (
              <img src={app.appMeta.iconUrl} alt={app.appMeta.title} height={40} width={40} />
            ) : (
              <CircularProgress size={35} />
            )}
          </Grid>
          <Grid item sm={8}>
            <CustomSelectInput
              menuItems={[
                { view: 'Force Installed', value: 'force_install' },
                { view: 'Preinstalled', value: 'preinstalled' },
                { view: 'Block', value: 'block' },
                { view: 'Available', value: 'available' },
                { view: 'Kiosk', value: 'kiosk' },
              ]}
              value={app.appInstallationType}
              handleChange={(e: SelectChangeEvent) => selectInstallType(e, app)}
            />
          </Grid>
        </Grid>
      }
      details={
        <div key={app.appname}>
          <Button
            variant='outlined'
            disabled={!app.appMeta.managedConfiguration}
            onClick={handlePlaystoreAppConfigDialog}
          >
            Configurations
          </Button>
          <Button variant='outlined' onClick={handleRuntimePermissionsDialog}>
            Runtime Permissions
          </Button>
          <Button variant='outlined' onClick={handleAdvancedSettingsDialog}>
            Advanced Settings
          </Button>
          <ManageAppConfigComponent
            app={app}
            open={openPlaystoreAppConfigDialog}
            onClose={handlePlaystoreAppConfigDialog}
          />
          <RuntimePermissionsComponent
            app={app}
            open={openRuntimePermissionsDialog}
            onClose={handleRuntimePermissionsDialog}
            permissionsList={app.appMeta.permissions}
          />
          <AdvancedSettings
            app={app}
            open={openAdvancedSettingsDialog}
            onClose={handleAdvancedSettingsDialog}
          />
        </div>
      }
    />
  );
}
