export const ANDROID_BUG_REPORT_SECTION_DESC =
  'To take a bug report from Developer options, you must have a Developer options enabled on your device so you can access the Take bug report option.';

export const ANDROID_DEVELOPER_MODE_ENABLE_DESC = `By default, the Developer options screen is hidden. Here's how to make it visible:`;

export const ANDROID_DEVELOPER_MODE_ENABLE_STEPS = [
  'Go to <strong>Settings</strong> > <strong>About phone</strong>.',
  'Tap <strong>Build number</strong> seven times.',
  'Return to the previous screen to find Developer options at the bottom.',
];

export const ANDROID_BUG_REPORT_STEPS_DESC = `In <strong>Developer options</strong>, tap Take bug report.`;

export const ANDROID_BUG_REPORT_STEPS = [
  'Select the type of bug report you want and tap Report.',
  'After a moment you get a notification that the bug report is ready.',
  'Tap the notification box to share the bug report.',
];

export const ANDROID_BUG_REPORT_SECTION_NOTE =
  'For this method to work, the device must be signed into a Google account.';

export const ANDROID_ESCALATION_FORM_NOTE =
  'Note: Please upload all the documents over some cloud repository. (Eg. google drive and provide the link here by sharing the folder access to mdmsupport@xecurify.com)';

export const ANDROID_MDM_FAQ_LINK = 'https://faq.miniorange.com/kb/unified-endpoint-management/';

export const ANDROID_MDM_GUIDES_LINK =
  'https://www.miniorange.com/unified-endpoint-management/platforms/how-to-enroll-android-enterprise-mdm';

export enum IEscalationIssueCategoryEnum {
  device_enrollment = 'Device Enrollment',
  policy_troubleshooting = 'Policy Troubleshooting',
  application_management = 'Application Management',
  device_management = 'Device Management',
  other = 'Other',
}
