import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IAppleDeviceGroup } from '../../../pages/AppleDeviceGroups';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_APPLICATION_GROUP_ATTACH_TO_DEVICE_GROUP_ROUTE,
  APPLE_APPLICATION_GROUP_DETACH_FROM_DEVICE_GROUP_ROUTE,
  APPLE_CONFIGURATION_PROFILE_ATTACH_TO_DEVICE_GROUP_ROUTE,
  APPLE_CONFIGURATION_PROFILE_DETACH_FROM_DEVICE_GROUP_ROUTE,
  APPLE_DEVICE_GROUP_CREATE_ROUTE,
  APPLE_DEVICE_GROUP_DELETE_ROUTE,
  APPLE_DEVICE_GROUP_LIST_ROUTE,
} from '../../../utils/routes-defs';

interface IAppleDeviceGroupDeletePayload {
  id: string;
}
interface IAppleDeviceGroupSliceState {
  appleDeviceGroups: IAppleDeviceGroup[];
  loading: boolean;
  message: IMessage;
}

const initialState: IAppleDeviceGroupSliceState = {
  appleDeviceGroups: [],
  loading: false,
  message: messageInit,
};

export const getAppleDeviceGroups = createAsyncThunk(
  'deviceGroup/getAppleDeviceGroups',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(APPLE_DEVICE_GROUP_LIST_ROUTE);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const appleDeviceGroups: IAppleDeviceGroup[] = response.data.appleDeviceGroups
        .reverse()
        .map((groupItem: any, idx: number) => ({
          id: idx + 1,
          _id: groupItem.id,
          name: groupItem.name,
          description: groupItem.description,
          AppGroups: groupItem.AppGroups,
          Profiles: groupItem.Profiles,
        }));

      return appleDeviceGroups;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const createAppleDeviceGroup = createAsyncThunk(
  'deviceGroup/createAppleDeviceGroup',
  async (payload: IAppleDeviceGroup, thunkAPI) => {
    try {
      const response = await axiosInstance().post(APPLE_DEVICE_GROUP_CREATE_ROUTE, payload);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getAppleDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);
export const deleteAppleDeviceGroup = createAsyncThunk(
  'deviceGroup/deleteAppleDeviceGroup',
  async (payload: IAppleDeviceGroupDeletePayload, thunkAPI) => {
    try {
      const { id } = payload;
      await axiosInstance().delete(`${APPLE_DEVICE_GROUP_DELETE_ROUTE}?id=${id}`);
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: 'Device group deleted successfully',
        }),
      );
      return id;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const attachProfileToDeviceGroup = createAsyncThunk(
  'deviceGroup/attachProfileToDeviceGroup',
  async (payload: { profileId: string; deviceGroupId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_CONFIGURATION_PROFILE_ATTACH_TO_DEVICE_GROUP_ROUTE,
        payload,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getAppleDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const detachProfileFromDeviceGroup = createAsyncThunk(
  'deviceGroup/detachProfileFromDeviceGroup',
  async (payload: { profileId: string; deviceGroupId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_CONFIGURATION_PROFILE_DETACH_FROM_DEVICE_GROUP_ROUTE,
        payload,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getAppleDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const attachAppGroupToDeviceGroup = createAsyncThunk(
  'deviceGroup/attachAppGroupToDeviceGroup',
  async (payload: { appGroupId: string; deviceGroupId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_APPLICATION_GROUP_ATTACH_TO_DEVICE_GROUP_ROUTE,
        payload,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getAppleDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

export const detachAppGroupFromDeviceGroup = createAsyncThunk(
  'deviceGroup/detachAppGroupFromDeviceGroup',
  async (payload: { appGroupId: string; deviceGroupId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_APPLICATION_GROUP_DETACH_FROM_DEVICE_GROUP_ROUTE,
        payload,
      );
      thunkAPI.dispatch(
        updateMessage({
          ...messageInit,
          success: true,
          successMessage: response.data.message,
        }),
      );
      thunkAPI.dispatch(getAppleDeviceGroups());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      }
      throw err;
    }
  },
);

const deviceGroupSlice = createSlice({
  name: 'AppledeviceGroup',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // create apple device group
    builder.addCase(createAppleDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAppleDeviceGroup.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAppleDeviceGroup.rejected, (state) => {
      state.loading = false;
    });
    // delete apple device group
    builder.addCase(deleteAppleDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAppleDeviceGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.appleDeviceGroups = state.appleDeviceGroups.filter(
        (group) => group._id !== action.payload,
      );
    });
    builder.addCase(deleteAppleDeviceGroup.rejected, (state) => {
      state.loading = false;
    });

    // get apple device group list
    builder.addCase(getAppleDeviceGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleDeviceGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.appleDeviceGroups = action.payload;
    });
    builder.addCase(getAppleDeviceGroups.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateMessage } = deviceGroupSlice.actions;
export default deviceGroupSlice;
