import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import googleLogo from '../../assets/images/google-logo.png';
import CustomHeader from '../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import { getAndroidEnterprise } from '../../redux/slices/androidEnterprise/androidEnterpriseSlice';
import {
  getAndroidPolicies,
  updateMessage,
} from '../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import { GET } from '../AndroidEnterprise';
import PolicyCard from './components/PolicyCard';
import PolicyDialog from './components/PolicyDialog';
import { IAndroidPolicy, initAndroidPolicy } from './definitions';

export const PolicyContext = createContext<{
  policy: IAndroidPolicy;
  setPolicy: React.Dispatch<React.SetStateAction<IAndroidPolicy>>;
}>({ policy: initAndroidPolicy, setPolicy: () => {} });

function AndroidPolicies() {
  const dispatch = useDispatch<AppDispatch>();
  const policies = useSelector((state: RootState) => state.androidPolicy);
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [policy, setPolicy] = useState<IAndroidPolicy>(initAndroidPolicy);
  const policyContextProviderValue = useMemo(
    () => ({
      policy,
      setPolicy,
    }),
    [policy],
  );
  const [openPolicyDialog, setOpenPolicyDialog] = useState<boolean>(false);

  const handlePolicyDialog = useCallback((create: boolean, policyReceived: IAndroidPolicy) => {
    if (create) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
    setPolicy(policyReceived);
    setOpenPolicyDialog((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(getAndroidPolicies());
    if (enterpriseId === '') {
      dispatch(getAndroidEnterprise(GET));
    }
  }, []);

  return (
    <MainLayout>
      <CustomHeader logo={googleLogo} title='Android Policies' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button variant='contained' onClick={() => handlePolicyDialog(true, policy)}>
            <AddIcon /> Create Policy
          </Button>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        {policies.androidPolicies.length > 0 &&
          policies.androidPolicies.map((item) => (
            <Grid item key={item.policyName} xs={12} sm={3} sx={{ pb: 2 }}>
              <PolicyCard policy={item} handlePolicyDialog={handlePolicyDialog} />
            </Grid>
          ))}
      </Grid>

      <PolicyContext.Provider value={policyContextProviderValue}>
        <PolicyDialog
          isCreate={isCreate}
          openPolicyDialog={openPolicyDialog}
          handlePolicyDialog={handlePolicyDialog}
        />
      </PolicyContext.Provider>
      {(policies.message.error || policies.message.success) && (
        <ErrorSuccessSnackbar
          opened={policies.message.error || policies.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={policies.message.errorMessage}
          successMessage={policies.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AndroidPolicies;
