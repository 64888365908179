import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Privacy() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_find_my_friends,
    allow_modify_find_my_friends_settings,
    allow_find_my_device,
    allow_send_diagnostics_apple,
    allow_modify_diagnostics_apple,
    force_limit_ad_tracking,

    allow_lockscreen_control_center,
    allow_lockscreen_notification_center,
    allow_lockscreen_today_view,
    allow_mail_privacy_protection,
  } = profile.restrictions.privacy;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        privacy: {
          ...prev.restrictions.privacy,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Find my friends',
      name: 'allow_find_my_friends',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_find_my_friends,
      handleChange,
      supportedOS: {
        ios: {
          version: '13',
          isSupervised: true,
        },
        macos: {
          version: '10.15',
          isSupervised: true,
        },
      },
      children: [
        {
          property: 'Modify find my friends',
          name: 'allow_modify_find_my_friends_settings',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_modify_find_my_friends_settings,
          handleChange,
          supportedOS: {
            ios: {
              version: '7',
              isSupervised: true,
            },
          },
        },
      ],
    },
    {
      property: 'Find my device',
      name: 'allow_find_my_device',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_find_my_device,
      handleChange,
      supportedOS: {
        ios: {
          version: '13',
          isSupervised: true,
        },
        macos: {
          version: '10.15',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Send diagnostics data to Apple',
      name: 'allow_send_diagnostics_apple',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_send_diagnostics_apple,
      handleChange,
      supportedOS: {
        ios: {
          version: '6',
        },
        macos: {
          version: '10.13',
        },
      },
      children: [
        {
          property: 'Modify Diagnostics & Usage UI settings',
          name: 'allow_modify_diagnostics_apple',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_modify_diagnostics_apple,
          handleChange,
          supportedOS: {
            ios: {
              version: '9.3.2',
              isSupervised: true,
            },
          },
        },
      ],
    },
    {
      property: 'Force limited ad tracking',
      name: 'force_limit_ad_tracking',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_limit_ad_tracking,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
        },
      },
    },
    {
      property: 'Lockscreen control center',
      name: 'allow_lockscreen_control_center',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_lockscreen_control_center,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
        },
      },
    },
    {
      property: 'Lockscreen notification view',
      name: 'allow_lockscreen_notification_center',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_lockscreen_notification_center,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
        },
      },
    },
    {
      property: 'Lockscreen today view',
      name: 'allow_lockscreen_today_view',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_lockscreen_today_view,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
        },
      },
    },
    {
      property: 'Mail privacy protection',
      name: 'allow_mail_privacy_protection',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_mail_privacy_protection,
      handleChange,
      supportedOS: {
        ios: {
          version: '15.2',
          isSupervised: true,
        },
      },
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Privacy;
