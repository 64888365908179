import {
  Button,
  Card,
  CardContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import googleLogo from '../../assets/images/google-logo.png';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import { messageInit } from '../../utils/common-constants';

import CustomHeader from '../../components/CustomHeader';
import {
  getAndroidEnterprise,
  updateMessage,
} from '../../redux/slices/androidEnterprise/androidEnterpriseSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { COLOR_BLACK } from '../../theme';
import { ANDROID_ENTERPRISE_SIGNUP_URL_ROUTE } from '../../utils/routes-defs';

export const CREATE = 'create';
export const GET = 'get';

function EnterpriseRegistrationStepper(props: { activeStep: number }) {
  const { activeStep } = props;

  const steps = ['Register', 'Finished'];

  const stepperStyle = {
    '& .Mui-completed': {
      '&.MuiStepIcon-root': {
        color: '#148A66',
      },
      '& .MuiStepConnector-line': {
        borderColor: 'secondary.main',
      },
    },
  };

  const stepper = (
    <Grid item xs={12} sm={3}>
      <Stepper activeStep={activeStep} sx={stepperStyle}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );

  return stepper;
}

function AndroidEnterprise() {
  const dispatch = useDispatch<AppDispatch>();
  const androidEnterprise = useSelector((state: RootState) => state.androidEnterprise);

  const openWindow = () => {
    const token = Cookies.get('token');
    if (token) {
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${ANDROID_ENTERPRISE_SIGNUP_URL_ROUTE}`;
      const windowName = 'NewWindow';
      const windowSize = 'width=1200,height=1000';

      const formObj = document.createElement('form');
      formObj.setAttribute('target', windowName);
      formObj.setAttribute('method', 'post');
      formObj.setAttribute('action', url);

      const inputElement = document.createElement('input');
      inputElement.setAttribute('type', 'hidden');
      inputElement.setAttribute('name', 'token');
      inputElement.setAttribute('value', token);

      formObj.appendChild(inputElement);
      document.body.appendChild(formObj);

      const newWindow = window.open('', windowName, windowSize) as Window;
      formObj.submit();

      const timer = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(timer);
          dispatch(getAndroidEnterprise(CREATE));
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (androidEnterprise.enterprise.enterpriseId === '') {
      dispatch(getAndroidEnterprise(GET));
    }
  }, []);

  return (
    <MainLayout>
      <CustomHeader
        logo={googleLogo}
        title='Android Enterprise'
        additionalComponent={
          <EnterpriseRegistrationStepper activeStep={androidEnterprise.activeStep} />
        }
      />
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        <Button
          variant='contained'
          onClick={openWindow}
          disabled={androidEnterprise.activeStep === 2}
        >
          Register
        </Button>
      </Grid>
      <Card sx={{ maxWidth: 275, ml: 2 }} elevation={2}>
        <CardContent>
          <Typography variant='h6' color='text.primary' gutterBottom>
            Enterprise Details
          </Typography>
          <Typography color='text.secondary' sx={{ fontSize: 14 }}>
            <span style={{ color: COLOR_BLACK }}>Enterprise Id</span> :{' '}
            {androidEnterprise.enterprise.enterpriseId}
          </Typography>
          <Typography color='text.secondary' sx={{ fontSize: 14 }}>
            <span style={{ color: COLOR_BLACK }}>Display Name</span> :{' '}
            {androidEnterprise.enterprise.displayName}
          </Typography>
        </CardContent>
      </Card>
      {(androidEnterprise.message.error || androidEnterprise.message.success) && (
        <ErrorSuccessSnackbar
          opened={androidEnterprise.message.error || androidEnterprise.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={androidEnterprise.message.errorMessage}
          successMessage={androidEnterprise.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AndroidEnterprise;
