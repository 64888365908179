import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';

interface IProps {
  children: React.ReactNode;
}
function MainLayout(props: IProps) {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const handleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  useEffect(() => {
    setOpenSidebar(!isMobile);
  }, [isMobile]);

  return (
    <>
      <Header handleSidebar={handleSidebar} />
      <Grid container direction='row' justifyContent='flex-start' flexWrap='nowrap'>
        <Grid
          item
          sx={{
            display: openSidebar ? 'block' : 'none',
            height: '100vh',
          }}
        >
          <Sidebar openSidebar={openSidebar} />
        </Grid>
        <Grid
          item
          flexGrow={1}
          sx={{
            borderLeft: openSidebar ? '0.75px solid #D9D9D9' : 'none',
            height: '100vh',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(MainLayout);
