import { SelectChangeEvent } from '@mui/material';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import EnrollmentDialog from '../../components/Enrollments/EnrollmentDialog';
import { ISelectItem } from '../../components/Enrollments/definitions';
import { sendEnrollmentInvitaion } from '../../redux/slices/androidDevice/androidDeviceSlice';
import { RootState } from '../../redux/store';
import { IDeviceGroup, groupInit } from '../DeviceGroup';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}
function DeviceEnrollment(props: IProps) {
  const { open, handleDialog } = props;

  const groups = useSelector((state: RootState) => state.deviceGroup.deviceGroups);
  const [group, setGroup] = useState<IDeviceGroup>(groupInit);

  const selectGroup = (e: SelectChangeEvent<ISelectItem>) => {
    const groupDetail = e.target.value as ISelectItem;
    setGroup({
      _id: groupDetail._id,
      name: groupDetail.value1,
      androidPolicyId: {
        _id: '',
        name: groupDetail.value2,
      },
      policy: '',
      policyId: '',
      description: '',
    });
  };

  const groupList: ISelectItem[] = [];
  groups.map((group) =>
    groupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.policy,
    }),
  );

  return (
    <EnrollmentDialog
      deviceType='android'
      title='Enroll Android Device'
      open={open}
      handleDialog={handleDialog}
      groupList={groupList}
      sendEnrollmentInvitation={sendEnrollmentInvitaion}
      selectGroup={selectGroup}
      selectedGroup={{
        _id: group._id,
        value1: group.name,
        value2: group.androidPolicyId.name,
      }}
    />
  );
}

export default DeviceEnrollment;
