import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';

interface IProps {
  message: string;
}
function ErrorMessage(props: IProps) {
  const { message } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: 'white',
        backgroundColor: '#E6353D',
        borderRadius: '0.23rem',
        py: '0.5rem',
        px: '0.25rem',
      }}
    >
      <ErrorOutlineIcon />
      <Typography sx={{ ml: '0.25rem' }}>{message}</Typography>
    </Box>
  );
}

export default ErrorMessage;
