import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import googleLogo from '../../assets/images/google-logo.png';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import { ISelectItem } from '../../components/Enrollments/definitions';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import MainLayout from '../../layouts/MainLayout';
import {
  getAndroidDevices,
  syncDeviceList,
  updateDeviceGroup,
  updateMessage,
} from '../../redux/slices/androidDevice/androidDeviceSlice';
import { getAndroidEnterprise } from '../../redux/slices/androidEnterprise/androidEnterpriseSlice';
import { getDeviceGroups } from '../../redux/slices/deviceGroup/deviceGroupSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { messageInit } from '../../utils/common-constants';
import { GET } from '../AndroidEnterprise';
import DeviceEnrollment from './DeviceEnrollment';
import CommandsMenu from './components/CommandsMenu';
import { IDevice, IDeviceGroupUpdatePayload } from './definitions';

interface IActionButtonForDevice {
  name: string;
  anchorEl: HTMLElement | null;
}
function AndroidDevices() {
  const dispatch = useDispatch<AppDispatch>();
  const enterpriseId = useSelector(
    (state: RootState) => state.androidEnterprise.enterprise.enterpriseId,
  );
  const deviceGroups = useSelector((state: RootState) => state.deviceGroup.deviceGroups);
  const androidDevice = useSelector((state: RootState) => state.androidDevice);

  const [openEnrolmentDialog, setOpenEnrolmentDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<IDevice[]>([]);
  const [openMenuDropdown, setOpenMenuDropdown] = useState<IActionButtonForDevice[]>(
    androidDevice.androidDevices.map((item) => ({ name: item.name, anchorEl: null })),
  );

  useEffect(() => {
    dispatch(getAndroidEnterprise(GET));
    dispatch(getAndroidDevices());
    dispatch(getDeviceGroups());
  }, []);

  useEffect(() => {
    setRows(androidDevice.androidDevices);
  }, [androidDevice.androidDevices]);

  const selectGroup = (e: SelectChangeEvent, deviceName: string, rowId: number) => {
    const selectedGroup = e.target.value.split(' ');

    const tempRows: IDevice[] = [...rows];
    const id = rowId - 1;
    const tempObj = {
      ...tempRows[id],
      id: rowId,
      deviceGroupId: selectedGroup[0],
      deviceGroup: selectedGroup[1],
    };

    tempRows[0] = { ...tempObj };

    setRows([...tempRows]);

    const payload: IDeviceGroupUpdatePayload = {
      deviceGroupId: selectedGroup[0],
      deviceGroupName: selectedGroup[1],
      deviceName,
      deviceType: 'android',
    };

    dispatch(updateDeviceGroup(payload));
  };

  const handleEnrolmentDialog = () => {
    setOpenEnrolmentDialog((prev) => !prev);
  };

  const handleIssueCommandMenu = (event: React.MouseEvent<HTMLElement>, deviceName: string) => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = androidDevice.androidDevices.map((item) => ({
        name: item.name,
        anchorEl: null,
      }));
      const index = anchors.findIndex((item) => item.name === deviceName);
      if (index !== -1) {
        anchors[index] = { ...anchors[index], anchorEl: event.currentTarget };
      }
      return anchors;
    });
  };

  const handleMenuDropdownClose = () => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = androidDevice.androidDevices.map((item) => ({
        name: item.name,
        anchorEl: null,
      }));
      return anchors;
    });
  };

  const androidDeviceGroupList: ISelectItem[] = [];
  deviceGroups.map((group) =>
    androidDeviceGroupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.policy,
    }),
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 120,
    },
    {
      field: 'deviceGroup',
      headerName: 'Device Group',
      width: 160,
      renderCell: (params) => (
        <>
          <span />
          {deviceGroups.length > 0 ? (
            <Select
              fullWidth
              labelId='group-select-label'
              id={String(params.row.id)}
              label='Group'
              value={`${params.row.deviceGroupId} ${params.row.deviceGroup}`}
              onChange={(e) => selectGroup(e, params.row.name, params.row.id)}
            >
              {deviceGroups.map((group) => (
                <MenuItem key={group._id} value={`${group._id} ${group.name}`}>
                  <Typography variant='body1' sx={{ fontSize: '14px' }}>
                    {group.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          ) : null}
        </>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand',
      width: 100,
    },
    {
      field: 'model',
      headerName: 'Model',
      width: 100,
    },
    {
      field: 'managementMode',
      headerName: 'Management',
      width: 120,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 110,
    },
    {
      field: 'policy',
      headerName: 'Policy',
      width: 120,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleIssueCommandMenu(event, params.row.name)
            }
          >
            <MoreVertIcon />
          </IconButton>
          {openMenuDropdown?.find((item) => item.name === params.row.name)?.anchorEl ? (
            <CommandsMenu
              deviceName={params.row.name}
              anchorEl={
                openMenuDropdown?.find((item) => item.name === params.row.name)?.anchorEl || null
              }
              handleMenu={handleMenuDropdownClose}
              policyName={params.row.policy}
              deviceManagementMode={params.row.managementMode}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader logo={googleLogo} title='Android Devices' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button
            variant='contained'
            onClick={() => dispatch(syncDeviceList({ enterpriseId }))}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {androidDevice.loading ? <CircularProgress color='inherit' size={20} /> : <SyncIcon />}
            Sync Devices
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={handleEnrolmentDialog}>
            <AddIcon />
            Enroll
          </Button>
        </Grid>
      </Grid>
      <CustomTable rows={rows} columns={columns} />
      <DeviceEnrollment open={openEnrolmentDialog} handleDialog={handleEnrolmentDialog} />
      {(androidDevice.message.error || androidDevice.message.success) && (
        <ErrorSuccessSnackbar
          opened={androidDevice.message.error || androidDevice.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={androidDevice.message.errorMessage}
          successMessage={androidDevice.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AndroidDevices;
