import { TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR_BLACK, COLOR_PRIMARY_BLUE, COLOR_WHITE } from '../../theme';

export const TextareaAutosizeStyled = styled(TextareaAutosize)(({ theme }) => ({
  '&:hover': {
    borderColor: '1px black !important',
  },
  '&:focus': {
    borderColor: 0,
    outline: `1px solid ${
      theme.palette.mode === 'dark' ? COLOR_BLACK : COLOR_PRIMARY_BLUE
    } !important`,
  },
  '&:focus-visible': {
    outline: 0,
  },
}));

export const customStyles = {
  card: {
    border: `1px solid #1976d2`,
    borderRadius: '10px !important',
    transition: 'background-color 0.3s, color 0.3s',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: COLOR_PRIMARY_BLUE,
      color: COLOR_WHITE,
      '& a': {
        color: COLOR_WHITE,
        textDecoration: 'none',
      },
    },
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  cardActions: {
    justifyContent: 'flex-end',
    color: COLOR_PRIMARY_BLUE,
    '&:hover': {
      color: COLOR_WHITE,
    },
    cursor: 'pointer',
  },
};
