const permissionsList = [
  {
    view: 'Accept Handover',
    value: 'android.permission.ACCEPT_HANDOVER',
    group: 'PHONE',
    minApiLevel: 28,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Access Background Location',
    value: 'android.permission.ACCESS_BACKGROUND_LOCATION',
    group: 'LOCATION',
    minApiLevel: 29,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Access Coarse Location',
    value: 'android.permission.ACCESS_COARSE_LOCATION',
    group: 'LOCATION',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Access Fine Location',
    value: 'android.permission.ACCESS_FINE_LOCATION',
    group: 'LOCATION',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Activity Recognition',
    value: 'android.permission.ACTIVITY_RECOGNITION',
    group: 'ACTIVITY_RECOGNITION',
    minApiLevel: 29,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Add Voicemail',
    value: 'com.android.voicemail.permission.ADD_VOICEMAIL',
    group: 'PHONE',
    minApiLevel: 14,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Answer Phone Calls',
    value: 'android.permission.ANSWER_PHONE_CALLS',
    group: 'PHONE',
    minApiLevel: 26,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Bluetooth Connect',
    value: 'android.permission.BLUETOOTH_CONNECT',
    group: 'NEARBY_DEVICES',
    minApiLevel: 31,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Bluetooth Scan',
    value: 'android.permission.BLUETOOTH_SCAN',
    group: 'NEARBY_DEVICES',
    minApiLevel: 31,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Body Sensors',
    value: 'android.permission.BODY_SENSORS',
    group: 'SENSORS',
    minApiLevel: 20,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Call Phone',
    value: 'android.permission.CALL_PHONE',
    group: 'PHONE',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Camera',
    value: 'android.permission.CAMERA',
    group: 'CAMERA',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Get Accounts',
    value: 'android.permission.GET_ACCOUNTS',
    group: 'CONTACTS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Process Outgoing Calls',
    value: 'android.permission.PROCESS_OUTGOING_CALLS',
    group: 'CALL_LOG',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read Calendar',
    value: 'android.permission.READ_CALENDAR',
    group: 'CALENDAR',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read Call Log',
    value: 'android.permission.READ_CALL_LOG',
    group: 'CALL_LOG',
    minApiLevel: 16,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read Contacts',
    value: 'android.permission.READ_CONTACTS',
    group: 'CONTACTS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read External Storage',
    value: 'android.permission.READ_EXTERNAL_STORAGE',
    group: 'STORAGE',
    minApiLevel: 16,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read Phone Numbers',
    value: 'android.permission.READ_PHONE_NUMBERS',
    group: 'PHONE',
    minApiLevel: 26,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read Phone State',
    value: 'android.permission.READ_PHONE_STATE',
    group: 'PHONE',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Read SMS',
    value: 'android.permission.READ_SMS',
    group: 'SMS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Receive MMS',
    value: 'android.permission.RECEIVE_MMS',
    group: 'SMS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Receive SMS',
    value: 'android.permission.RECEIVE_SMS',
    group: 'SMS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Receive WAP Push',
    value: 'android.permission.RECEIVE_WAP_PUSH',
    group: 'SMS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Record Audio',
    value: 'android.permission.RECORD_AUDIO',
    group: 'MICROPHONE',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Send SMS',
    value: 'android.permission.SEND_SMS',
    group: 'SMS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'SMS Financial Transactions',
    value: 'android.permission.SMS_FINANCIAL_TRANSACTIONS',
    group: 'NONE',
    minApiLevel: 29,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Use SIP',
    value: 'android.permission.USE_SIP',
    group: 'PHONE',
    minApiLevel: 9,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Write Calendar',
    value: 'android.permission.WRITE_CALENDAR',
    group: 'CALENDAR',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Write Call Log',
    value: 'android.permission.WRITE_CALL_LOG',
    group: 'CALL_LOG',
    minApiLevel: 16,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Write Contacts',
    value: 'android.permission.WRITE_CONTACTS',
    group: 'CONTACTS',
    minApiLevel: 1,
    protectionLevel: 'DANGEROUS',
  },
  {
    view: 'Write External Storage',
    value: 'android.permission.WRITE_EXTERNAL_STORAGE',
    group: 'STORAGE',
    minApiLevel: 4,
    protectionLevel: 'DANGEROUS',
  },
];

export default permissionsList;
