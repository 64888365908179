import { Grid, SelectChangeEvent } from '@mui/material';
import PropertyAndSelectInput from '../../../AndroidPolicies/components/PropertyAndSelectInput';
import { AppleOSType } from '../../definitions';

type SelectInput = {
  view: string;
  value: string | boolean | number;
};
interface IProperty {
  property: string;
  name: string;
  menuItems: SelectInput[];
  value: string | boolean | number | undefined;
  handleChange: (event: SelectChangeEvent) => void;
  supportedOS: {
    ios?: {
      version: string;
      isSupervised?: boolean;
    };
    macos?: {
      version: string;
    };
    tvos?: {
      version: string;
    };
    watchos?: {
      version: string;
    };
  };
  children?: IProperty[];
}

interface IProp {
  propertyList: IProperty[];
  osType: AppleOSType;
}
function Properties(props: IProp) {
  const { propertyList, osType } = props;
  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={10}>
        {propertyList
          .filter((item) => item.supportedOS[osType])
          .flatMap((item) => [
            <PropertyAndSelectInput
              key={`${item.property} ${item.name}`}
              data={{ ...item, appleSupport: item.supportedOS[osType] }}
            />,
            ...(item.children?.map((child) => (
              <div key={`${child.property} ${child.name}`} style={{ marginLeft: 20 }}>
                <PropertyAndSelectInput
                  data={{ ...child, appleSupport: child.supportedOS[osType] }}
                />
              </div>
            )) || []),
          ])}
      </Grid>
    </Grid>
  );
}

export default Properties;
