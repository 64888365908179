import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

interface IProps {
  onChange: (event: SelectChangeEvent) => void;
}
const interests = [
  'Time Based Restrictions',
  'Restrictions - Passwords | File & Data Sharing | Screen Capture etc.',
  'Application Management',
];

function AreaOfInterest(props: IProps) {
  const { onChange } = props;
  return (
    <>
      <Box display='flex' width='100%'>
        <Typography
          variant='subtitle2'
          sx={{ '@media (max-width:600px)': { fontSize: '0.75rem' } }}
        >
          Area of Interest
        </Typography>
        <Typography color='#FF5656' variant='subtitle2'>
          &nbsp;*
        </Typography>
      </Box>
      <FormControl fullWidth>
        <Select id='areaOfInterest' name='areaOfInterest' size='small' onChange={onChange} required>
          {interests?.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default AreaOfInterest;
