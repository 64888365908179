import { Button, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PolicyContext } from '../../../..';
import { createWebtoken } from '../../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import AppCard from './AppCard';
import SelectAppsComponent from './SelectAppsComponent';

function Applications() {
  const { policy } = useContext(PolicyContext);
  const dispatch = useDispatch<AppDispatch>();
  const webtoken = useSelector((state: RootState) => state.androidPolicy.webtoken);

  useEffect(() => {
    if (webtoken === '') {
      dispatch(createWebtoken());
    }
  }, []);

  const [openPlaystore, setOpenPlaystore] = useState<boolean>(false);
  const handlePlaystoreDialog = () => {
    setOpenPlaystore((prev) => !prev);
  };

  return (
    <>
      <Button
        variant='contained'
        size='small'
        sx={{ marginBottom: '16px' }}
        onClick={() => setOpenPlaystore(true)}
      >
        Select Playstore Apps
      </Button>
      {policy.appCatalog?.applications?.length > 0 ? (
        policy.appCatalog?.applications?.map((app) => (
          <Grid key={app.appname} container paddingBottom={2}>
            <AppCard app={app} />
          </Grid>
        ))
      ) : (
        <Typography>No app selected</Typography>
      )}
      <SelectAppsComponent open={openPlaystore} onClose={handlePlaystoreDialog} />
    </>
  );
}

export default Applications;
