import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IAppleAppGroup } from '../definitions';
import DeleteAppGroupDialog from '../DeleteAppGroupDialog';

interface IProps {
  appGroup: IAppleAppGroup;
  handleAppGroupDialog: (create: boolean, appGroup: IAppleAppGroup) => void;
  noEdit?: boolean;
}

AppGroupCard.defaultProps = {
  noEdit: false,
};

function AppGroupCard(props: IProps) {
  const { appGroup, handleAppGroupDialog, noEdit } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    handleAppGroupDialog(false, appGroup);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Card sx={{ maxWidth: 275 }} elevation={2}>
        <CardContent>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6' color='text.primary' gutterBottom>
              {appGroup.name}
            </Typography>
            {noEdit ? null : (
              <>
                <IconButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{ height: '30px', overflow: 'hidden' }}
          >
            <Typography color='text.primary' sx={{ fontSize: 16 }}>
              {appGroup.description}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <DeleteAppGroupDialog
        appGroup={appGroup}
        openDeleteDialog={deleteDialogOpen}
        handleDeleteDialog={setDeleteDialogOpen} // Pass the state setter function
      />
    </>
  );
}

export default React.memo(AppGroupCard);
