import Cookies from 'js-cookie';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isUserLoggedin } from '../../redux/slices/auth/authSlice';
import { AppDispatch } from '../../redux/store';

const loginPath = '/login';
export type PrivateRouteProps = {
  element: ReactElement;
};

export default function PrivateRoute({ element }: PrivateRouteProps) {
  const token = Cookies.get('token');
  const dispatch = useDispatch<AppDispatch>();
  dispatch(isUserLoggedin());
  if (token) {
    return element;
  }
  return <Navigate to={{ pathname: loginPath }} />;
}
