export const PERSONAL_PROFILE_DESCRIPTION =
  'Selecting this option enables the Work profile setup on personal devices';

export const CORPORATE_PROFILE_DESCRIPTION = 'Select this option if the device is company-owned';

export const ENROLLMENT_EMAIL_OPTION_DESCRIPTION =
  'Enrollment steps will be sent over email to the selected user(Recommended for personal devices)';

export const ENROLLMENT_STEPS_DISPLAY_DESCRIPTION =
  'Enrollment steps will be shown here(Recommended for corporate devices)';

export const CHOICE_SEND_EMAIL = 'send-email';
export const CHOICE_SHOW_DETAILS = 'show-details';

export const DEVICE_PERSONAL = 'personal';
export const DEVICE_CORPORATE = 'corporate';

export const RESET_PASSWORD_WARNING = 'Frequent password resets might not be effective.';
export const START_DEVICE_LOST_MODE_CONDITIONS = [
  'The device is already in lost mode.',
  'The device password has been reset by an IT admin in the last 12 hours.',
  'The employee manually exited lost mode in the last 12 hours.',
  'It is a work profile on a company-owned device and the work profile is paused.',
];

export const DEVICE_DEPROVISION_INFORMATION =
  'This action is subject to device enrollment type. It will delete the Work Profile in BYOD devices and RESET the Company-Owned Device.';
export const DEVICE_DEPROVISION_USER_FACING_MESSAGE =
  'Your IT Admin has initiated the deprovision of this device.';
