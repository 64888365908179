import { Button, Card, Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndInput from '../../PropertyAndInput';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';

function PasswordSettings() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { passwordSettings } = policy.policyControls;

  const handleChange = (event: SelectChangeEvent, index: number) => {
    const { name, value } = event.target;

    setPolicy((prev) => {
      const newPasswordSettings = prev.policyControls.passwordSettings.map((elem, id) =>
        id === index
          ? {
              ...elem,
              [name]: value,
            }
          : elem,
      );
      return {
        ...prev,
        policyControls: {
          ...prev.policyControls,
          passwordSettings: newPasswordSettings,
        },
      };
    });
  };

  const handleAddNewPasswordPolicy = () => {
    setPolicy((prev) => {
      const newSetting = {
        minLength: 6,
        minLetters: 0,
        minLowerCase: 0,
        minimumNonLetter: 0,
        minimumNumbers: 0,
        minimumSymbols: 0,
        minimumUpperCase: 0,
        quality: 'numeric',
        historyLength: 2,
        maxifailedPasswords: 4,
        expirationTimeout: '123456789.0s',
        scope: 'scope_profile',
        requireUnlock: 'default',
        unifiedLockSettings: 'unified_lock',
      };

      return {
        ...prev,
        policyControls: {
          ...prev.policyControls,
          passwordSettings: [...passwordSettings, newSetting],
        },
      };
    });
  };

  const removePasswordSetting = (index: number) => {
    setPolicy((prev) => {
      const newPasswordSettings = prev.policyControls.passwordSettings.filter(
        (_, id) => id !== index,
      );

      return {
        ...prev,
        policyControls: {
          ...prev.policyControls,
          passwordSettings: newPasswordSettings,
        },
      };
    });
  };

  return (
    <>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          {passwordSettings.map((passwordSetting, index) => (
            <Card sx={{ padding: 2, marginBottom: 2, display: 'flex', flexDirection: 'column' }}>
              <PropertyAndSelectInput
                data={{
                  property: 'Profile Scope',
                  name: 'scope',
                  menuItems: [
                    { view: 'Work Profile', value: 'scope_profile' },
                    { view: 'Device Wide', value: 'scope_device' },
                  ],
                  value: passwordSetting.scope,
                  androidVersion: '(Device Password)5.0+ (Work Profile Password)7.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Password Quality',
                  name: 'quality',
                  menuItems: [
                    { view: 'No Password', value: 'unspecified' },
                    { view: 'Complex', value: 'complex' },
                    { view: 'Numeric', value: 'numeric' },
                    { view: 'Numeric Complex', value: 'numeric_complex' },
                    { view: 'Alphabetic', value: 'alphabetic' },
                    { view: 'Alphanumeric', value: 'alphanumeric' },
                    { view: 'Biometric Weak', value: 'weak' },
                  ],
                  value: passwordSetting.quality,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Minimum length of password'
                name='minLength'
                value={passwordSetting.minLength}
                disabled={passwordSetting.quality === 'unspecified'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of numeric digits'
                name='minimumNumbers'
                value={passwordSetting.minimumNumbers}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of letters'
                name='minLetters'
                value={passwordSetting.minLetters}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of lowercase letters'
                name='minLowerCase'
                value={passwordSetting.minLowerCase}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of uppercase letters'
                name='minimumUpperCase'
                value={passwordSetting.minimumUpperCase}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of symbols'
                name='minimumSymbols'
                value={passwordSetting.minimumSymbols}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Password History'
                name='historyLength'
                value={passwordSetting.historyLength}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />

              <PropertyAndSelectInput
                data={{
                  property: 'Password Expiry',
                  name: 'expirationTimeout',
                  menuItems: [
                    { view: 'Never', value: '123456789.0s' },
                    { view: '1 Year', value: '31536000.0s' },
                    { view: '6 Months', value: '15768000.0s' },
                    { view: '3 Months', value: '7884000.0s' },
                    { view: '1 Month', value: '2628000.0s' },
                  ],
                  value: passwordSetting.expirationTimeout,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Maximum Failed Passwords'
                name='maxifailedPasswords'
                value={passwordSetting.maxifailedPasswords}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Require Password Unlock',
                  name: 'requireUnlock',
                  menuItems: [
                    { view: 'Device Default', value: 'default' },
                    { view: 'Everyday', value: 'require_every_day' },
                  ],
                  value: passwordSetting.requireUnlock,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Device & Work Profile Lock Settings',
                  name: 'unifiedLockSettings',
                  menuItems: [
                    { view: 'Common Lock', value: 'unified_lock' },
                    { view: 'Separate Lock', value: 'separate_lock' },
                  ],
                  value: passwordSetting.unifiedLockSettings,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <div style={{ marginTop: 'auto', width: 'fit-content', marginLeft: 'auto' }}>
                <Button
                  onClick={() => removePasswordSetting(index)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
        </Grid>
      </Grid>

      <Button variant='contained' onClick={handleAddNewPasswordPolicy}>
        Add Password For Different Profile Scope
      </Button>
    </>
  );
}

export default PasswordSettings;
