import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function AdvancedSecurity() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_conf_profile_installation,
    allow_account_modification,
    allow_untrusted_tls_prompt,
    allow_usb_restriction_mode,
    allow_usb_file_drive_sharing,
    allow_unpaired_external_boot_to_recovery,

    allow_password_sharing,
    allow_password_proximity_requests,
    allow_passcode_modify,

    force_airplay_incoming_requests_pp,
    force_airplay_outgoing_requests_pp,

    allow_apple_watch_pairing,
    force_watch_wrist_detect,

    allow_new_device_setup_in_proximity,
    allow_autofill_password,
    force_authenticate_for_autofill,
  } = profile.restrictions.advancedSecurity;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        advancedSecurity: {
          ...prev.restrictions.advancedSecurity,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Install configuration profiles and certificates interactively',
      name: 'allow_conf_profile_installation',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_conf_profile_installation,
      handleChange,
      supportedOS: {
        ios: {
          version: '6',
          isSupervised: true,
        },
        macos: {
          version: '13',
        },
      },
    },
    {
      property: 'Add/modify Mail, iCloud, Contacts, Calender and other accounts',
      name: 'allow_account_modification',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_account_modification,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
          isSupervised: true,
        },
        macos: {
          version: '14',
        },
        watchos: {
          version: '10',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Prompt user for untrusted TLS certificates',
      name: 'allow_untrusted_tls_prompt',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_untrusted_tls_prompt,
      handleChange,
      supportedOS: {
        ios: {
          version: '5',
        },
      },
    },
    {
      property: 'Allow USB connection when locked',
      name: 'allow_usb_restriction_mode',
      menuItems: GLOBAL_CONST.SELECT_YES_NO_TRUE_FALSE,
      value: allow_usb_restriction_mode,
      handleChange,
      supportedOS: {
        ios: {
          version: '11.4.1',
          isSupervised: true,
        },
        macos: {
          version: '13',
        },
      },
    },
    {
      property: 'USB access to Files app',
      name: 'allow_usb_file_drive_sharing',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_usb_file_drive_sharing,
      handleChange,
      supportedOS: {
        ios: {
          version: '13.1',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Allow unpaired computers to boot devices into recovery mode',
      name: 'allow_unpaired_external_boot_to_recovery',
      menuItems: GLOBAL_CONST.SELECT_YES_NO_TRUE_FALSE,
      value: allow_unpaired_external_boot_to_recovery,
      handleChange,
      supportedOS: {
        ios: {
          version: '13.1',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Allow password sharing via Airdrop',
      name: 'allow_password_sharing',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_password_sharing,
      handleChange,
      supportedOS: {
        ios: {
          version: '12',
          isSupervised: true,
        },
        macos: {
          version: '10.14',
        },
      },
    },
    {
      property: 'Request passwords from nearby devices',
      name: 'allow_password_proximity_requests',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_password_proximity_requests,
      handleChange,
      supportedOS: {
        ios: {
          version: '12',
          isSupervised: true,
        },
        macos: {
          version: '10.14',
        },
        tvos: {
          version: '12',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Modify passcode',
      name: 'allow_passcode_modify',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_passcode_modify,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.3',
          isSupervised: true,
        },
        macos: {
          version: '14',
        },
      },
    },
    {
      property: 'Force password for AirPlay incoming requests',
      name: 'force_airplay_incoming_requests_pp',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_airplay_incoming_requests_pp,
      handleChange,
      supportedOS: {
        ios: {
          version: '7.1', // added this, although not present in documentation
        },
        tvos: {
          version: '6.2',
        },
      },
    },
    {
      property: 'Force password for AirPlay outgoing requests',
      name: 'force_airplay_outgoing_requests_pp',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_airplay_outgoing_requests_pp,
      handleChange,
      supportedOS: {
        ios: {
          version: '7.1',
        },
      },
    },
    {
      property: 'Pair with Apple watch',
      name: 'allow_apple_watch_pairing',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_apple_watch_pairing,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.3',
          isSupervised: true,
        },
        macos: {
          version: '14',
        },
      },
    },
    {
      property: 'Wrist detection for paired Apple watch',
      name: 'force_watch_wrist_detect',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_watch_wrist_detect,
      handleChange,
      supportedOS: {
        ios: {
          version: '8.2',
        },
      },
    },
    {
      property: 'Set up other devices using proximity detection',
      name: 'allow_new_device_setup_in_proximity',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_new_device_setup_in_proximity,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Autofill passwords using Keychain and third-party password managers',
      name: 'allow_autofill_password',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_autofill_password,
      handleChange,
      supportedOS: {
        ios: {
          version: '12',
          isSupervised: true,
        },
        macos: {
          version: '10.14',
        },
      },
      children: [
        {
          property: 'Authenticate with TouchID or FaceID before allowing autofill ',
          name: 'force_authenticate_for_autofill',
          menuItems: GLOBAL_CONST.SELECT_YES_NO_TRUE_FALSE,
          value: force_authenticate_for_autofill,
          handleChange,
          supportedOS: {
            ios: {
              version: '8.3',
              isSupervised: true,
            },
            macos: {
              version: '14',
            },
          },
        },
      ],
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default AdvancedSecurity;
