import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAppleInvitationPayload } from '../../../components/Enrollments/definitions';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IAppleDevice } from '../../../pages/AppleDevices';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_DEVICE_ACTIVATE_DEFAULT_VPN_PROFILE,
  APPLE_DEVICES_LIST_ROUTE,
  APPLE_IOS_DEVICE_ENROLLMENT_ROUTE,
} from '../../../utils/routes-defs';

interface IAppledDeviceSliceState {
  appleDevices: IAppleDevice[];
  loading: boolean;
  message: IMessage;
}

const initialState: IAppledDeviceSliceState = {
  appleDevices: [],
  loading: false,
  message: messageInit,
};

export const getAppleDevices = createAsyncThunk(
  'androidDevice/getAppleDevices',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(`${APPLE_DEVICES_LIST_ROUTE}?page=1&limit=10`);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const allappleDevices: IAppleDevice[] = response.data.devices.map(
        (device: any, idx: number) => ({
          id: idx + 1,
          _id: device.id,
          type: device.type,
          name: device.name,
          osVersion: device.osVersion,
          enabled: device.enabled,
          serialNumber: device.serialNumber,
          user: device.email,
          deviceGroupId: device.deviceGroupId,
          deviceGroupName: device.deviceGroupName,
          moVPN: device.moVPN,
        }),
      );
      return allappleDevices;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const sendEnrollmentInvitation = createAsyncThunk(
  'appleDevice/sendEnrollmentInvitation',
  async (payload: IAppleInvitationPayload, thunkAPI) => {
    try {
      if (payload.sendEmail) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Enrollment email sent successfully',
          }),
        );
      }
      const response = await axiosInstance().post(APPLE_IOS_DEVICE_ENROLLMENT_ROUTE, payload);
      thunkAPI.dispatch(getAppleDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const activateDefaultVPNProfile = createAsyncThunk(
  'appleDevice/activateDefaultVPNProfile',
  async (payload: { deviceId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_DEVICE_ACTIVATE_DEFAULT_VPN_PROFILE,
        payload,
      );
      return response.data.message;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const appledDeviceSlice = createSlice({
  name: 'androidDevice',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get android devices
    builder.addCase(getAppleDevices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleDevices.fulfilled, (state, action) => {
      const allappleDevices = action.payload;
      state.loading = false;
      state.appleDevices = allappleDevices;
    });
    builder.addCase(getAppleDevices.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });

    // send enrollment invitation
    builder.addCase(sendEnrollmentInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEnrollmentInvitation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEnrollmentInvitation.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      } else {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });

    // activate VPN profile
    builder.addCase(activateDefaultVPNProfile.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          success: true,
          successMessage: action.payload as string,
        };
      }
    });
    builder.addCase(activateDefaultVPNProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateDefaultVPNProfile.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.message = {
          ...messageInit,
          error: true,
          errorMessage: action.payload as string,
        };
      }
    });
  },
});

export const { updateMessage } = appledDeviceSlice.actions;
export default appledDeviceSlice;
