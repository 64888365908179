import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function Browser() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_safari_browser,
    allow_safari_autofill,
    allow_safari_javascript,
    allow_safari_popups,
    allow_safari_accept_cookies,
    allow_safari_fraud_website_warning,
  } = profile.restrictions.browser;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        browser: {
          ...prev.restrictions.browser,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Safari',
      name: 'allow_safari_browser',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_safari_browser,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
          supervisionOver: '13',
        },
      },
      children: [
        {
          property: 'Safari Autofill',
          name: 'allow_safari_autofill',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_safari_autofill,
          handleChange,
          supportedOS: {
            ios: {
              version: '4',
              isSupervised: true,
              supervisionOver: '13',
            },
            macos: {
              version: '10.13',
            },
          },
        },
        {
          property: 'Safari Execute Javascript',
          name: 'allow_safari_javascript',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_safari_javascript,
          handleChange,
          supportedOS: {
            ios: {
              version: '4',
              isSupervised: false,
            },
          },
        },
        {
          property: 'Safari Allow Popups',
          name: 'allow_safari_popups',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_safari_popups,
          handleChange,
          supportedOS: {
            ios: {
              version: '4',
              isSupervised: true,
            },
          },
        },
        {
          property: 'Safari Accept Cookies',
          name: 'allow_safari_accept_cookies',
          menuItems: [
            {
              view:
                'Prevent Cross-Site Tracking and Block All Cookies, and the user canʼt disable either setting.',
              value: 0,
            },
            {
              view:
                'Prevent Cross-Site Tracking, and the user canʼt disable it. Doesn’t enable Block All Cookies, but the user can enable it.',
              value: 1,
            },
            {
              view:
                'Prevent Cross-Site Tracking but doesn’t enable Block All Cookies. The user can toggle either setting.',
              value: 2,
            },
          ],
          value: allow_safari_accept_cookies,
          handleChange,
          supportedOS: {
            ios: {
              version: '4',
              isSupervised: true,
            },
          },
        },

        {
          property: 'Safari Fraud Site Warning',
          name: 'allow_safari_fraud_website_warning',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_safari_fraud_website_warning,
          handleChange,
          supportedOS: {
            ios: {
              version: '4',
              isSupervised: true,
            },
          },
        },
      ],
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default Browser;
