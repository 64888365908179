// time in clock
export const times = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

// timezones with offset from GMT
export const timezones = [
  {
    tz: '(GMT -12:00) Eniwetok, Kwajalein',
    offset: '-12:00',
  },
  {
    tz: '(GMT -11:00) Midway Island, Samoa',
    offset: '-11:00',
  },
  {
    tz: '(GMT -10:00) Hawaii',
    offset: '-10:00',
  },
  {
    tz: '(GMT -9:00) Alaska',
    offset: '-09:00',
  },
  {
    tz: '(GMT -8:00) Pacific Time (US & Canada)',
    offset: '-08:00',
  },
  {
    tz: '(GMT -7:00) Mountain Time (US & Canada)',
    offset: '-07:00',
  },
  {
    tz: '(GMT -6:00) Central Time (US & Canada), Mexico City',
    offset: '-06:00',
  },
  {
    tz: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    offset: '-05:00',
  },
  {
    tz: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    offset: '-04:00',
  },
  {
    tz: '(GMT -3:30) Newfoundland',
    offset: '-03:30',
  },
  {
    tz: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
    offset: '-03:00',
  },
  {
    tz: '(GMT -2:00) Mid-Atlantic',
    offset: '-02:00',
  },
  {
    tz: '(GMT -1:00) Azores, Cape Verde Islands',
    offset: '-01:00',
  },
  {
    tz: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    offset: '+00:00',
  },
  {
    tz: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    offset: '+01:00',
  },
  {
    tz: '(GMT +2:00) Kaliningrad, South Africa',
    offset: '+02:00',
  },
  {
    tz: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    offset: '+03:00',
  },
  {
    tz: '(GMT +3:30) Tehran',
    offset: '+03:30',
  },
  {
    tz: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    offset: '+04:00',
  },
  {
    tz: '(GMT +4:30) Kabul',
    offset: '+04:30',
  },
  {
    tz: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    offset: '+05:00',
  },
  {
    tz: '(GMT +5:30) Indian Standard (Mumbai, Kolkata, Chennai, New Delhi)',
    offset: '+05:30',
  },
  {
    tz: '(GMT +5:45) Kathmandu',
    offset: '+05:45',
  },
  {
    tz: '(GMT +6:00) Almaty, Dhaka, Colombo',
    offset: '+06:00',
  },
  {
    tz: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
    offset: '+07:00',
  },
  {
    tz: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    offset: '+08:00',
  },
  {
    tz: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    offset: '+09:00',
  },
  {
    tz: '(GMT +9:30) Adelaide, Darwin',
    offset: '+09:30',
  },
  {
    tz: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    offset: '+10:00',
  },
  {
    tz: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    offset: '+11:00',
  },
  {
    tz: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    offset: '+12:00',
  },
];
