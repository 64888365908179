import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = () => {
  const token = Cookies.get('token');
  if (!token && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
  const Axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
      Authorization: token ? `Bearer ${token}` : 'Bearer',
    },
  });

  return Axios;
};

export default axiosInstance;
