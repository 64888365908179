import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardCard from '../../components/DashboardCard';
import MainLayout from '../../layouts/MainLayout';
import { getAndroidDevices } from '../../redux/slices/androidDevice/androidDeviceSlice';
import { getAndroidPolicies } from '../../redux/slices/androidPolicy/androidPolicySlice';
import { getDeviceGroups } from '../../redux/slices/deviceGroup/deviceGroupSlice';
import { getUsers } from '../../redux/slices/users/usersSlice';
import { AppDispatch, RootState } from '../../redux/store';
import CustomHeader from '../../components/CustomHeader';

interface IDetails {
  users: number;
  policies: number;
  devices: number;
  deviceGroups: number;
}
const detailsInit: IDetails = {
  users: 0,
  policies: 0,
  devices: 0,
  deviceGroups: 0,
};
function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();
  const androidPolicies = useSelector((state: RootState) => state.androidPolicy.androidPolicies);
  const androidDevices = useSelector((state: RootState) => state.androidDevice.androidDevices);
  const users = useSelector((state: RootState) => state.users.users);
  const deviceGroups = useSelector((state: RootState) => state.deviceGroup.deviceGroups);

  const [details, setDetails] = useState<IDetails>(detailsInit);

  useEffect(() => {
    dispatch(getAndroidPolicies());
    dispatch(getAndroidDevices());
    dispatch(getUsers());
    dispatch(getDeviceGroups());
  }, []);

  useEffect(() => {
    setDetails({
      users: users.length,
      policies: androidPolicies.length,
      devices: androidDevices.length,
      deviceGroups: deviceGroups.length,
    });
  }, [androidPolicies, androidDevices, users, deviceGroups]);

  return (
    <MainLayout>
      <CustomHeader title='Dashboard' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item key='Users' xs={12} sm={3} sx={{ pb: 2 }}>
          <DashboardCard title='Users' value={details.users} />
        </Grid>
        <Grid item key='Devices' xs={12} sm={3} sx={{ pb: 2 }}>
          <DashboardCard title='Devices' value={details.devices} />
        </Grid>
        <Grid item key='Device Groups' xs={12} sm={3} sx={{ pb: 2 }}>
          <DashboardCard title='Device Groups' value={details.deviceGroups} />
        </Grid>
        <Grid item key='Policies' xs={12} sm={3} sx={{ pb: 2 }}>
          <DashboardCard title='Policies' value={details.policies} />
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default Dashboard;
