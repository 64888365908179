import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import AllAppsPolicy from './AppCatalog/AllAppsPolicy';
import AppSecurity from './AppCatalog/AppSecurity';
import Applications from './AppCatalog/Applications';
import TimeRestriction from './CustomRestrictions/TimeRestriction';
import DataSharing from './DataSharing';
import DeviceFunctionalities from './DeviceFunctionalities';
import VPN from './Network/VPN';
import OSPatchManagement from './OSPatchManagement';
import PasswordSettings from './PasswordSettings';
import Security from './Security';
import WifiConfiguration from './Network/WifiConfiguration';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function TabNavigation() {
  const [levelOneTabValue, setLevelOneTabValue] = useState<number>(0);
  const [levelTwoTabValue, setLevelTwoTabValue] = useState<number>(0);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLevelOneTabValue(newValue);
  };
  const handleSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLevelTwoTabValue(newValue);
  };

  const tabData = [
    {
      label: 'App Catalog',
      content: (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={levelTwoTabValue}
            onChange={handleSubTabChange}
            aria-label='App Catalog'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab
              label='All Apps Settings'
              id='All Apps Settings'
              aria-controls='All Apps Settings'
            />
            <Tab label='Applications' id='Applications' aria-controls='Applications' />
            <Tab label='Security' id='Security' aria-controls='Security' />
          </Tabs>
          <div style={{ width: '100%' }}>
            <TabPanel value={levelTwoTabValue} index={0}>
              <AllAppsPolicy />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={1}>
              <Applications />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={2}>
              <AppSecurity />
            </TabPanel>
          </div>
        </Box>
      ),
    },
    {
      label: 'Password Settings',
      content: <PasswordSettings />,
    },
    {
      label: 'Device Functionalities',
      content: <DeviceFunctionalities />,
    },
    {
      label: 'Data Sharing',
      content: <DataSharing />,
    },
    {
      label: 'Security',
      content: <Security />,
    },
    {
      label: 'OS Patch Management',
      content: <OSPatchManagement />,
    },
    {
      label: 'Network',
      content: (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={levelTwoTabValue}
            onChange={handleSubTabChange}
            aria-label='Network'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label='VPN Configuration' id='VPN' aria-controls='VPN' />
            <Tab label='WiFi Configuration' id='WiFi' aria-controls='WiFi' />
          </Tabs>
          <div style={{ width: '100%' }}>
            <TabPanel value={levelTwoTabValue} index={0}>
              <VPN />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={1}>
              <WifiConfiguration />
            </TabPanel>
          </div>
        </Box>
      ),
    },
    {
      label: 'Custom Restrictions',
      content: (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={levelTwoTabValue}
            onChange={handleSubTabChange}
            aria-label='Custom Restrictions Tabs'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label='Time Restriction' id='Time Restriction' aria-controls='Time Restriction' />
          </Tabs>
          <div style={{ width: '100%' }}>
            <TabPanel value={levelTwoTabValue} index={0}>
              <TimeRestriction />
            </TabPanel>
          </div>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={levelOneTabValue}
        onChange={handleMainTabChange}
        aria-label='Policy'
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {/* Dynamically generate level one tabs */}
        {tabData.map((tab) => (
          <Tab key={tab.label} label={tab.label} aria-controls={tab.label} />
        ))}
      </Tabs>
      <div style={{ width: '100%' }}>
        {tabData.map((tab, index) => (
          <TabPanel key={tab.label} value={levelOneTabValue} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </div>
    </Box>
  );
}

export default React.memo(TabNavigation);
