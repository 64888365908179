import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import AdvancedSecurity from './Restrictions/AdvancedSecurity';
import Applications from './Restrictions/Applications';
import Browser from './Restrictions/Browser';
import DateTime from './Restrictions/DateTime';
import DeviceFunctionality from './Restrictions/DeviceFunctionality';
import Icloud from './Restrictions/Icloud';
import Keyboard from './Restrictions/Keyboard';
import Network from './Restrictions/Network';
import Privacy from './Restrictions/Privacy';
import Security from './Restrictions/Security';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel=${index}`}
      aria-labelledby={`vertical-tabpanel=${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function TabNavigation() {
  const [levelOneTabValue, setLevelOneTabValue] = useState<number>(0);
  const [levelTwoTabValue, setLevelTwoTabValue] = useState<number>(0);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLevelOneTabValue(newValue);
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLevelTwoTabValue(newValue);
  };

  const tabData = [
    {
      label: 'Restrictions',
      content: (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={levelTwoTabValue}
            onChange={handleSubTabChange}
            aria-label='Restrictions'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab
              label='Device Functionality'
              id='Device-Functionality'
              aria-controls='Device-Functionality'
            />
            <Tab label='Security' id='Security' aria-controls='Security' />
            <Tab
              label='Advanced Security'
              id='Advanced-Security'
              aria-controls='Advanced-Security'
            />
            <Tab label='Safari Browser' id='Safari-Browser' aria-controls='Safari-Browser' />
            <Tab label='Applications' id='Applications' aria-controls='Applications' />
            <Tab
              label='Network And Roaming'
              id='Network-And-Roaming'
              aria-controls='Network-And-Roaming'
            />
            <Tab label='iCloud' id='iCloud' aria-controls='iCloud' />
            <Tab label='Privacy' id='Privacy' aria-controls='Privacy' />
            <Tab label='Date and Time' id='Date-And-Time' aria-controls='Date-And-Time' />
            <Tab
              label='Keyboard Settings'
              id='Keyboard-Settings'
              aria-controls='Keyboard-Settings'
            />
          </Tabs>
          <div style={{ width: '100%' }}>
            <TabPanel value={levelTwoTabValue} index={0}>
              <DeviceFunctionality />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={1}>
              <Security />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={2}>
              <AdvancedSecurity />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={3}>
              <Browser />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={4}>
              <Applications />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={5}>
              <Network />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={6}>
              <Icloud />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={7}>
              <Privacy />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={8}>
              <DateTime />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={9}>
              <Keyboard />
            </TabPanel>
            <TabPanel value={levelTwoTabValue} index={10}>
              <Keyboard />
            </TabPanel>
          </div>
        </Box>
      ),
    },
  ];
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={levelOneTabValue}
        onChange={handleMainTabChange}
        aria-label='Profile'
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {tabData.map((tab) => (
          <Tab key={tab.label} label={tab.label} aria-controls={tab.label} />
        ))}
      </Tabs>
      <div style={{ width: '100%' }}>
        {tabData.map((tab, index) => (
          <TabPanel key={tab.label} value={levelOneTabValue} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </div>
    </Box>
  );
}

export default React.memo(TabNavigation);
