import React, { ChangeEvent, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IIssueCommandEnum, IIssueCommandPayload } from '../definitions';
import { START_DEVICE_LOST_MODE_CONDITIONS } from '../constants';

interface IStartLostModeParams {
  email: string;
  address: string;
  organization: string;
  message: string;
  phoneNumber: string;
}

interface IProps {
  deviceName: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendCommand: (payload: IIssueCommandPayload) => void;
}

function StartDeviceLostModeDialog(props: IProps) {
  const { deviceName, open, setOpen, sendCommand } = props;

  const startLostModeParamsInit: IStartLostModeParams = {
    email: '',
    address: '',
    organization: '',
    message: '',
    phoneNumber: '',
  };

  const [startLostModeFields, setStartLostModeParams] = useState<IStartLostModeParams>(
    startLostModeParamsInit,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setStartLostModeParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const payload: IIssueCommandPayload = {
      deviceName,
      commandDetails: {
        device_command: IIssueCommandEnum.START_LOST_MODE,
        startLostOptions: {
          message: startLostModeFields.message,
          phoneNumber: startLostModeFields.phoneNumber,
          emailAddress: startLostModeFields.email,
          address: startLostModeFields.address,
          organization: startLostModeFields.organization,
        },
      },
    };
    sendCommand(payload);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      sx={{ height: '80vh' }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>Set Device On Lost Mode</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpen(false)}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Alert severity='warning' sx={{ marginBottom: 2 }}>
          <AlertTitle>Lost mode cannot be started in following cases</AlertTitle>
          <ul>
            {START_DEVICE_LOST_MODE_CONDITIONS.map((condition) => (
              <li key={condition}>{condition}</li>
            ))}
          </ul>
        </Alert>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid item sm={4}>
            <InputLabel htmlFor='email' required sx={{ color: 'black' }}>
              Email Address
            </InputLabel>
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='email'
              name='email'
              placeholder='Enter Email address to be shown on screen'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid item sm={4}>
            Message on screen
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='message'
              name='message'
              placeholder='Message that will display on lock screen'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid item sm={4}>
            Organization
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='organization'
              name='organization'
              placeholder='Enter organization name'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid item sm={4}>
            Phone Number
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='phoneNumber'
              name='phoneNumber'
              placeholder='Enter phone number'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid item sm={4}>
            Address
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='address'
              name='address'
              placeholder='Enter organization address'
              variant='outlined'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit}>
          Set Lost Mode
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StartDeviceLostModeDialog;
