import React from 'react';
import { Tooltip } from '@mui/material';
import mobileicon from '../../../../assets/images/mobile-icon.png';

interface IProps {
  profileType: string;
}

function ProfileType(props: IProps) {
  const { profileType } = props;
  return (
    <Tooltip title={profileType}>
      <img src={mobileicon} alt='Minimum Android Version Supported' width={15} height={15} />
    </Tooltip>
  );
}

export default ProfileType;
