import { SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { ProfileContext } from '../../../..';
import { GLOBAL_CONST } from '../../../../../../constants';
import Properties from '../../../Properties';

function NetworkAndRoaming() {
  const { osType, profile, setProfile } = useContext(ProfileContext);

  const {
    allow_sync_while_roaming,
    allow_modify_app_cellular_data,
    allow_modify_bluetooth_settings,
    allow_modify_bluetooth_sharing_settings_in_settings_app,

    allow_create_vpn_config,
    force_wifi_on,
    allow_wifi_connect_to_allowed_networks,
    allow_modify_hotspot_settings,
    allow_modify_esim_settings,
    allow_nfc_comm,
  } = profile.restrictions.network;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setProfile((prev) => ({
      ...prev,
      restrictions: {
        ...prev.restrictions,
        network: {
          ...prev.restrictions.network,
          [name]: value,
        },
      },
    }));
  };

  const propertyList = [
    {
      property: 'Automatic sync while roaming',
      name: 'allow_sync_while_roaming',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_sync_while_roaming,
      handleChange,
      supportedOS: {
        ios: {
          version: '4',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Modify cellular data usage for apps',
      name: 'allow_modify_app_cellular_data',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_app_cellular_data,
      handleChange,
      supportedOS: {
        ios: {
          version: '7',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Modify bluetooth',
      name: 'allow_modify_bluetooth_settings',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_bluetooth_settings,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
      children: [
        {
          property: 'Modify bluetooth sharing settings',
          name: 'allow_modify_bluetooth_sharing_settings_in_settings_app',
          menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
          value: allow_modify_bluetooth_sharing_settings_in_settings_app,
          handleChange,
          supportedOS: {
            ios: {
              version: '11',
              isSupervised: true,
            },
          },
        },
      ],
    },
    {
      property: 'Allow configuring VPN',
      name: 'allow_create_vpn_config',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_create_vpn_config,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Always on Wi-Fi',
      name: 'force_wifi_on',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: force_wifi_on,
      handleChange,
      supportedOS: {
        ios: {
          version: '13',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Connect to Wi-Fi if distributed via MDM',
      name: 'allow_wifi_connect_to_allowed_networks',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_wifi_connect_to_allowed_networks,
      handleChange,
      supportedOS: {
        ios: {
          version: '14.5',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Modify Personal Hotspot',
      name: 'allow_modify_hotspot_settings',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_hotspot_settings,
      handleChange,
      supportedOS: {
        ios: {
          version: '12.2',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Modify e-SIM settings',
      name: 'allow_mod',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_modify_esim_settings,
      handleChange,
      supportedOS: {
        ios: {
          version: '11',
          isSupervised: true,
        },
      },
    },
    {
      property: 'Near-Field Communications (NFC)',
      name: 'allow_nfc_comm',
      menuItems: GLOBAL_CONST.SELECT_ALLOW_DENY_TRUE_FALSE,
      value: allow_nfc_comm,
      handleChange,
      supportedOS: {
        ios: {
          version: '14.2',
          isSupervised: true,
        },
      },
    },
  ];

  return <Properties propertyList={propertyList} osType={osType} />;
}

export default NetworkAndRoaming;
