import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';

interface IProps {
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  showPassword: boolean;
  handleClickShowPassword: () => void;
}

function PasswordField(props: IProps) {
  const { label, name, onChange, showPassword, handleClickShowPassword } = props;

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>
      <Box display='flex' width='100%'>
        <Typography
          variant='subtitle2'
          sx={{ '@media (max-width:600px)': { fontSize: '0.75rem' } }}
        >
          {label}
        </Typography>
        <Typography color='#FF5656' variant='subtitle2'>
          &nbsp;*
        </Typography>
      </Box>
      <FormControl sx={{ width: '100%' }} variant='outlined'>
        <OutlinedInput
          id={name}
          name={name}
          onChange={onChange}
          size='small'
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          required
        />
      </FormControl>
    </>
  );
}

export default PasswordField;
